import { DebugHandler, Blueprint, TrayLayoutOffsets, TrayShape, Vector, Tray } from "@/types";
import * as rep from "replicad";

export const type = "scripted";

export type Configuration = {
  script: string,
}

export function getDefaultConfiguration(): Configuration {
  return {
    script: "",
  };
}


export function getLayoutOffsets(tray: Blueprint): TrayLayoutOffsets {
  return {
    start: {
      x: 0,
      y: 0,
      z: 0,
    },
    extent: {
      x: 0,
      y: 0,
      z: 0,
    },
  };
}

export function generate(blueprint: Blueprint, tray: Tray, debug?: DebugHandler) {
  const result: TrayShape[] = [];
  result.push({
    name: "tray",
    shape: rep.drawRectangle(10, 10).sketchOnPlane("XY").extrude(10) as rep.Shape3D,
  });
  return result;
}