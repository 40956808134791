import * as rep from "replicad";
import { InsertGlobals, Modifier, Part, PocketElementBlueprint, TrayMeasure, ConfigurationXYZ, ConfigurationCornerModifier, ConfigurationHexCutPrevent, GenerationOptions, ConfigurationTakeoutAssistEnableCornerModifier, ConfigurationTakeoutAssistCornerModifier, ConfigurationTakeoutAssistCornerLength, ConfigurationTakeoutAssistCutoutModifier, ConfigurationTakeoutAssistCutoutLength, ConfigurationTakeoutAssistCutoutWidthPercent, ConfigurationTakeoutAssistCutoutDepthPercent, TrayElement } from "@/types";
import * as lib from "../library";

export const type = "cards-flat";

export type Configuration = ConfigurationXYZ & ConfigurationCornerModifier & ConfigurationHexCutPrevent & 
                            ConfigurationTakeoutAssistEnableCornerModifier & ConfigurationTakeoutAssistCornerModifier & ConfigurationTakeoutAssistCornerLength & ConfigurationTakeoutAssistCutoutModifier & ConfigurationTakeoutAssistCutoutLength & ConfigurationTakeoutAssistCutoutWidthPercent & ConfigurationTakeoutAssistCutoutDepthPercent;

export function getDefaultConfiguration(): Configuration {
  return {
    x: 40,
    y: 60,
    z: 20,
    cornerModifierEnable: true,
    cornerModifier: Modifier.Fillet,
    cornerModifierLength: 2.8,
    takeoutAssistEnableCornerModifier: true,
    takeoutAssistCornerModifier: Modifier.Fillet,
    takeoutAssistCornerLength: 4,
    takeoutAssistCutoutModifier: Modifier.Fillet,
    takeoutAssistCutoutLength: 4,
    takeoutAssistCutoutDepthPercent: 15,
    takeoutAssistCutoutWidthPercent: 50,
    hexCutPrevent: false,
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals) {
  const m: TrayMeasure = {
    xMin: c.x,
    yMin: c.y,
    zMin: c.z,
  };
  return m;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part {
  const configuration = blueprint.configuration as Configuration;
  const x = Math.max(element.placement.x * configuration.takeoutAssistCutoutWidthPercent / 100, 2 * configuration.takeoutAssistCutoutLength);
  const y = Math.max(element.placement.y * configuration.takeoutAssistCutoutDepthPercent / 100, configuration.takeoutAssistCutoutLength);
  // p.x == x.c and so on, this is a simple case where the outer dimensions equal the configuration
  let perimeter = lib.drawRectangle(element.placement.x, element.placement.y);
  if (configuration.cornerModifierEnable) {
    perimeter = lib.modifyDrawing(perimeter, configuration.cornerModifier, configuration.cornerModifierLength);
  }
  let preserveAll = perimeter.offset(globals.wall);
  if (!configuration.hexCutPrevent) {
    preserveAll = preserveAll.cut(perimeter);
  }
  const pocketTop = lib.modifyDrawing(lib.drawRectangle(x, y + 50), configuration.takeoutAssistCutoutModifier, configuration.takeoutAssistCutoutLength)
    .translate((element.placement.x - x) / 2, element.placement.y - y);
  const pocketTopPreserve = pocketTop.offset(globals.wall);
  const pocketBottom = lib.modifyDrawing(lib.drawRectangle(x, y + 50), configuration.takeoutAssistCutoutModifier, configuration.takeoutAssistCutoutLength)
    .translate((element.placement.x - x) / 2, -50);
  const pocketBottomPreserve = pocketBottom.offset(globals.wall);
  const cutout = perimeter
    .sketchOnPlane("XY")
    .extrude(element.placement.z + options.zPosExtent) as rep.Shape3D;
  /*let cutoutBase = lib.drawRectangle(x + 2 * configuration.takeoutAssistCutoutLength, (element.placement.yTotal ?? element.placement.y) + options.yNegExtent + options.yPosExtent)
    .sketchOnPlane("XY", element.placement.z + options.zPosExtent)
    .extrude(configuration.takeoutAssistCutoutLength + options.zPosExtent);*/
  return {
    cutout: cutout,
    preserveAll: lib.drawRectangle(element.placement.x + 2 * globals.wall, element.placement.y + 2 * globals.wall)
      .translate(-globals.wall, -globals.wall)
      .cut(perimeter)
      .fuse(pocketTopPreserve)
      .fuse(pocketBottomPreserve),
    cutAll: pocketTop.fuse(pocketBottom),
    post: shape => {
      if(configuration.takeoutAssistEnableCornerModifier) {
        // TODO implement as boolean op to support top stacking
        const z = element.placement.zOffset + element.placement.z + options.zPosExtent;
        shape = lib.modifyShape(shape, configuration.takeoutAssistCornerModifier, configuration.takeoutAssistCornerLength, p => p.and([
          e => e.inPlane("XY", z),
          e => e.inDirection([0, 1, 0]),
          g => g.either([
            e => e.containsPoint([element.placement.xOffset + (element.placement.x - x) / 2, element.placement.yOffset, z]),
            e => e.containsPoint([element.placement.xOffset + (element.placement.x - x) / 2 + x, element.placement.yOffset, z]),
            e => e.containsPoint([element.placement.xOffset + (element.placement.x - x) / 2, element.placement.yOffset + element.placement.y, z]),
            e => e.containsPoint([element.placement.xOffset + (element.placement.x - x) / 2 + x, element.placement.yOffset + element.placement.y, z]),
          ]),
        ]))
      }
      return shape;
    }
  };
}