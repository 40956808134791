import React from "react";
import { cn } from "./utils";
import { Button } from "./button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";

export interface FailureProps extends React.HTMLAttributes<SVGElement> {
}

const Failure = React.forwardRef<HTMLDivElement, FailureProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-10">
          <div className="mb-2 text-5xl underline decoration-primary underline-offset-4">
            {children}
          </div>
          This should never happen. Help the site by reporting the issue.
          <Button variant="primary">
            <ChatBubbleIcon/>
            Report issue
          </Button>
        </div>
    );
  }
)

Failure.displayName = "Failure";

export { Failure }

