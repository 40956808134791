import { Anchor, Blueprint, BlueprintType, CanvasElementConfiguration, CanvasElementLayout, Configuration, DebugHandler, ElementLayout, ElementType, GenerationOptions, GridElementConfiguration, GridElementLayout, HorizontalAlignment, Insert, InsertGlobals, Key, MeasurementOptions, Orientation, Part, PocketElementBlueprint, pockets, PocketType, Settings, StackElementConfiguration, StackElementLayout, TopStacking, Tray, TrayElement, TrayLayoutOffsets, TrayMeasure, blueprints, TrayShape, TrayType, VerticalAlignment } from "./types";
import { inflateBlueprint, inflateInsert, inflateTray } from "./utils";

export function measurePocketElement(element: PocketElementBlueprint, globals: InsertGlobals, options: MeasurementOptions): TrayMeasure {
  for(const pocket of Object.values(pockets)) {
    if(pocket.type === element.pocketType) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return pocket.measureElement(element.configuration as any, globals, options);
    }
  }
  console.error(`failed to measure unknown pocket element ${element.pocketType}`);
  return {
    xMin: 10,
    yMin: 10,
    zMin: 10,
  };
}

export function getDefaultPocketElementConfiguration(type: PocketType): Configuration {
  for(const pocket of Object.values(pockets)) {
    if(pocket.type === type) {
      return pocket.getDefaultConfiguration() as Configuration;
    }
  }
  return {};
}

export function generatePocketElementPart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part | null {
  for(const pocket of Object.values(pockets)) {
    if(pocket.type === blueprint.pocketType) {
      return pocket.generatePart(blueprint, element, globals, options);
    }
  }
  console.error(`failed to generate part for unknown pocket type ${blueprint.pocketType}`);
  return null;
}

export function getPocketStackingSupport(type: PocketType) {
  switch (type) {
    // configure pocket stacking support here, search for NEW-POCKET when adding new pockets
    case pockets.spacer.type: return false;
    case pockets.connector.type: return false;
    default: return true;
  }
}

export function getPocketLayoutSupport(type: PocketType) {
  switch (type) {
    // configure pocket layout support here, search for NEW-POCKET when adding new pockets
    case pockets.connector.type: return false;
    default: return true;
  }
}

export function getDefaultBlueprintConfiguration(type: BlueprintType): Configuration {
  for(const blueprint of Object.values(blueprints)) {
    if(blueprint.type === type) {
      return blueprint.getDefaultConfiguration() as Configuration;
    }
  }
  return {};
}


export function generateTrayShape(blueprint: Blueprint, tray: Tray, debug?: DebugHandler): TrayShape[] {
  for(const b of Object.values(blueprints)) {
    if(blueprint.type === b.type) {
      return b.generate(blueprint, tray, debug);
    }
  }
  throw `unknown blueprint type ${blueprint.type}`;
}

export function getTrayLayoutOffsets(blueprint: Blueprint): TrayLayoutOffsets {
  for(const b of Object.values(blueprints)) {
    if(blueprint.type === b.type) {
      return b.getLayoutOffsets(blueprint);
    }
  }
  return {
    start: {
      x: 0,
      y: 0,
      z: 0,
    },
    extent: {
      x: 0,
      y: 0,
      z: 0,
    },
  };
}

export const defaultTrayName = "new tray";

// format keys of all tray configurations here, search for NEW-BLUEPRINT when adding new blueprints
export function configurationKeyName(key: Key | string): string {
  switch (key as Key) {
    case "placement": return "Placement";
    case "stackingBottom": return "Bottom stacking";
    case "stackingHeight": return "Stacking height";
    case "stackingWiggle": return "Stacking height wiggle";
    case "script": return "Script";
    case "traySizeX":
    case "trayOffsetX":
    case "x": return "Width (X)";
    case "traySizeY":
    case "trayOffsetY":
    case "y": return "Depth (Y)";
    case "traySizeZ":
    case "trayOffsetZ":
    case "z": return "Height (Z)";
    case "trayLayout": return "Layout";
    case "orientation": return "Orientation";
    case "shell": return "Shell thickness";
    case "anchor": return "Anchor";
    case "wall": return "Wall";
    case "hWiggle": return "Horizontal wiggle";
    case "vWiggle": return "Vertical wiggle";
    case "hexCutEnable": return "Enable hexagon cutout";
    case "hexCutTrimThreshold": return "Trim threshold";
    case "hexCutWall": return "Polygon wall";
    case "hexCutRadius": return "Polygon radius";
    case "hexCutOrientation": return "Polygon orientation";
    case "hexCutOffsetX": return "X offset";
    case "hexCutOffsetY": return "Y offset";
    case "hexCutPrevent": return "Prevent cutout within pocket";
    case "hexCutAlign": return "Align cutout to pocket center";
    case "cornerModifierEnable": return "Enable corner modifier";
    case "cornerModifier": return "Corner modifier";
    case "cornerModifierLength": return "Modifier length";
    case "takeoutAssistEnableCornerModifier": return "Enable corner modifier";
    case "takeoutAssistCornerModifier": return "Corner modifier";
    case "takeoutAssistCornerLength": return "Corner modifier length";
    case "takeoutAssistCutoutModifier": return "Cutout modifier";
    case "takeoutAssistCutoutLength": return "Cutout modifier length";
    case "takeoutAssistCutoutWidth":
    case "takeoutAssistCutoutWidthPercent": return "Cutout width (X)";
    case "takeoutAssistCutoutDepth":
    case "takeoutAssistCutoutDepthPercent": return "Cutout depth (Y)";
    case "insertPath": return "Path";
    case "insertGame": return "Game";
    case "elementName":
    case "blueprintName":
    case "trayName":
    case "insertName":
    case "name": return "Name";
    case "trayColor": return "Color";
    case "trayType":
    case "connectorType": 
    case "pocketType": return "Type";
    case "topStacking": return "Top stacking";
    case "stack": return "Stack layout";
    case "grid": return "Grid layout";
    case "canvas": return "Canvas layout";
    case "trayWiggle": return "Tray wiggle";
    case "settingShowTrayEditor": return "Show tray editor";
    case "settingShowTrayHistory": return "Show tray history";
    case "settingShowTrayWorkers": return "Show tray workers";
    case "settingCanvasSnapDistance": return "Snap distance";
    case "connectorLength": return "Connector length";
    case "connectorMargin": return "Connector margin";
    case "connectorWiggle": return "Connector wiggle";
    case "part": return "Part";
    case "stackCount": return "Stack count";
  }
}

// format keys of all tray configurations here, search for NEW-BLUEPRINT when adding new blueprints
export function configurationKeyDescription(key: Key | string) {
  switch (key as Key) {
    case "wall": return "The wall thickness used for all supported walls.";
    case "shell": return "The shell will hollow out a full shape keeping a wall of the specified thickness.";
    case "stackingBottom": return "This tray can be stacked on top of other trays.";
    case "stackingHeight": return "The height of the tray base and top surface modifiers that are used to enable tray stacking.";
    case "stackingWiggle": return "The vertical offset used to correct the height of tray stacks.";
    case "hexCutTrimThreshold": return "Prevent a hexagon cutout when any edge of the face is smaller than the provided value.";
    case "hexCutOffsetX": return "The offset of the hexagon cutout along the X axis. This configuration can be used to reduce the amount of wonky cuts.";
    case "hexCutOffsetY": return "The offset of the hexagon cutout along the Y axis. This configuration can be used to reduce the amount of wonky cuts.";
    case "hWiggle": return "The horizontal distance between measured board game material and its containing pocket.";
    case "vWiggle": return "The vertical distance between measured board game material and the end of its containing pocket.";
    case "insertPath": return "Inserts can be organized in a directory structure. Provide a path to set the location of this insert within that structure.";
    case "placement": return "The element placement is controlled by alignment and growth for each axis individually. They affect the size and padding of the element and are used to dynamically alter the entire tray depending on its requested size.";
    case "trayWiggle": return "The tray wiggle is subtracted from the inner box dimensions along the X and Y axis to allow easy access to the trays. Note that the wiggle is subtracted only once which means that larger boxes have the same wiggle as small boxes.";
    case "settingCanvasSnapDistance": return "The distance threshold which controls snapping to other trays and the insert boundary.";
    default: return undefined;
  }
}

// format keys of all tray configurations here, search for NEW-BLUEPRINT when adding new blueprints
export function configurationKeyEnumerations(key: Key | string) {
  switch (key as Key) {
    case "orientation":
    case "hexCutOrientation": return ["horizontal", "vertical"];
    case "anchor": return ["center", "xmin", "xmax", "ymin", "ymax"];
    case "topStacking": return ["disabled", "enabled", "enabled, individual"];
    case "cornerModifier":
    case "takeoutAssistCutoutModifier":
    case "takeoutAssistCornerModifier": return ["chamfer", "fillet"];
    default: return undefined;
  }
}

// format keys of all tray configurations here, search for NEW-BLUEPRINT when adding new blueprints
export function configurationGroup(key: Key | string) {
  switch (key as Key) {
    case "hWiggle":
    case "vWiggle": return "Wiggle";
    case "stackingHeight":
    case "stackingWiggle":
    case "stackingBottom": return "Tray stacking";
    case "hexCutEnable":
    case "hexCutTrimThreshold":
    case "hexCutWall":
    case "hexCutOrientation":
    case "hexCutOffsetX":
    case "hexCutOffsetY":
    case "hexCutPrevent":
    case "hexCutAlign":
    case "hexCutRadius": return "Hexagon cutout";
    case "x":
    case "y":
    case "z": return "Dimensions";
    case "traySizeX":
    case "traySizeY":
    case "traySizeZ":
    case "trayLayout": return "Tray size";
    case "trayOffsetX":
    case "trayOffsetY":
    case "trayOffsetZ": return "Tray offset";
    case "takeoutAssistEnableCornerModifier":
    case "takeoutAssistCornerModifier":
    case "takeoutAssistCornerLength":
    case "takeoutAssistCutoutModifier":
    case "takeoutAssistCutoutLength":
    case "takeoutAssistCutoutWidth":
    case "takeoutAssistCutoutWidthPercent":
    case "takeoutAssistCutoutDepth":
    case "takeoutAssistCutoutDepthPercent": return "Takeout assistence";
    case "cornerModifierEnable":
    case "cornerModifier":
    case "cornerModifierLength": return "Corner modifier";
    case "settingShowTrayEditor":
    case "settingShowTrayHistory":
    case "settingShowTrayWorkers": return "Panels";
    case "settingCanvasSnapDistance": return "Canvas";
    default: return undefined;
  }
}

export function formatConfigurationValue(key: Key | string, value: unknown) {
  const enumerations = configurationKeyEnumerations(key as Key);
  if (enumerations !== undefined && typeof value === "number") {
    return enumerations[value];
  }
  switch (typeof value) {
    case "string": return `"${value}"`;
    case "number": return value.toFixed(1) + "mm";
    case "boolean": return value ? "true" : "false";
    default: return String(value);
  }
}

export function getDefaultInsertGlobals(): InsertGlobals {
  return {
    hWiggle: 1,
    vWiggle: 3,
    stackingHeight: 4,
    stackingWiggle: 0.858,
    wall: 1.2,
  };
}

export function getDefaultGridElementLayout(): GridElementLayout {
  return {
    type: "grid",
    row: 0,
    column: 0,
    xAlign: HorizontalAlignment.Center,
    yAlign: VerticalAlignment.Center,
    xGrow: 1,
    yGrow: 1,
    zGrow: 0,
  };
}

export function getDefaultGridElementConfiguration(): GridElementConfiguration {
  return {
    topStacking: TopStacking.Disabled,
    rows: 2,
    cols: 2,
  };
}

export function getDefaultStackElementLayout(): StackElementLayout {
  return {
    type: "stack",
    xAlign: HorizontalAlignment.Center,
    yAlign: VerticalAlignment.Center,
    xGrow: 1,
    yGrow: 1,
    zGrow: 1,
  };
}

export function getDefaultStackElementConfiguration(): StackElementConfiguration {
  return {
    topStacking: TopStacking.Disabled,
    orientation: Orientation.Horizontal,
  };
}

export function getDefaultCanvasElementLayout(): CanvasElementLayout {
  return {
    type: "canvas",
    anchor: Anchor.Center,
    xAlign: HorizontalAlignment.Center,
    yAlign: VerticalAlignment.Center,
    xGrow: 1,
    yGrow: 1,
    zGrow: 0,
  };
}

export function getDefaultCanvasElementConfiguration(): CanvasElementConfiguration {
  return {
    topStacking: TopStacking.Disabled,
    xMin: 60,
    yMin: 60,
  };
}


export function getDefaultElementLayout(type: Omit<ElementType, "pocket">, forContainer: boolean) {
  let layout: ElementLayout;
  switch (type) {
    case "canvas": layout = getDefaultCanvasElementLayout(); break;
    case "grid": layout = getDefaultGridElementLayout(); break;
    default: layout = getDefaultStackElementLayout(); break;
  }
  if (forContainer) {
    layout.zGrow = 0;
  }
  return layout;
}

export function getDefaultContainerElementConfiguration(type: Omit<ElementType, "pocket">) {
  switch (type) {
    case "canvas": return getDefaultCanvasElementConfiguration();
    case "grid": return getDefaultGridElementConfiguration();
    default: return getDefaultStackElementConfiguration();
  }
}

export function getDefaultInsert(options?: {
  name?: string,
  trays?: number[],
  globals?: InsertGlobals
}): Insert {
  if (options === undefined) {
    options = {};
  }
  if (options.name === undefined) {
    options.name = "New insert";
  }
  if (options.trays === undefined) {
    options.trays = [1];
  }
  if (options.globals === undefined) {
    options.globals = getDefaultInsertGlobals();
  }
  return inflateInsert({
    id: 1,
    version: 0,
    name: options.name,
    path: "/",
    gameId: -1,
    expansions: [],
    trays: options.trays,
    globals: options.globals,
    configuration: {
      trayWiggle: 1.2,
      x: 300,
      y: 300,
      z: 50,
    },
  });
}

export function getDefaultBlueprint(options?: {
  id?: number,
  name?: string,
  globals?: InsertGlobals,
}): Blueprint {
  if (options === undefined) {
    options = {};
  }
  if (options.id === undefined) {
    options.id = 1;
  }
  if (options.name === undefined) {
    options.name = defaultTrayName;
  }
  if (options.globals === undefined) {
    options.globals = getDefaultInsertGlobals();
  }
  return inflateBlueprint({
    id: options.id,
    name: options.name,
    type: "layout",
    version: 0,
    color: "#0ea5e9",
    globals: options.globals,
    configuration: getDefaultBlueprintConfiguration("layout"),
    elements: {
      0: {
        id: 0,
        type: "stack",
        childs: [1],
        configuration: getDefaultStackElementConfiguration(),
        layout: {
          type: "stack",
          xAlign: 0.5,
          yAlign: 0.5,
          az: 1,
          xGrow: 1,
          yGrow: 1,
          zGrow: 0,
        },
      },
      1: {
        id: 1,
        name: "Major Pocket",
        type: "pocket",
        pocketType: "tokens",
        configuration: getDefaultPocketElementConfiguration("tokens"),
        layout: {
          type: "stack",
          xAlign: 0.5,
          yAlign: 0.5,
          az: 1,
          xGrow: 1,
          yGrow: 1,
          zGrow: 0,
        },
      }
    },
    root: 0,
  });
}

export function getDefaultTray(options?: {
  id?: number,
  blueprintId?: number,
}) {
  if (options === undefined) {
    options = {};
  }
  if (options.id === undefined) {
    options.id = 1;
  }
  if (options.blueprintId === undefined) {
    options.blueprintId = 1;
  }
  return inflateTray({
    id: options.id,
    type: TrayType.Primary,
    blueprintId: options.blueprintId,
    offset: {
      x: 0,
      y: 0,
      z: 0,
    },
    size: {
      x: 0,
      y: 0,
      z: 0,
    }
  });
}

export function getDefaultElementInstance() : TrayElement {
  return {
    measure: {
      xMin: 0,
      yMin: 0,
      zMin: 0,
    },
    placement: {
      x: 0,
      y: 0,
      z: 0,
      xOffset: 0,
      yOffset: 0,
      zOffset: 0,
    }
  };
}


export function getDefaultSettings(): Settings {
  return {
    settingShowTrayEditor: false,
    settingShowTrayHistory: true,
    settingShowTrayWorkers: false,
    settingCanvasSnapDistance: 3,
  };
}
