import { Badge } from "@ui/badge";
import { GeometryFace } from "@/types";
import { printable } from "@/utils";
import { PaddingIcon } from "@radix-ui/react-icons";

export default function CanvasFaceInfo({ face, designator }: { face: GeometryFace, designator: string | undefined }) {
  let area: number;
  let summary: string;
  if(face.size.x < 0.05) {
    area = face.size.y * face.size.z;
    summary = printable(face.size.y) + " x " +  printable(face.size.z) + " (YZ)";
  } else if(face.size.y < 0.05) {
    area = face.size.x * face.size.z;
    summary = printable(face.size.x) + " x " +  printable(face.size.z) + " (XZ)";
  } else {
    area = face.size.x * face.size.y;
    summary = printable(face.size.x) + " x " +  printable(face.size.y) + " (XY)";
  }
  return (
    <div className="flex flex-col rounded-sm border bg-white" >
      <div className="flex items-center gap-2 rounded-t-sm bg-slate-100 p-1">
        {designator !== undefined && (
          <Badge className="px-1.5 font-mono">{designator}</Badge>
        )}
        <PaddingIcon/>
        <div className="select-none">Face</div>
        <div className="flex-auto"/>
        <Badge className="font-mono">area: {printable(area)}mm²</Badge>
      </div>
      <div className="flex gap-1 p-1">
        <Badge variant="outline" className="font-mono">{summary}</Badge>
        <div className="select-none">@</div>
        <Badge variant="outline" className="font-mono">{printable(face.center.x)}, {printable(face.center.y)}, {printable(face.center.z)}</Badge>
      </div>
    </div>
  );
}