import { Button } from "@ui/button";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Avatar, AvatarFallback } from "@ui/avatar";
import { ChevronDownIcon, EnterIcon, PersonIcon } from "@radix-ui/react-icons";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import NavigationLogin from "./NavigationLogin";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu"
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyLoginQuery } from "@/state/api/site";
import { endSession, startSession } from "@/state/session";
import { Spinner } from "@ui/spinner";

export default function NavigationMenuProfile({ children, ...props} : React.HTMLAttributes<HTMLButtonElement>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [triggerLogin, loginStatus] = useLazyLoginQuery();
  const dispatch = useAppDispatch();
  const session = useAppSelector(s => s.session);
  const [visible, setVisible] = useState(searchParams.get("login") === "true" && session.type === "new");
  useLayoutEffect(() => {
    if(session.token !== undefined && session.type === "new") {
      triggerLogin({}).then(r => {
        if(r.isSuccess) {
          dispatch(startSession({
            type: "cookie",
            user: r.data,
            token: r.data.token,
          }));
        }
      });
    }
  }, [ session.type ]);
  if(loginStatus.isFetching) {
    return (
      <div className="flex h-8 items-center gap-2 rounded-sm border px-2">
        <Spinner/>
        Logging you in...
      </div>
    );
  } else if (session.user !== undefined) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="group h-8 px-2" {...props}>
            <div className="flex gap-2">
              {children}
            </div>
            <Avatar>
              <AvatarFallback className="border-2 border-green-400 bg-white group-hover:bg-green-50 group-focus:bg-green-50">
                {session.user.name[0].toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <div className="flex items-center gap-2 p-2">
            <Avatar>
              <AvatarFallback>
                {session.user.name[0]}
              </AvatarFallback>
            </Avatar>
            {session.user.name}
          </div>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem>
              My inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Shared inserts
            </DropdownMenuItem>
            <DropdownMenuItem>
              Liked inserts
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              Settings
            </DropdownMenuItem>
          <DropdownMenuItem onClick={() => {
            dispatch(endSession());
            navigate("/");
          }}>
            Log out
          </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  } else {
    return (
      <>
        <Dialog open={visible} onOpenChange={setVisible}>
          <DialogTrigger asChild>
            <Button variant="outline" className="px-2 underline">
              <EnterIcon />
              Log in
            </Button>
          </DialogTrigger>
          <DialogContent className="w-96">
            <NavigationLogin tokenReceived={t => {
              setVisible(false);
              triggerLogin({
                token: t,
              }).then(r => {
                if(r.isSuccess) {
                  dispatch(startSession({
                    type: "login",
                    user: r.data,
                    token: r.data.token,
                  }));
                  const redirect = searchParams.get("redirect");
                  if(redirect !== null) {
                    navigate(decodeURI(redirect));
                  }
                }
              });
            }}/>
          </DialogContent>
        </Dialog>
        <Button variant="outline" className="px-2">
          <PersonIcon />
          Sign up
        </Button>
      </>
    );
  }
}