import { DbInsert } from "@/state/api/inserts";  
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@ui/card"
import { Button } from "@ui/button";

export default function WorkbenchInsert({ insert } : {insert: DbInsert}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{insert.name}</CardTitle>
        <CardDescription>{insert.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <p></p>
      </CardContent>
      <CardFooter>
        <Button>Open</Button>
      </CardFooter>
    </Card>
  );
}