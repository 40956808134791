import * as rep from "replicad";
import { InsertGlobals, Part, PocketElementBlueprint, TrayMeasure, ConfigurationHexCutPrevent, GenerationOptions, TrayElement, ConfigurationPart, ConfigurationStackCount, MeasurementOptions } from "@/types";

export const type = "part-vertical";

export type Configuration = ConfigurationHexCutPrevent & ConfigurationPart & ConfigurationStackCount;

export function getDefaultConfiguration(): Configuration {
  return {
    hexCutPrevent: false,
    stackCount: 1,
    part: { width: 0, depth: 0, height: 0, points: [] }
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals, options: MeasurementOptions) {
  const measure: TrayMeasure = {
    xMin: c.part.width + (globals.wall + globals.hWiggle) * 2 ,
    yMin: c.part.depth + (globals.wall + globals.hWiggle) * 2,
    zMin: c.part.height * c.stackCount + globals.vWiggle,
  };
  return measure;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part {
  const c = blueprint.configuration as Configuration;
  const offset = globals.wall + globals.hWiggle;
  const contour = rep.draw([ offset + c.part.points[0], offset + c.part.points[1]]);
  for(let index = 2; index < c.part.points.length; index += 2) {
    contour.lineTo([ offset + c.part.points[index], offset + c.part.points[index + 1]]);
  }
  const perimeter = contour.close().offset(globals.hWiggle);
  let preserveAll = perimeter.offset(globals.wall);
  if (!c.hexCutPrevent) {
    preserveAll = preserveAll.cut(perimeter);
  }
  const cutout = perimeter
    .sketchOnPlane("XY")
    .extrude(element.placement.z + options.zPosExtent) as rep.Shape3D;
  return {
    cutout: cutout,
    preserveAll: preserveAll,
  };
}