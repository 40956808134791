import { getBlueprintForTray, selectBlueprintForCurrentTray, selectCurrentTray, useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsert, modifyInsertConfiguration, modifyBlueprint, modifyTray } from "../../../state/model";
import ConfigurationEditor from "../ConfigurationEditor";
import { InputSubmit } from "@ui/inputSubmit";
import { Drawer, Blueprint } from "@/types";
import { useMemo } from "react";
import { InputNumber } from "@ui/inputNumber";
import { getTrayLayoutOffsets } from "@/catalog";
import { printable, roundNumber } from "@/utils";
import { Label } from "@ui/label";
import BlueprintMessages from "../blueprint/BlueprintMessages";

export default function InsertTrayProperties({ query }: { query: string }) {
  const tray = useAppSelector(selectCurrentTray)!;
  const blueprint = useAppSelector(selectBlueprintForCurrentTray)!;
  const configuration = useAppSelector(s => s.model.insert.configuration);
  const dispatch = useAppDispatch();
  const offsets = getTrayLayoutOffsets(blueprint);
  const minX = roundNumber(tray.measure.xMin + offsets.start.x + offsets.extent.x);
  const maxX = tray.measure.xLimit !== undefined ? tray.measure.xLimit + offsets.start.x + offsets.extent.x : undefined;
  const minY = roundNumber(tray.measure.yMin + offsets.start.y + offsets.extent.y);
  const maxY = tray.measure.yLimit !== undefined ? tray.measure.yLimit + offsets.start.y + offsets.extent.y : undefined;
  const minZ = roundNumber(tray.measure.zMin + offsets.start.z + offsets.extent.z);
  const maxZ = tray.measure.zLimit !== undefined ? tray.measure.zLimit + offsets.start.z + offsets.extent.z : undefined;
  const drawers = useMemo<Drawer[]>(() => [{
    keys: [ "trayName" ],
    control: <InputSubmit initialValue={tray.name ?? ""} applyValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        name: v,
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "trayOffsetX" ],
    control: <InputNumber unit="mm" min={0} max={configuration.x - tray.size.x} value={tray.offset.x} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: v,
          y: tray.offset.y,
          z: tray.offset.z,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "trayOffsetY" ],
    control: <InputNumber unit="mm" min={0} max={configuration.y - tray.size.y} value={tray.offset.y} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: tray.offset.x,
          y: v,
          z: tray.offset.z,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "trayOffsetZ" ],
    control: <InputNumber unit="mm" min={0} max={configuration.z - tray.size.z} value={tray.offset.z} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        offset: {
          x: tray.offset.x,
          y: tray.offset.y,
          z: v,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "traySizeX" ],
    control: <InputNumber unit="mm" min={minX} max={maxX} value={tray.size.x} magic={{
      tooltip: "Reset to minimal width.",
      icon: "reset",
      handler: () => minX,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: v,
          y: tray.size.y,
          z: tray.size.z,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "traySizeY" ],
    control: <InputNumber unit="mm" min={minY} max={maxY} value={tray.size.y} magic={{
      tooltip: "Reset to minimal depth.",
      icon: "reset",
      handler: () => minY,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: tray.size.x,
          y: v,
          z: tray.size.z,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "traySizeZ" ],
    control: <InputNumber unit="mm" min={minZ} max={maxZ} value={tray.size.z} magic={{
      tooltip: "Reset to minimal height.",
      icon: "reset",
      handler: () => minZ,
    }} setValue={v => {
      dispatch(modifyTray({
        tray: tray.id,
        size: {
          x: tray.size.x,
          y: tray.size.y,
          z: v,
        },
      }));
    }} />,
    useLabel: true,
  }, {
    keys: [ "trayLayout" ],
    control: <div className="flex flex-col gap-1 rounded border p-2">
      <Label htmlFor="layout">
        Minimal tray size
      </Label>
      <div className="text-sm text-slate-500">
        The minimal tray size is determined by the tray configuration.
      </div>
      <table id="layout" className="font-mono text-sm">
        <tbody className="table">
          <tr>
            <td className="pr-2">
              Width
            </td>
            <td>
              {printable(minX)} mm
            </td>
          </tr>
          <tr>
            <td className="pr-2">
              Depth
            </td>
            <td>
              {printable(minY)} mm
            </td>
          </tr>
          <tr>
            <td className="pr-2">
              Height
            </td>
            <td>
              {printable(minZ)} mm
            </td>
          </tr>
        </tbody>
      </table>
    </div>,
    useLabel: false,
  }], [ tray ]);
  return (
    <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => {}} drawers={drawers}/>
  );
}