import { Blueprint, Tray, TrayType } from "@/types";
import { getTrayName, printable } from "@/utils";
import { CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { QuestionCircleIcon } from "react-line-awesome";
import DesignerMessagesBar from "../DesignerMessagesBar";

export default function BlueprintCanvasTray({tray, blueprint} : {tray: Tray, blueprint: Blueprint}) {
  const info = tray.messages.filter(m => m.type === "info").length;
  const warning = tray.messages.filter(m => m.type === "warning").length;
  const error = tray.messages.filter(m => m.type === "error").length;
  return <div className="flex flex-col items-start">
    {tray.type === TrayType.Preview ? (
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center">
            <div className="mr-1 text-nowrap">Preview</div>
            <QuestionCircleIcon size="lg"/>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          The preview always uses the minimal size of the tray. This helps to visualize the original layout.
        </TooltipContent>
      </Tooltip>
    ) : (
      <div className="">{getTrayName(blueprint.name, tray.name)}</div>
    )}
    <div className="font-mono text-slate-500">{printable(tray.size.x)}/{printable(tray.size.y)}/{printable(tray.size.z)}</div>
    <DesignerMessagesBar infoCount={info} warningCount={warning} errorCount={error}/>
  </div>;
}