import { Button } from "@ui/button";
import { addBlueprint, moveTray, select } from "../../../state/model";
import { getTray, useAppDispatch, useAppSelector } from "@/state/store";
import InsertTrayListItem from "./InsertTrayListItem";
import { defaultTrayName, getDefaultBlueprint } from "../../../catalog";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import InsertTrayLibrary from "./InsertTrayLibrary";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import { useMemo } from "react";
import { TrayType } from "@/types";
import InsertTrayListLinks from "./InsertTrayListLinks";

interface TrayItem {
  name: string,
  index: number,
}

interface TrayItemActionMove {
  type: "move",
  oldIndex: number,
  newIndex: number,
}

interface TrayItemActionReset {
  type: "reset",
  items: TrayItem[],
}

type TrayItemAction = TrayItemActionReset | TrayItemActionMove;

/*
function traysReducer(draft: TrayItem[], action: TrayItemAction) {
  if(action.type == "move") {
    const removed = draft.splice(action.oldIndex, 1);
    draft.splice(action.newIndex, 0, ...removed);
  } else {
    draft = action.items;
  }
}

export default function InsertTrayList({ insert }: { insert: Insert}) {
  const dispatch = useAppDispatch();
  const [items, setItems] = useImmerReducer(traysReducer, insert.trays.map(t => ({
    index: t.index,
    name: t.name,
  })));
  const [hasChanges, setHasChanges]  = useState(false);
  useEffect(() => {
    setHasChanges(false);
    setItems({
      type: "reset",
      items: insert.trays.map(t => ({
        index: t.index,
        name: t.name,
      })),
    });
  }, [insert ]);
  const listItems: JSX.Element[] = [];
  for(let index = 0; index < items.length; index++) {
    listItems.push((
      <Draggable key={index} id={index} index={index} itemType="tray" className="flex items-center" moveDraggable={(dragIndex, hoverIndex) => {
        setItems({
          type: "move",
          oldIndex: dragIndex,
          newIndex: hoverIndex,
        });
        setHasChanges(true);
      }}>
        <DragHandleDots2Icon className="w-4 h-4 mr-1"/>
        <div className="flex-auto">
          {items[index].name}
        </div>
        <Button onClick={() => dispatch(openTray({
          trayIndex: index,
        }))} variant="ghost" className="p-2">
          <GearIcon className="w-4 h-4"/>
        </Button>
        <Button onClick={() => dispatch(deleteTray({
            trayIndex: index,
        }))} variant="ghost" className="p-2">
          <TrashIcon  className="w-4 h-4"/>
        </Button>
      </Draggable>
    ));
  }
  return (
    <>
      <div className="flex flex-row items-center mb-2">
        <div className="flex-auto font-medium text-slate-700">
          {insert.trays.length} tray{insert.trays.length == 1 ? "" : "s"}
        </div>
        <Button variant="outline" onClick={() => dispatch(addTray({
          tray: {
            index: 0, // assigned automatically
            name: "tray " + insert.trays.length,
            type: "horizontal-stack",
            configuration: DefaultTrayConfigurationHorizontalStack,
            globals: {},
            pockets: [],
            changes: {
              configuration: {},
              globals: {},
              pocketsAdded: [],
              pocketsDeleted: [],
            },
        }}))}>Add tray</Button>
      </div>
      <div className="grid grid-cols-1 gap-1">
        <DndProvider backend={HTML5Backend}>
          {listItems}
        </DndProvider>
      </div>
      {hasChanges && (
        <Button variant="outline" onClick={() => setHasChanges(false)}>Apply changes</Button>
      )}
    </>
  );
}

*/

export default function InsertTrayList() {
  const dispatch = useAppDispatch();
  const trayIds = useAppSelector(s => s.model.insert.trays);
  const items = useMemo(() => {
    const blueprints: {[blueprintId: number]: {
      id: number,
      group?: number,
      primary?: number,
      secondary: number[]
    }} = {};
    for(const trayId of trayIds) {
      const tray = getTray(trayId);
      if(!(tray.blueprintId in blueprints)) {
        blueprints[tray.blueprintId] = {
          id: tray.blueprintId,
          secondary: [],
        };
      }
      if(tray.type === TrayType.Primary) {
        blueprints[tray.blueprintId].primary = tray.id;
      } else if(tray.type === TrayType.Secondary) {
        blueprints[tray.blueprintId].secondary.push(tray.id);
      }
    }
    let group = 0;
    for(const blueprint of Object.values(blueprints)) {
      if(blueprint.primary !== undefined && blueprint.secondary.length > 0) {
        blueprint.group = group++;
      }
    }
    const result: {
      trayId: number,
      blueprintId: number,
      type: "standalone" | "primary" | "secondary",
      group?: number,
    }[] = [];
    for(const trayId of trayIds) {
      for(const blueprint of Object.values(blueprints)) {
        if(blueprint.primary === trayId) {
          result.push({
            trayId: trayId,
            blueprintId: blueprint.id,
            type: "primary",
            group: blueprint.group,
          });
          break;
        } else if(blueprint.secondary.includes(trayId)) {
          result.push({
            trayId: trayId,
            blueprintId: blueprint.id,
            type: "secondary",
            group: blueprint.group,
          });
          break;
        }
      }
    }
    return result;
  }, [ trayIds ]);
  return (
    <>
      <div className="mb-2 flex flex-row items-center gap-1">
        <div className="flex-auto font-medium text-slate-700">
          {items.length} tray{items.length == 1 ? "" : "s"}
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm">
              Add from library
            </Button>
          </DialogTrigger>
          <DialogContent>
            <InsertTrayLibrary/>
          </DialogContent>
        </Dialog>
        <Button variant="outline" onClick={() => dispatch(addBlueprint({
          blueprint: getDefaultBlueprint({
            name: defaultTrayName + " " + trayIds.length,
          }),
          selectIn: "blueprint",
        }))} size="sm">Add new</Button>
      </div>
      <DndContext autoScroll={false} onDragEnd={e => {
        if(e.over === null) {
          return;
        }
        dispatch(moveTray({
          tray: Number(e.active.id),
          index: trayIds.indexOf(Number(e.over.id)),
        }));
      }} modifiers={[]}>
        <SortableContext items={trayIds} id="trays" strategy={verticalListSortingStrategy}>
          <div className="flex">
            <div className="flex flex-auto flex-col">
              {items.map(item => <InsertTrayListItem key={item.trayId} item={item}/>)}
            </div>
            <InsertTrayListLinks items={items}/>
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
}