import { DbTray } from "@/state/api/trays";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export default function InsertTrayLibraryTray( { tray, isSelected, select } : { tray: DbTray, isSelected: boolean, select: () => void }) {
  const navigate = useNavigate();
  return (
    <Button variant="outline" className={"justify-start" + (isSelected ? " text-primary underline decoration-primary" : "")} onClick={() => select()}>
      {tray.name}
    </Button>
  );
}