import { Outlet } from "react-router";
import { Link, Navigate } from "react-router-dom";
import NavigationMenu from "@/pages/navigation/NavigationMenu";
import WorkbenchMenu from "./WorkbenchMenu";
import { useAppSelector } from "@/state/store";

export default function WorkbenchLayout() {
  const session = useAppSelector(s => s.session);
  if(session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench"/>;
  }
  return (
    <div className="flex h-screen flex-col bg-slate-50">
      <div className="h-12 flex-none border-b bg-white px-2">
        <NavigationMenu showInsert={true} scope="workbench"/>
      </div>
      <div className="flex flex-auto overflow-hidden">
        <div className="w-60 border-r p-2 ">
          <WorkbenchMenu/>
        </div>
          <Outlet />
      </div>
    </div>
  );
}