import React from "react"

export interface StripesProps extends React.HTMLAttributes<HTMLDivElement> {
  background?: string,
  accent?: string,
  angle?: number,
}

const Stripes = React.forwardRef<HTMLDivElement, StripesProps>(
  ({ children, accent = "white", background = "#f1f5f9", angle = -45, ...props }, ref) => {
    return (
      <div style={{
        backgroundImage: `linear-gradient(
          ${angle}deg,
          ${background} 12.5%,
          ${accent} 12.5%,
          ${accent} 50%,
          ${background} 50%,
          ${background} 62.5%,
          ${accent} 62.5%,
          ${accent} 100%
        )`,
        backgroundSize: "8px 8px",
      }} {...props}>
        {children}
      </div>
      
    )
  }
)

Stripes.displayName = "Stripes";

export { Stripes }




