import { useAppDispatch, useAppSelector } from "@/state/store";
import ContainerElementTreeNode from "./element/ContainerElementTreeNode";
import { DndContext } from "@dnd-kit/core";
import { moveElement } from "@/state/model";

export default function BlueprintElements({ blueprintId }: { blueprintId: number }) {
  const dispatch = useAppDispatch();
  const type = useAppSelector(s => s.model.blueprints[blueprintId].type);
  const root = useAppSelector(s => s.model.blueprints[blueprintId].root);
  if(type != "layout") {
    return (
      <div>
        This tray does not support any pockets.
      </div>
    );
  }
  return (
    <DndContext autoScroll={false} onDragEnd={e => {
      if(e.over === null) {
        return;
      }
      dispatch(moveElement({
        blueprint: blueprintId,
        element: e.active.id as number,
        parent: parseInt(e.over.data.current!.sortable.containerId.substring(10)),
        index: e.over.data.current!.sortable.items.indexOf(e.over.id),
      }));
    }} modifiers={[]}>
      <ContainerElementTreeNode blueprintId={blueprintId} elementId={root} parentElementId={-1}/>
    </DndContext>
  );
}