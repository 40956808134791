import { Label } from "@ui/label";
import { Button } from "@ui/button"
import { getTray, getTraysForBlueprint, selectFromBlueprint, useAppDispatch, useAppSelector, } from "@/state/store";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@ui/dialog";
import { Input } from "@ui/input";
import { Badge } from "@ui/badge";
import { useRef } from "react";
import { select } from "@/state/model";
import { ArrowLeftIcon, TokensIcon } from "@radix-ui/react-icons";
import BlueprintShare from "./BlueprintShare";
import { TrayModel, TrayType } from "@/types";

export default function BlueprintActions({ blueprintId, models, trayId }: { blueprintId: number, trayId: number, models: TrayModel[] }) {
  const dispatch = useAppDispatch();
  const version = useAppSelector(s => s.model.blueprints[blueprintId].version);
  const library = useAppSelector(s => s.model.blueprints[blueprintId].library);
  const input = useRef<HTMLInputElement>(null);
  const isPreview = getTray(trayId).type === TrayType.Preview;
  return (
    <div className="flex flex-row items-center gap-1">
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="outline">Save</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Save current tray</DialogTitle>
            <DialogDescription>
              Save the current tray as <Badge variant="outline" className="px-1">version {version}</Badge> and clear all tracked changes. Further changes are compared with the newly created version.
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-1">
            <Label>Description</Label>
            <Input placeholder="optional description of changes" ref={input} />
            <Label className="mb-0 mt-2">Change summary</Label>
            {/**<TrayDiff showActions={false} />**/}
          </div>
          <DialogFooter>
            <Button>
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" disabled={!isPreview}>
              <TokensIcon />
              {!isPreview ? "Switch to Preview" : (library === undefined ? "Add to library" : "Update in library")}
            </Button>
          </DialogTrigger>
          <DialogContent className="max-h-[800px] max-w-[1000px]">
            <BlueprintShare blueprintId={blueprintId} models={models}/>
          </DialogContent>
        </Dialog>
     
      <div className="flex-auto" />
      {(library === undefined || library === null || !library.isIsolated) && (
        <Button onClick={() => {
          if(isPreview) {
            // do not select preview trays in insert designer as they are not listed there
            const primaryTray = getTraysForBlueprint(blueprintId).find(t => t.type === TrayType.Primary);
            if(primaryTray !== undefined) {
              dispatch(select({
                scope: "insert",
                aspect: "tray",
                trayId: primaryTray.id,
              }));
            } else {
              dispatch(select({
                scope: "insert",
                aspect: "basics",
              }));
            }
          } else {
            dispatch(select({
              scope: "insert",
              aspect: "tray",
              trayId: trayId,
            }));
          }
        }}>
          <ArrowLeftIcon/>
          back to insert
        </Button>
      )}
    </div>
  );
}