import * as rep from "replicad";
import { InsertGlobals, Modifier, Part, PocketElementBlueprint, TrayMeasure, ConfigurationXYZ, ConfigurationCornerModifier, ConfigurationHexCutPrevent, GenerationOptions, ConfigurationTakeoutAssistEnableCornerModifier, ConfigurationTakeoutAssistCornerModifier, ConfigurationTakeoutAssistCornerLength, TrayElement } from "@/types";
import * as lib from "../library";

export const type = "tokens";

export type Configuration = ConfigurationXYZ & ConfigurationCornerModifier & ConfigurationHexCutPrevent & 
                            ConfigurationTakeoutAssistEnableCornerModifier & ConfigurationTakeoutAssistCornerModifier & ConfigurationTakeoutAssistCornerLength;

export function getDefaultConfiguration(): Configuration {
  return {
    x: 40,
    y: 60,
    z: 20,
    cornerModifierEnable: true,
    cornerModifier: Modifier.Fillet,
    cornerModifierLength: 2.8,
    takeoutAssistEnableCornerModifier: true,
    takeoutAssistCornerModifier: Modifier.Fillet,
    takeoutAssistCornerLength: 2.8,
    hexCutPrevent: true,
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals) {
  const measure: TrayMeasure = {
    xMin: c.x,
    yMin: c.y,
    zMin: c.z,
  };
  return measure;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part {
  const c = blueprint.configuration as Configuration;
  // p.x == x.c and so on, this is a simple case where the outer dimensions equal the configuration
  let perimeter = lib.drawRectangle(element.placement.x, element.placement.y);
  if (c.cornerModifierEnable) {
    if (c.cornerModifier == 0) {
      perimeter = perimeter.chamfer(c.cornerModifierLength);
    } else {
      perimeter = perimeter.fillet(c.cornerModifierLength);
    }
  }
  let preserveAll = perimeter.offset(globals.wall);
  if (!c.hexCutPrevent) {
    preserveAll = preserveAll.cut(perimeter);
  }
  let cutout = perimeter
    .sketchOnPlane("XY")
    .extrude(element.placement.z + options.zPosExtent) as rep.Shape3D;
  if (c.takeoutAssistEnableCornerModifier) {
    cutout = lib.modifyShape(cutout, c.takeoutAssistCornerModifier, c.takeoutAssistCornerLength - 0.001, r => r.inPlane("XY"));
  }
  return {
    cutout: cutout,
    preserveAll: preserveAll,
  };
}