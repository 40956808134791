import { Outlet } from "react-router";
import React, { useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Label } from "@ui/label";
import { Input } from "@ui/input";
import { TextareaHTMLAttributes, useLayoutEffect, useRef, useState } from "react";
import { Textarea, TextareaProps } from "@ui/textarea";
import { Button } from "@ui/button";
import DesignerMarkup from "../designer/DesignerMarkup";
import { BoxIcon, ChevronLeftIcon, ClipboardIcon, CopyIcon, FileTextIcon, HeadingIcon, IdCardIcon, ImageIcon, Pencil2Icon, QuestionMarkCircledIcon, QuestionMarkIcon, ReaderIcon, ResetIcon, TrashIcon, UploadIcon, ViewVerticalIcon } from "@radix-ui/react-icons";
import { bytesToBase64, imageUrlFromId, sanatizeFileName, toMySqlDateString, useTextboxState } from "@/utils";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ui/tabs";
import { Popover, PopoverAnchor, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import UpdatesMarkup from "./UpdatesMarkup";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@ui/resizable";
import { TabsListProps, TabsProps } from "@radix-ui/react-tabs";
import { DbUpdateDetails, useAddUpdateMutation, useEditUpdateMutation, useGetUpdateQuery } from "@/state/api/updates";
import { Calendar } from "@ui/calendar";

interface PostImage {
  name: string,
  source: string,
}

function UpdatesPostImage({ image, deleteImage, insertImage, updateName }: { image: PostImage, updateName: (name: string) => void, deleteImage: () => void, insertImage: () => void }) {
  const [ description, setDescription] = useState("loading image...");
  return (
    <div className="flex rounded-r-lg border">
      <img src={image.source} className="size-56 border-r bg-gray-100 object-contain p-1" onLoad={e => {
        const image = e.target as HTMLImageElement;
        setDescription(`${image.naturalWidth}x${image.naturalHeight}px`);
      }}/>
      <div className="flex flex-auto flex-col gap-1 p-2">
        <Label htmlFor="name">Name</Label>
        <Input id="name" value={image.name} onChange={e => updateName(e.target.value)} />
        <Label className="mt-2">Size</Label>
        <div className="font-mono">
          {description}
        </div>
        <div className="flex-auto" />
        <div className="flex justify-between">
          <Button variant="destructive" onClick={() => deleteImage()}>
            <TrashIcon />
            Delete
          </Button>
          <Button variant="outline" onClick={() => insertImage()}>
            Insert
          </Button>
        </div>
      </div>
    </div>
  );
}

export function UpdatesPublish() {
  return UpdatesForm({
    data: undefined,
    id: undefined,
  });
}

export function UpdatesEdit() {
  const { id } = useParams();
  const query = useGetUpdateQuery({
    id: id!,
  });
  return UpdatesForm({
    data: query.data,
    id: id,
  });
}

function getImages(id: string | undefined, content: string | undefined): PostImage[] {
  if(id === undefined || content === undefined) {
    return [];
  }
  const images: PostImage[] = [];
  for(const match of content.matchAll(/!\[[^\]]*\]\(([^)]+)\)/g)) {
    images.push({
      name: match[1],
      source: imageUrlFromId(id, match[1]),
    })
  }
  return images;
}

function CopyTemplate({ markup} : { markup: string}) {
  const [ value, setValue ] = useState(markup);
  return (
    <div>
      <UpdatesMarkup markup={value} className="rounded-t-lg border-x border-t bg-white p-2" getImageSource={name => "data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5C1 1.67157 1.67157 1 2.5 1ZM2.5 2C2.22386 2 2 2.22386 2 2.5V8.3636L3.6818 6.6818C3.76809 6.59551 3.88572 6.54797 4.00774 6.55007C4.12975 6.55216 4.24568 6.60372 4.32895 6.69293L7.87355 10.4901L10.6818 7.6818C10.8575 7.50607 11.1425 7.50607 11.3182 7.6818L13 9.3636V2.5C13 2.22386 12.7761 2 12.5 2H2.5ZM2 12.5V9.6364L3.98887 7.64753L7.5311 11.4421L8.94113 13H2.5C2.22386 13 2 12.7761 2 12.5ZM12.5 13H10.155L8.48336 11.153L11 8.6364L13 10.6364V12.5C13 12.7761 12.7761 13 12.5 13ZM6.64922 5.5C6.64922 5.03013 7.03013 4.64922 7.5 4.64922C7.96987 4.64922 8.35078 5.03013 8.35078 5.5C8.35078 5.96987 7.96987 6.35078 7.5 6.35078C7.03013 6.35078 6.64922 5.96987 6.64922 5.5ZM7.5 3.74922C6.53307 3.74922 5.74922 4.53307 5.74922 5.5C5.74922 6.46693 6.53307 7.25078 7.5 7.25078C8.46693 7.25078 9.25078 6.46693 9.25078 5.5C9.25078 4.53307 8.46693 3.74922 7.5 3.74922Z' fill='currentColor' fill-rule='evenodd' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E"}/>
      <div className="flex h-8">
        <Input value={value} onChange={e => setValue(e.target.value)} className="flex-auto rounded-e-none rounded-t-none bg-white"/>
        <Button onClick={() => setValue(markup)} variant="outline" className="h-8 rounded-e-none rounded-s-none">
          <ResetIcon/>
        </Button>
        <Button id="copy" onClick={() => navigator.clipboard.writeText(value)} variant="outline" className="h-8 rounded-s-none rounded-t-none">
          <CopyIcon/>
        </Button>
      </div>
    </div>
  );
}

export function UpdatesForm({ data, id }: {data: DbUpdateDetails | undefined, id: string | undefined }) {
  const session = useAppSelector(s => s.session);
  const [images, setImages] = useState<PostImage[]>(getImages(id, data?.content));
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [title, setTitle] = useState(data?.title ?? "");
  const [timestamp, setTimestamp] = useState(new Date(data?.timestamp === undefined ? Date.now() : Date.parse(data.timestamp)));
  const [content, setContent] = useState(data?.content ?? "");
  const [description, setDescription] = useState(data?.description ?? "");
  const inputFile = useRef<HTMLInputElement>(null);
  const [ addUpdate, addUpdateStatus] = useAddUpdateMutation();
  const [ editUpdate, editUpdateStatus] = useEditUpdateMutation();
  useEffect(() => {
    if(textareaRef.current !== null && data?.content !== undefined) {
      textareaRef.current.textContent = data.content;
    }
  } , []);
  if (session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench" />;
  } else if (!session.user.privileges.includes("post")) {
    return (
      <div>
        No post privileges.
      </div>
    );
  }
  if(addUpdateStatus.isSuccess && addUpdateStatus.data !== undefined) {
    return <Navigate to={"/updates/" + addUpdateStatus.data.id}/>;
  } else if(editUpdateStatus.isSuccess && id !== undefined) {
    return <Navigate to={"/updates/" + id}/>;
  }
  return (
    <div className="container flex flex-col gap-2">
      <div className="flex gap-2">
        <Link to="/updates">
          <Button variant="outline">
            <ChevronLeftIcon/>
            Back to Updates
          </Button>
        </Link>
        <div className="flex items-center gap-2 text-lg">
          <Pencil2Icon/>
          New Post
        </div>
        <div className="flex-auto"/>
        <Button disabled={addUpdateStatus.isLoading} onClick={() => {
          const imageData: { [name: string]: string} = {};
          for(const image of images) {
            imageData[image.name] = image.source;
          }
          if(data === undefined || id === undefined) {
            addUpdate({
              title: title,
              timestamp: toMySqlDateString(timestamp),
              configuration: "{t:1}",
              description: description,
              content: textareaRef.current!.value,
              images: imageData,
            });
          } else {
            editUpdate({
              id: id,
              title: title,
              timestamp: toMySqlDateString(timestamp),
              configuration: "{t:1}",
              description: description,
              content: textareaRef.current!.value,
              images: imageData,
            });
          }
        }}>
          {data === undefined || id === undefined ? "Publish" : "Update"}
        </Button>
      </div>
      <div className="flex flex-col gap-2 rounded border bg-white p-4">
        <div className="flex gap-2">
          <div className="flex flex-auto flex-col gap-2">
            <Label htmlFor="title">
              Title
            </Label>
            <Input id="title" defaultValue={title} onChange={e => setTitle(e.target.value)} />
          </div>
          <div className="flex w-52 flex-col gap-2">
            <Label htmlFor="timestamp">
              Timestamp
            </Label>
            <Input id="timestamp" defaultValue={toMySqlDateString(timestamp)} onChange={e => setTimestamp(new Date(Date.parse(e.target.value)))} />
          </div>
        </div>
        <Label htmlFor="description">
          Description
        </Label>
        <Textarea id="description" value={description} onChange={e => setDescription(e.target.value)} className="flex-auto"/>
        <Label htmlFor="content">
          Content
        </Label>
        <div className="flex flex-col">
          <Textarea id="content" rows={20} ref={textareaRef} className="z-10 rounded-b-none" onChange={e => setContent(e.target.value)}/>
          <div className="flex flex-wrap items-end gap-2 rounded-b border-x border-b bg-slate-50 p-2">
            <CopyTemplate markup="# heading"/>
            <CopyTemplate markup="## heading"/>
            <CopyTemplate markup="**bold text**"/>
            <CopyTemplate markup="*italic text*"/>
            <CopyTemplate markup="`code`"/>
            <CopyTemplate markup="![subtitle](name)"/>
            <CopyTemplate markup="[link text](url)"/>
          </div>
        </div>
        <Label>
          Images
        </Label>
        <div className="flex justify-start gap-1">
          <Button variant="outline" className="" onClick={async () => {
            const clipboardContents = await navigator.clipboard.read();
            for (const item of clipboardContents) {
              if (item.types.includes("image/png")) {
                const blob = await item.getType("image/png");
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                  setImages([...images, {
                    name: "test",
                    source: reader.result as string,
                  }]);
                }
                }
              }
            }}>
              <ClipboardIcon />
              Add from clipboard
            </Button>
            <input type="file" id="file" accept=".jpg,.jpeg,.png,.gif" ref={inputFile} style={{ display: "none" }} onChange={e => {
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files![0]);
              reader.onloadend = () => {
                setImages([...images, {
                  name: sanatizeFileName(e.target.files![0].name, true),
                  source: reader.result as string,
                }]);
              }
            }} />
            <Button variant="outline" onClick={async () => {
              inputFile.current!.click();
            }}>
              <UploadIcon />
              Upload
            </Button>
        </div>
        {images.length > 0 && (
        <div className="grid grid-cols-3 gap-1">
          {images.map(i => <UpdatesPostImage key={i.name} image={i} deleteImage={() => setImages(images.filter(o => o != i))} insertImage={() => {
            const image = `![subtitle](${i.name})`;
            const index = textareaRef.current!.selectionStart;
            // https://stackoverflow.com/questions/37482620/how-to-insert-text-into-a-textarea-using-javascript-without-messing-up-edit-hist
            textareaRef.current!.focus();
            const el = document.createElement('p');
            el.innerText = image;
            document.execCommand('insertHTML', false, el.innerHTML);
            textareaRef.current!.selectionStart = index;
            textareaRef.current!.selectionEnd = index + image.length;
            setContent(textareaRef.current!.value);
          }} updateName={name => {
            setImages(images.map(o => {
              if(o != i) {
                return o;
              }
              return {
                name: name,
                source: i.source,
              };
            }));
          }}/>)}
        </div>
        )}
      </div>
      <div className="flex flex-col gap-2 rounded border bg-white p-4">
        <Label>
          Preview
        </Label>
        <UpdatesMarkup markup={content} getImageSource={name => {
          const image = images.find(i => i.name == name);
          if(image) {
            return image.source;
          }
          return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAYAAADhu0ooAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJlSURBVGhD7do/TJNBGMfxB1ZacLTGwCKOzISEsLhAmXFAB1ebVFcTVpAVErvIn4mBMFN3ncEoE+DixKhCmUt/cKdA3uvde/c8d8nr+02a9q7L+8n7vu317TvQ7UX/QYPqufCV0KJVQotWCS1aTtAvXw/p/fYm/Tw7UzPp+925oNbeLu18aqsZS1gw9Ovz4UG3Oj15/Xg8+6z77eRYvZOuXxfn3alXL/9u1/LmR/WOOesexd7U/el0qP6mQd9PT9RM/LAn680GHf04VTN3t9GUFbo4W6fhoSE1SovNQqLGwnP1ypwVOlarUXu9lRxrQrbeLdH89IwamXP6MJoYf5oU2w/5onfEueQERamwHEjkDEWxsVxIlAuKYmE5kSg3FEljuZHIC4qksBJI5A1F3FgpJAqCIi6sJBIFQ1EoVhqJWKDIFxsDidigKC82FhKxQpErNiYSiV3ABmqu+ZrOLy/VDNFIpUL7ax9otPdDISYSiV6pN2FHH9aiIpH4XxJZ2PtJIxH7OXq/rHP2djGQSByKcE6O1R6p0b9wGE88GVcj2cShpk9X5LOC8k0UakL6rqBCEoP2+54MWS76JgK1LQZC18Y+sUNdVzyxsaxQV6QuJpYNmhepi4VlgfoidTGwwdBQpE4aGwTlQuoksd5QbqROCusFlULqJLC5odJIHTc2FzQWUseJdYbGRuq4sE7QVEgdB9YKTY3UhWKt0J32fnKkzoRd2d5QI3NW6Ei1ql7dlAqpy8I+qNzdxqycrgKubG1c3+KCuz9cboyIEQ5X7EkgV5tvrdjyDuyiVUKLVgktWiW0WBFdAZNKd54NKQ4QAAAAAElFTkSuQmCC";
        }} className="w-full p-2" />
      </div>
    </div>
  );
}