import { Line, Text } from "@react-three/drei";
import { TrayConnector } from "@/types";
import InsertCanvasVerticalConnectorPanel from "./InsertCanvasVerticalConnectorPanel";

export default function InsertCanvasVerticalConnector({ connector, negative, insertHeight, action } : {
  connector: TrayConnector,
  negative: boolean,
  insertHeight: number,
  action: (type: "fill" | "snap", axis: "X" | "Y" | "Z", distance: number, negative: boolean) => void,
 }) {
  const wiggle = negative ? -0.05 : 0.05;
  const sign = negative ? -1 : 1;
  const distance = connector.distance * sign;
  let xOffset: number;
  if(connector.endOrthogonalMax !== undefined && connector.endOrthogonalMin !== undefined) {
    xOffset = (Math.min(connector.startOrthogonalMax, connector.endOrthogonalMax) + Math.max(connector.startOrthogonalMin, connector.endOrthogonalMin)) / 2;
  } else {
    xOffset = (connector.startOrthogonalMin + connector.startOrthogonalMax) / 2;
  }
  return (
    <>
      <Line lineWidth={2} points={[[xOffset, connector.offset + wiggle, connector.z + 0.05], [xOffset, connector.offset + distance - wiggle, connector.z + 0.05]]} color="#22c55e"/>
      <Line lineWidth={2} points={[[connector.startOrthogonalMin, connector.offset + wiggle, connector.z + 0.05], [connector.startOrthogonalMax, connector.offset + wiggle, connector.z + 0.05]]} color="#22c55e" />
      {connector.endOrthogonalMax !== undefined && connector.endOrthogonalMin !== undefined ? (
        <Line lineWidth={2} points={[[connector.endOrthogonalMin, connector.offset + distance - wiggle, connector.z + 0.05], [connector.endOrthogonalMax, connector.offset + distance - wiggle, connector.z + 0.05]]} color="#22c55e" />
      ) : (
        <Line points={[[xOffset, connector.offset + distance, 0], [xOffset, connector.offset + distance, insertHeight]]} color="#94a3b8" />
      )}
      <InsertCanvasVerticalConnectorPanel distance={connector.distance} action={action} negative={negative} flip={xOffset <= (connector.startOrthogonalMin + connector.startOrthogonalMax) / 2} position={{
        x: xOffset,
        y: connector.offset + wiggle,
        z: connector.z + 0.05,
      }}/>
    </>
  );
}