import { Bounds, Insert, Message, Model } from "@/types";
import { boundsIntersectAny, boundsOffsetConatinsAll, boundsOffsetIntersectAny, getTrayName } from "@/utils";
import { Draft } from "@reduxjs/toolkit";

export function validateInsert(model: Draft<Model>) {
  const trays = model.insert.trays.map(id => model.trays[id]);
  const insertBounds: Bounds = {
    xOffset: 0,
    yOffset: 0,
    zOffset: 0,
    x: model.insert.configuration.x,
    y: model.insert.configuration.y,
    z: model.insert.configuration.z,
  };
  const messages: Message[] = [];
  for(const a of trays) {
    for(const b of trays) {
      if(a === b) {
        continue;
      }
      const key = [a.id, b.id].sort((a, b) => a - b).join("-");
      // check overlap
      const overlapKey = key + ":o";
      if(!messages.some(m => m.key == overlapKey)) {
        if(boundsOffsetIntersectAny(a.bounds, a.offset, b.bounds, b.offset)) {
          messages.push({
            type: "error",
            key: overlapKey,
            content: `The tray t{${getTrayName(model.blueprints[a.blueprintId].name, a.name)}} overlaps with the tray t{${getTrayName(model.blueprints[b.blueprintId].name, b.name)}}.`,
          });
        }
      }
    }
    // check if within insert bounds
    const boundsKey = a.id + ":b";
    if(!messages.some(m => m.key === boundsKey)) {
      if(!boundsOffsetConatinsAll(a.bounds, a.offset, insertBounds, {
        x: 0,
        y: 0,
        z: 0,
      })) {
        messages.push({
          type: "error",
          key: boundsKey,
          content: `The tray t{${getTrayName(model.blueprints[a.blueprintId].name, a.name)}} is not fully contained within the insert bounds.`,
        });
      }
    }
  }
  model.insert.messages = messages;
}