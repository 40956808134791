import { api } from '../store';
import { UpdateVote } from '@/types';

export interface AddUpdateResponse {
  id: string,
}

export interface AddVoteResponse {
  id: number,
}

export interface AddCommentResponse {
  id: number,
}

export interface DbUpdate {
  id: string,
  title: string,
  configuration: string,
  description: string,
  views: number,
  timestamp: string,
}

export interface DbUpdates {
  updates: DbUpdate[],
  remaining: number,
}

export interface DbAddUpdate {
  title: string,
  configuration: string,
  content: string,
  images: { [name: string] : string },
}

export interface DbUpdateComment {
  id: number,
  ownerId: number,
  ownerName: string,
  comment: string,
  timestamp: string
}

export interface DbUpdateDetails {
  title: string,
  configuration: number,
  description: string,
  content: string,
  views: number,
  timestamp: string,
  comments: DbUpdateComment[],
  votes: { [vote: number] : number },
  userVote?: number,
}

const updatesApi = api.injectEndpoints({
  endpoints: builder => ({
    listUpdates: builder.query<DbUpdates, {
      page: number,
      count: number,
    }>({
      query: (arg) => ({
        url: `/updates?page=${arg.page}&count=${arg.count}`,
      }),
      providesTags: [ "updates" ],
    }),
    getUpdate: builder.query<DbUpdateDetails, {
      id: string,
    }>({
      query: (arg) => ({
        url: `/updates/${arg.id}`,
      }),
      providesTags: [ "updates" ],
    }),
    addUpdate: builder.mutation<AddUpdateResponse, {
      title: string,
      timestamp: string,
      configuration: string,
      description: string,
      content: string,
      images: { [name: string] : string },
    }>({
      query: (arg) => ({
        url: "/updates/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "updates" ],
    }),
    deleteUpdate: builder.mutation<Record<string, never>, {
      id: string,
    }>({
      query: (arg) => ({
        url: `/updates/${arg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: [ "updates" ],
    }),
    editUpdate: builder.mutation<AddUpdateResponse, {
      id: string,
      title: string,
      timestamp: string,
      configuration: string,
      description: string,
      content: string,
      images: { [name: string] : string },
    }>({
      query: (arg) => ({
        url: `/updates/${arg.id}`,
        method: "PUT",
        body: arg,
      }),
      invalidatesTags: [ "updates" ],
    }),
    addVote: builder.mutation<AddVoteResponse, {
      updateId: string,
      vote: UpdateVote,
    }>({
      query: (arg) => ({
        url: `/updates/${arg.updateId}/vote/${arg.vote}`,
        method: "POST",
        body: arg,
      }),
    }),
    addComment: builder.mutation<AddCommentResponse, {
      updateId: string,
      comment: string,
    }>({
      query: (arg) => ({
        url: `/updates/${arg.updateId}/comment`,
        method: "POST",
        body: {
          comment: arg.comment,
        },
      }),
      invalidatesTags: [ "updates" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddUpdateMutation, useListUpdatesQuery, useGetUpdateQuery, useEditUpdateMutation, useDeleteUpdateMutation, useAddCommentMutation } = updatesApi;