import { InsertGlobals, Part, PocketElementBlueprint, TrayMeasure, ConfigurationHexCutPrevent, ConfigurationX, ConfigurationY, GenerationOptions, TrayElement } from "@/types";
import * as lib from "../library";

export const type = "tokens-spacer";

export type Configuration = ConfigurationX & ConfigurationY & ConfigurationHexCutPrevent;

export function getDefaultConfiguration(): Configuration {
  return {
    x: 1.2,
    y: 1.2,
    hexCutPrevent: false,
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals) {
  const m: TrayMeasure = {
    xMin: c.x,
    yMin: c.y,
    zMin: 0, // always use requested size
  };
  return m;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part {
  const c = blueprint.configuration as Configuration;
  const perimeter = lib.drawRectangle(element.placement.xTotal ?? element.placement.x, element.placement.yTotal ?? element.placement.y);
  return {
    preserveAll: c.hexCutPrevent ? perimeter.offset(globals.wall) : perimeter.offset(globals.wall).cut(perimeter),
  };
}