/* eslint-disable react/no-unknown-property */
import { useMemo, useCallback } from "react";
import { ReplicadFacesMesh, ReplicadEdgesMesh } from "./ReplicadMesh";
import { complement, darken, lighten } from "polished";
import { DoubleSide, Plane, Texture, Vector3 } from "three";
import ClipPlane from "./ClipPlane";
import { GeometryEdge, GeometryFace, TrayModel, Vector } from "@/types";
import { ThreeEvent } from "@react-three/fiber";
import { getEdgeIndex, getFaceIndex } from "replicad-threejs-helper";
import { Decal, Image, RenderTexture } from "@react-three/drei";
import tile from "../../../../images/selection-tile.png?url"

export function getColorVariants(color = "#5a8296") {
  return {
    base: color,
    line: darken(0.2, color),
    selected: lighten(0.15, color),
    lineselected: lighten(0.25, color),
    sideColor: complement(color),
  };
}

export function ReplicadShape({
  model,
  onFaceClick,
  onEdgeClick,
  facesHighlight,
  edgesHighlight,
  clipDirection,
  clipConstant,
  wireframe,
  color,
}: {
  model: TrayModel,
  onFaceClick: (e: ThreeEvent<PointerEvent>, index: number, face: GeometryFace) => void,
  onEdgeClick: (e: ThreeEvent<PointerEvent>, index: number, edge: GeometryEdge) => void,
  facesHighlight: number[],
  edgesHighlight: number[],
  clipDirection: Vector | null,
  clipConstant: number,
  wireframe?: boolean,
  color?: string,
}) {
  const onFacePointerDown = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (e && e.faceIndex !== undefined) {
      // @ts-expect-error geometry is not known
      const faceIndex = getFaceIndex(e.faceIndex, e.object.geometry);
      if (faceIndex < model.faces.length) {
        onFaceClick(e, faceIndex, model.faces[faceIndex]);
      }
    }
  }, [onFaceClick, model]);
  const onEdgePointerDown = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (e && e.index !== undefined) {
      // @ts-expect-error geometry is not known
      const edgeIndex = getEdgeIndex(e.index, e.object.geometry);
      if (edgeIndex < model.edges.length) {
        onEdgeClick(e, edgeIndex, model.edges[edgeIndex]);
      }
    }
  }, [onEdgeClick, model]);
  const colors = getColorVariants(color ?? model.color);
  const clippingPlane = useMemo<Plane | null>(() => {
    if (!clipDirection) {
      return null;
    }
    const plane = new Plane();
    plane.normal.set(clipDirection.x, clipDirection.y, clipDirection.z);
    plane.constant = clipConstant;
    return plane;
  }, [clipDirection, clipConstant]);
  return (
    <ClipPlane sideColor={colors.sideColor} clippingPlane={clippingPlane}>
      <ReplicadFacesMesh  faces={model.faceGeometry} highlight={facesHighlight} onPointerDown={onFacePointerDown}>
        {/* TODO readd wireframe={wireframe} */}
        <meshMatcapMaterial attach="material-0" color={colors.base} polygonOffset polygonOffsetFactor={2.0} polygonOffsetUnits={1.0} />
        <meshMatcapMaterial attach="material-1" color={colors.selected} polygonOffset polygonOffsetFactor={2.0} polygonOffsetUnits={1.0} />
      </ReplicadFacesMesh>
      <ReplicadEdgesMesh edges={model.edgeGeometry} highlight={edgesHighlight} onPointerDown={onEdgePointerDown}>
        <lineBasicMaterial attach="material-0" color={colors.line} />
        <lineBasicMaterial attach="material-1" color={colors.lineselected} />
      </ReplicadEdgesMesh>
    </ClipPlane>
  );
}