import { Link, useNavigate } from "react-router-dom";
import { Button } from "@ui/button";
import { ArrowRightIcon, BellIcon, ChevronRightIcon, EnterIcon, MixIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import NavigationMenuProfile from "./NavigationMenuProfile";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "@/state/store";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { SiteScope } from "@/types";
import { InputGameSearch } from "@/components/InputGameSearch";

export function NavigationInsert({ children, ...props} : React.HTMLAttributes<HTMLAnchorElement>) {
  const insertName = useAppSelector(s => s.model.insert.name);
  const recordsCount = useAppSelector(s => s.model.history.records.length);
  const isBlank = recordsCount === 1;
  if(isBlank) {
    return (
      <div className="flex h-full items-center border-x bg-slate-50 px-2">
        <Link to="/designer" {...props}>
          <Button variant="outline" className="h-8 px-2">
            <PlusCircledIcon className="size-5"/>
            Create new insert
          </Button>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="flex h-full items-center gap-3 border-x bg-slate-50 px-2 py-1">
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex flex-col justify-between">
              <div className="whitespace-nowrap text-sm font-semibold">
                {insertName}
              </div>
              <div className="whitespace-nowrap text-sm">
                Insert ({recordsCount})
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            sdf
          </TooltipContent>
        </Tooltip>
        <Link to="/designer" {...props}>
          <Button variant="outline" className="h-8 px-2">
            Continue
            <ArrowRightIcon/>
          </Button>
        </Link>
      </div>
    );
  }
}

export interface NavigationmenuProps extends React.HTMLAttributes<HTMLDivElement> {
  showInsert: boolean,
  scope: SiteScope,
}

export default function NavigationMenu({ showInsert, scope, ...props} : NavigationmenuProps) {
  const navigate = useNavigate();
  return (
    <div className="flex size-full items-center justify-between gap-2">
      <Link to="/" className="flex items-center gap-2">
        <MixIcon className="size-6 text-primary" />
        <div className="text-nowrap font-semibold">
          Board Game Inserts
        </div>
      </Link>
      <div className="h-full w-[1px] bg-slate-200"/>
      <div className="flex items-center">
        <Link to="/">
          <Button variant="link" size="sm" className={"px-1" + (scope !== "workbench" && scope !== "updates" ? " text-primary" : "")}>
            Explore
          </Button>
        </Link>
        {scope === "games" && (
          <>
            <ChevronRightIcon className="text-primary"/>
            <div className="p-1 text-sm font-medium text-primary">
              Games
            </div>
          </>
        )}
        {scope === "cameras" && (
          <>
            <ChevronRightIcon className="text-primary"/>
            <div className="p-1 text-sm font-medium text-primary">
              Cameras
            </div>
          </>
        )}
      </div>
      <Link to="/workbench">
        <Button variant="link" size="sm" className={"px-1" + (scope === "workbench" ? " text-primary" : "")}>Workbench</Button>
      </Link>
      <Link to="/updates">
        <Button variant="link" size="sm" className={"px-1" + (scope === "updates" ? " text-primary" : "")}>Updates</Button>
      </Link>
      <div className="flex-auto"/>
      <InputGameSearch className="flex flex-auto justify-end" clearOnSelect={true} onSelectResult={id => navigate("/games/" + id)}/>
      {showInsert && (
        <NavigationInsert/>
      )}
      <NavigationMenuProfile>
        <div className="flex gap-1">
          <BellIcon className="size-5"/>
          5
        </div>
      </NavigationMenuProfile>
    </div>
  );
}