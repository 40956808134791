
import { AlignBottomIcon, AlignCenterHorizontallyIcon, AlignCenterVerticallyIcon, AlignLeftIcon, AlignRightIcon, AlignTopIcon, SizeIcon, TransformIcon } from "@radix-ui/react-icons";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Toggle } from "@ui/toggle";
import ElementPlacementControl from "./ElementPlacementControl";
import { Button } from "@ui/button";
import { ElementLayout, ElementMeasure, ElementPlacement } from "@/types";
import { useAppDispatch } from "@/state/store";
import { modifyElementLayout } from "@/state/model";

export default function ElementPlacementDrawer({ elementId, layout, measure, placement }: { elementId: number, layout: ElementLayout, measure: ElementMeasure, placement: ElementPlacement }) {
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-wrap justify-start gap-1">
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="flex-auto justify-start">
            <TransformIcon />
            Edit
          </Button>
        </PopoverTrigger>
        <PopoverContent side="left" align="center" className="w-96">
          <ElementPlacementControl elementId={elementId} layout={layout} measure={measure} placement={placement} />
        </PopoverContent>
      </Popover>
      <Toggle variant="outline" size="sm" pressed={layout.xGrow > 0} onPressedChange={p => {
        dispatch(modifyElementLayout({
          element: elementId,
          layout: {
            xGrow: p ? 1 : 0,
          },
        }));
      }}>
        <div className="flex items-center gap-2">
          <SizeIcon />
          X
        </div>
      </Toggle>
      <Toggle variant="outline" size="sm" pressed={layout.yGrow > 0} onPressedChange={p => {
        dispatch(modifyElementLayout({
          element: elementId,
          layout: {
            yGrow: p ? 1 : 0,
          },
        }));
      }}>
        <div className="flex items-center gap-2">
          <SizeIcon />
          Y
        </div>
      </Toggle>
      <Toggle variant="outline" size="sm" pressed={layout.zGrow > 0} onPressedChange={p => {
        dispatch(modifyElementLayout({
          element: elementId,
          layout: {
            zGrow: p ? 1 : 0,
          },
        }));
      }}>
        <div className="flex items-center gap-2">
          <SizeIcon />
          Z
        </div>
      </Toggle>
      {placement.xTotal !== undefined && (
        <>
          <Toggle variant="outline" size="sm" pressed={layout.xAlign === 0} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                xAlign: 0,
              },
            }));
          }}>
            <AlignLeftIcon />
          </Toggle>
          <Toggle variant="outline" size="sm" pressed={layout.xAlign === 0.5} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                xAlign: 0.5,
              },
            }));
          }}>
            <AlignCenterHorizontallyIcon />
          </Toggle>

          <Toggle variant="outline" size="sm" pressed={layout.xAlign === 1} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                xAlign: 1,
              },
            }));
          }}>
            <AlignRightIcon />
          </Toggle>
        </>
      )}
      {placement.yTotal !== undefined && (
        <>
          <Toggle variant="outline" size="sm" pressed={layout.yAlign === 1} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                yAlign: 1,
              },
            }));
          }}>
            <AlignTopIcon />
          </Toggle>
          <Toggle variant="outline" size="sm" pressed={layout.yAlign === 0.5} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                yAlign: 0.5,
              },
            }));
          }}>
            <AlignCenterVerticallyIcon />
          </Toggle>
          <Toggle variant="outline" size="sm" pressed={layout.yAlign === 0} onPressedChange={p => {
            dispatch(modifyElementLayout({
              element: elementId,
              layout: {
                yAlign: 0,
              },
            }));
          }}>
            <AlignBottomIcon />
          </Toggle>
        </>
      )}
    </div>
  );
}