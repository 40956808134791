import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "@ui/button";
import { Cross1Icon, ExitIcon, GearIcon, ListBulletIcon, ReloadIcon, Share1Icon } from "@radix-ui/react-icons";
import { useAppDispatch } from "@/state/store";
import { endSession } from "@/state/session";

export default function WorkbenchMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch()
  return (
    <div className="flex h-full flex-col gap-2">
      <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">Workbench</h4>
      <p className="text-sm text-muted-foreground">
        This space is for you. Use it to organize your inserts and collaborate with others.
      </p>
      <Button variant="outline" className="justify-start px-2 disabled:border-primary disabled:opacity-100" onClick={() => navigate("/workbench/recent")} disabled={location?.pathname === "/workbench/recent" || location?.pathname === "/workbench"}>
        <ReloadIcon />
        Recent inserts
      </Button>
      <Button variant="outline" className="justify-start px-2 disabled:border-primary disabled:opacity-100" onClick={() => navigate("/workbench/all")} disabled={location?.pathname === "/workbench/all"}>
        <ListBulletIcon />
        All inserts
      </Button>
      <Button variant="outline" className="justify-start px-2 disabled:border-primary disabled:opacity-100" onClick={() => navigate("/workbench/inserts")} disabled={location?.pathname === "/workbench/inserts"}>
        <Share1Icon />
        Shared inserts
      </Button>
      <Button variant="outline" className="justify-start px-2 disabled:border-primary disabled:opacity-100" onClick={() => navigate("/workbench/trays")} disabled={location?.pathname === "/workbench/trays"}>
        <Share1Icon className="text-primary" />
        Shared trays
      </Button>
      <div className="flex-auto"/>
      <hr/>
      <Button variant="outline" className="justify-start px-2">
        <GearIcon />
        Settings
      </Button>
      <Button variant="outline" className="justify-start px-2" onClick={() => {
        dispatch(endSession());
        navigate("/");
      }}>
        <ExitIcon />
        Log out
      </Button>
    </div>
  );
}