import { InputSubmit } from "@/components/ui/inputSubmit";
import ConfigurationEditor from "../ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsert } from "@/state/model";
import { Drawer, ExpansionSupport, GameExpansions } from "@/types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { GameDetails, useLazyGetBoardgameQuery } from "@/state/api/boardgames";
import { Button } from "@ui/button";
import { ArrowLeftIcon, ComponentBooleanIcon, ComponentInstanceIcon, ExternalLinkIcon, GearIcon } from "@radix-ui/react-icons";
import { Stripes } from "@ui/stripes";
import { Placeholder } from "@ui/placeholder";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTrigger } from "@ui/dialog";
import { InputGameSearch } from "@/components/InputGameSearch";
import { Spinner } from "@ui/spinner";
import { SectionLabel } from "@ui/sectionLabel";
import { SectionDescription } from "@ui/sectionDescription";
import { GameCard } from "@/pages/games/GameCard";
import { Label } from "@ui/label";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import { shallowEqual } from "react-redux";
import { ScrollArea } from "@ui/scroll-area";
import { DialogProps } from "@radix-ui/react-dialog";

function parseExpansionSupport(value: string) {
  switch(value) {
    case "optional": return ExpansionSupport.Optional;
    case "required": return ExpansionSupport.Required;
    default: return ExpansionSupport.None;
  }
}

function GameSelection({ 
  onSelected 
}: { 
  onSelected: (selection: { 
    data: GameDetails, 
    expansions: GameExpansions,
  }) => void }) {
  const [getBoardgame, getBoardgameStatus] = useLazyGetBoardgameQuery();
  const [expansions, setExpansions] = useState<GameExpansions>([]);
  return (
    <>
      <SectionLabel>
        Select the board game for your insert
      </SectionLabel>
      <SectionDescription>
        Other users will find your insert when looking for the selected board game. The associated board game is also filter available boxes and parts.
      </SectionDescription>
      <InputGameSearch id="model" onSelectResult={id => getBoardgame({
        id: id,
      })} autoFocus/>
      {getBoardgameStatus.isFetching ? (
        <div className="flex h-full items-center justify-center">
          <div className="flex items-center gap-2 text-secondary-foreground">
            <Spinner />
            Loading metadata...
          </div>
        </div>
      ) : getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined ? (
        <>
          <GameCard data={getBoardgameStatus.data} />
          {getBoardgameStatus.data.expansions.length > 0 && (
            <>
              <SectionLabel>
                Select supported board game expansions
              </SectionLabel>
              <SectionDescription>
                Expansions are available for this board game. Select the expanstions that your insert supports. Optional support means that the insert has optional trays or trays that can optionally hold the expansion components. Required support means that the insert does not work without the expansion components.
              </SectionDescription>
              <ScrollArea>
                <div className="flex flex-col gap-2">
                  {getBoardgameStatus.data.expansions.map(e =>
                    <div key={e.id} className="flex flex-col gap-2 rounded border p-2">
                      <div className="text-lg">{e.name}</div>
                      <div className="flex items-end justify-between">
                        <RadioGroup defaultValue="none" onValueChange={(v: "none" | "optional" | "required") => {
                          if(v === "none") {
                            setExpansions(expansions.filter(o => o.expansionId !== e.id));
                          } else {
                            setExpansions(expansions.filter(o => o.expansionId !== e.id).concat({
                              expansionId: e.id,
                              support: parseExpansionSupport(v),
                            }));
                          }
                        }}>
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="none" id={e.id + "-none"} />
                            <Label htmlFor={e.id + "-none"}>not supported</Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="optional" id={e.id + "-optional"} />
                            <Label htmlFor={e.id + "-optional"}>optional</Label>
                          </div>
                          <div className="flex items-center gap-2">
                            <RadioGroupItem value="required" id={e.id + "-required"} />
                            <Label htmlFor={e.id + "-required"}>required</Label>
                          </div>
                        </RadioGroup>
                        <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${e.id}`, '_blank')?.focus()} size="sm">
                          <ExternalLinkIcon />
                          {e.id}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </>
          )}
          <DialogFooter>
            <div className="flex justify-end">
              <Button variant="primary" onClick={() => onSelected({
                data: getBoardgameStatus.data,
                expansions: expansions
              })}>
                Apply
              </Button>
            </div>
          </DialogFooter>
        </>
      ) : (
        <Placeholder type="info">
          Search and select a game
        </Placeholder>
      )}
    </>
  );
}

function GameControl() {
  const gameId = useAppSelector(s => s.model.insert.gameId);
  const expansions = useAppSelector(s => s.model.insert.expansions, shallowEqual);
  const dispatch = useAppDispatch();
  const [getBoardgame, getBoardgameStatus] = useLazyGetBoardgameQuery();
  const [data, setData] = useState<GameDetails>();
  useEffect(() => {
    if (gameId !== -1) {
      getBoardgame({
        id: gameId,
      });
    }
  }, [gameId]);
  useEffect(() => {
    if(getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined && !shallowEqual(getBoardgameStatus.data, data)) {
      setData(getBoardgameStatus.data);
    }
  }, [getBoardgameStatus]);
  return (
    <div className="flex flex-col gap-2">
      <Label>Game</Label>
      <div className="rounded border p-2">
        {getBoardgameStatus.isFetching ? (
          <Placeholder>
          Loading game details...
        </Placeholder>
        ) : (
          <div className="flex gap-2">
            {data !== undefined ? (
              <img src={data.image} className="w-32 rounded shadow-lg" />
            ) : (
              <Stripes className="h-40 w-32 rounded border shadow" />
            )}
            <div className="flex flex-auto flex-col justify-between">
            {data !== undefined ? (
              <div className="flex flex-col">
                <div className="text-lg">
                  {data.name} <span className="text-secondary-foreground">({data.year})</span>
                </div>
                {expansions.map(e => (
                  <div key={e.expansionId} className="flex items-center gap-1">
                    {e.support === ExpansionSupport.Optional ? (
                      <ComponentBooleanIcon className="text-primary"/>
                    ) : (
                      <ComponentInstanceIcon className="text-primary"/>
                    )}
                    {data.expansions.find(o => o.id == e.expansionId)!.name}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                no game selected
              </div>
            )}
              <Dialog>
                <DialogTrigger asChild>
                  <Button className="w-full justify-start" size="sm">
                    <GearIcon />
                    Change board game
                  </Button>
                </DialogTrigger>
                <DialogContent className="w-[600px]">
                  <GameSelection onSelected={s => {
                    setData(data);
                    dispatch(modifyInsert({
                      gameId: s.data.id,
                      expansions: s.expansions,
                    }));
                  }}/>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function InsertBasicsProperties({ query }: { query: string }) {
  const dispatch = useAppDispatch();
  const name = useAppSelector(s => s.model.insert.name);
  const path = useAppSelector(s => s.model.insert.path);
  const drawers = useMemo<Drawer[]>(() => [{
    keys: ["insertGame"],
    control: <GameControl/>,
    useLabel: false,
  }, {
    keys: ["insertName"],
    control: <InputSubmit id="name" initialValue={name} applyValue={n => dispatch(modifyInsert({
      name: n,
    }))} />,
    useLabel: true,
  }, {
    keys: ["insertPath"],
    control: <InputSubmit id="path" initialValue={path} applyValue={n => dispatch(modifyInsert({
      path: n,
    }))} preprocess={v => {
      if (!v.startsWith("/")) {
        v = "/" + v;
      }
      return v;
    }} />,
    useLabel: true,
  }], [name, path]);
  return (
    <ConfigurationEditor query={query} configuration={{}} modifyConfiguration={c => { }} drawers={drawers} />
  );
}