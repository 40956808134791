import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import modelSlice from './model';
import sessionSlice from './session';
import settingsSlice from './settings';
import { setupListeners } from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Blueprint, ElementBlueprint, Tray } from '@/types';
import { LoginResponse } from './api/site';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: import.meta.env.MODE == "development" ? "http://localhost:3000" : "https://api.boardgameinserts.xyz",
    prepareHeaders: (headers, api) => {
      const token = (api.getState() as RootState).session.token;
      if(token !== undefined) {
        headers.set("Authorization", "Bearer " + token);
      }
      return headers;
    }
  }),
  tagTypes: [ "updates", "cameras", "boxes" ],
  endpoints: () => ({
  }),
});

export const store = configureStore({
  reducer: {
    model: modelSlice,
    settings: settingsSlice,
    session: sessionSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export function selectCurrentBlueprint(state: RootState) {
  if(state.model.selection.scope === "blueprint") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      return state.model.blueprints[state.model.selection.blueprintId];
    }
  }
  return null;
}

export function selectCurrentBlueprintId(state: RootState) {
  if(state.model.selection.scope === "blueprint") {
    return state.model.selection.blueprintId;
  }
  return null;
}

export function selectCurrentTray(state: RootState) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    if(state.model.selection.trayId in state.model.trays) {
      return state.model.trays[state.model.selection.trayId];
    }
  }
  return null;
}

export function selectBlueprintForCurrentTray(state: RootState) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    if(state.model.selection.trayId in state.model.trays) {
      return state.model.blueprints[state.model.trays[state.model.selection.trayId].blueprintId];
    }
  }
  return null;
}

export function selectCurrentTrayId(state: RootState) {
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    return state.model.selection.trayId;
  }
  return null;
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromCurrentBlueprint<T>(state: RootState, selector: (blueprint: Blueprint) => T) {
  const tray = selectCurrentBlueprint(state);
  if(tray !== null) {
    return selector(tray);
  }
  return null;
}

export function selectBlueprint(state: RootState, blueprintId: number) {
  return state.model.blueprints[blueprintId];
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromBlueprint<T>(state: RootState, blueprintId: number, selector: (blueprint: Blueprint) => T) {
  return selector(selectBlueprint(state, blueprintId));
}


export function selectTray(state: RootState, trayId: number) {
  return state.model.trays[trayId];
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromTray<T>(state: RootState, trayId: number, selector: (tray: Tray) => T) {
  return selector(selectTray(state, trayId));
}

export function selectCurrentElement(state: RootState) {
  if(state.model.selection.aspect === "element") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      if(state.model.selection.elementId in state.model.blueprints[state.model.selection.blueprintId].elements)
      return state.model.blueprints[state.model.selection.blueprintId].elements[state.model.selection.elementId];
    }
  }
  return null;
}

export function selectCurrentElementId(state: RootState) {
  if(state.model.selection.aspect === "element") {
    return state.model.selection.elementId;
  }
  return null;
}

export function selectElement(state: RootState, elementId: number) {
  if(state.model.selection.scope === "blueprint") {
    if(state.model.selection.blueprintId in state.model.blueprints) {
      if(elementId in state.model.blueprints[state.model.selection.blueprintId].elements)
      return state.model.blueprints[state.model.selection.blueprintId].elements[elementId];
    }
  }
  return null;
}

/**
 * make sure to use shallowEqual ar another applicable comparator when returning an object
 */
export function selectFromCurrentElement<T>(state: RootState, selector: (element: ElementBlueprint) => T) {
  const element = selectCurrentElement(state);
  if(element !== null) {
    return selector(element);
  }
  return null;
}

export function selectTraysForBlueprint(state: RootState, blueprintId: number) {
  return Object.values(state.model.trays).filter(t => t.blueprintId == blueprintId).sort((a, b) => a.type - b.type);
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getCurrentBlueprint() {
  const state =  store.getState();
  if(state.model.selection.scope === "blueprint") {
    return state.model.blueprints[state.model.selection.blueprintId] ?? null;
  }
  return null;
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getCurrentBlueprintId() {
  const state =  store.getState();
  if(state.model.selection.scope === "blueprint") {
    return state.model.selection.blueprintId;
  }
  return null;
}


/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getCurrentTray() {
  const state =  store.getState();
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    return state.model.trays[state.model.selection.trayId] ?? null;
  }
  return null;
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getCurrentTrayId() {
  const state =  store.getState();
  if(state.model.selection.scope === "insert" && state.model.selection.aspect === "tray") {
    return state.model.selection.trayId;
  }
  return null;
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getBlueprint(blueprintId: number) {
  return store.getState().model.blueprints[blueprintId];
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getTray(trayId: number) {
  return store.getState().model.trays[trayId];
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getTraysForBlueprint(blueprintId: number) {
  return Object.values(store.getState().model.trays).filter(tray => tray.blueprintId === blueprintId);
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getBlueprintForTray(trayId: number) {
  return getBlueprint(getTray(trayId).blueprintId);
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getInsert() {
  return store.getState().model.insert;
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getModel() {
  return store.getState().model;
}

/**
 * this function must only be called in business logic, never to fetch data for the UI
 */
export function getElement(blueprintId: number, elementId: number) {
  return store.getState().model.blueprints[blueprintId].elements[elementId];
}