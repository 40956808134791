import { User } from '@/types';
import { api } from '../store'

export interface LoginResponse extends User {
  token: string,
}

export interface GetTokenResponse {
  userId: number,
  token: string,
}

const siteApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.query<LoginResponse, {
      token?: string,
    }>({
      query: (arg) => ({
        url: "/auth/login",
        params: arg,
      }),
    }),
    getToken: builder.query<GetTokenResponse, {
      userId?: number,
    }>({
      query: (arg) => ({
        url: `/auth/token/${arg.userId}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useLoginQuery, useLazyLoginQuery, useGetTokenQuery } = siteApi;