import { Vector } from "@/types";
import { Html } from "@react-three/drei";
import { DiscIcon } from "@radix-ui/react-icons";
import { printable } from "@/utils";

export default function VertexInfo({ position, parentSize }: { position: Vector, parentSize?: Vector }) {
  if (parentSize === undefined || position.z < parentSize.z / 2) {
    return (
      <Html zIndexRange={[20, 0]} as="div" position={[position.x, position.y, position.z]}
      >
        <div className="flex flex-col items-center" style={{
          transform: "translate3d(-50%, 0px, 0px)"
        }}>
          <div className="size-2 rounded-full bg-slate-300" style={{
            marginTop: -4,
          }} />
          <div className="h-1 w-[2px] bg-slate-300" />
          <div className="select-none rounded border border-slate-300 bg-white px-1 py-0 font-mono">
            {printable(position.x)},{printable(position.y)},{printable(position.z)}
          </div>
        </div>
      </Html>
    );
  } else {
    return (
      <Html zIndexRange={[20, 0]} as="div"  position={[position.x, position.y, position.z]}>
        <div className="flex flex-col items-center" style={{
          transform: "translate3d(-50%, -100%, 0px)"
        }}>
          <div className="select-none rounded border border-slate-300 bg-white px-1 py-0 font-mono">
            {printable(position.x)},{printable(position.y)},{printable(position.z)}
          </div>
          <div className="h-2 w-[2px] bg-slate-300" />
          
          <div className="size-2 rounded-full bg-slate-300" style={{
            marginBottom: -4,
          }} />
        </div>
      </Html>
    );
  }
}