import { Vector } from "@/types";
import { Line, Text } from "@react-three/drei";
import { printable } from "@/utils";
import roboto from '../../../../public/fonts/RobotoMono-Regular.ttf?url'


export default function BoxFootprint({ position, size }: { position: Vector, size: Vector }) {
  return (
    <>
      <Line points={[[position.x, position.y - 10, position.z], [position.x + size.x, position.y - 10, position.z]]} color="#F87171" lineWidth={2}/>
      <Line points={[[position.x + size.x, position.y - 10.3, position.z], [position.x + size.x, position.y - 9.7, position.z]]} color="#F87171" lineWidth={2}/>
      <Line points={[[position.x, position.y - 10.3, position.z], [position.x, position.y - 9.7, position.z]]} color="#F87171" lineWidth={2}/>
      <Text position={[position.x + size.x / 2, position.y - 12, position.z]}  color="#F87171" anchorX="center" anchorY="middle" fontSize={3} font={roboto}>
        {printable(size.x)}
      </Text>
      <Line points={[[position.x - 10, position.y, position.z], [position.x - 10, position.y + size.y, position.z]]} color="#22c55e" lineWidth={2}/>
      <Line points={[[position.x - 10.3, position.y, position.z], [position.x - 9.7, position.y, position.z]]} color="#22c55e" lineWidth={2}/>
      <Line points={[[position.x - 10.3, position.y + size.y, position.z], [position.x - 9.7, position.y + size.y, position.z]]} color="#22c55e" lineWidth={2}/>
      <Text position={[position.x - 12, position.y + size.y / 2, position.z]} rotation={[0,0,-Math.PI/2]} color="#22c55e" anchorX="center" anchorY="middle" fontSize={3} font={roboto}>
        {printable(size.y)}
      </Text>
      <Text position={[position.x - 10, position.y - 7, position.z]} color="#60A5FA" anchorX="center" anchorY="middle" fontSize={2} font={roboto}>
        height
      </Text>
      <Text position={[position.x - 10, position.y - 10, position.z]} color="#60A5FA" anchorX="center" anchorY="middle" fontSize={3} font={roboto}>
        {printable(size.z)}
      </Text>
    </>
  );
  
}