/* eslint-disable react/no-unknown-property */
import { ContainerElementBlueprint, TrayElement, ElementType, PocketElementBlueprint, Blueprint, Vector } from "@/types";
import { Box } from "@react-three/drei";

export default function Skeleton({ size, pockets }: { 
  size: Vector, 
  pockets?: {
    id: number,
    blueprint: PocketElementBlueprint,
    element: TrayElement,
    parent: ContainerElementBlueprint | null
  }[],
}) {
  return (
    <>
      <Box position={ [size.x / 2, size.y / 2, size.z / 2]} scale={[size.x, size.y, size.z]}>
        <meshNormalMaterial wireframe />
      </Box>
      {pockets !== undefined && pockets.filter(e => e.blueprint.type === "pocket").map(e => (
        <Box key={e.id} position={ [e.element.placement.xOffset + e.element.placement.x / 2, e.element.placement.yOffset + e.element.placement.y / 2, e.element.placement.zOffset + e.element.placement.z / 2]} scale={[e.element.placement.x, e.element.placement.y, e.element.placement.z]}>
          <meshNormalMaterial wireframe />
        </Box>
      ))}
    </>
  );
}