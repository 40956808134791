import { Button } from "@ui/button";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { PageHeader } from "@ui/pageHeader";
import { Placeholder } from "@ui/placeholder";
import { Failure } from "@ui/failure";

export default function Home() {
  return (
    <div className="flex flex-col gap-2">
      <PageHeader>
        <SectionHeader>
          Page header, start upperscore, no notations
        </SectionHeader>
        <SectionDescription>
          Page description for long explanations and hints. Fill sentences with notations.
        </SectionDescription>
      </PageHeader>
      <Section>
        <SectionLabel>
          Content colors
        </SectionLabel>
        <div className="flex flex-wrap gap-2">
          <div className="size-20 bg-[#be123c]" />
          <div className="size-20 bg-[#0891b2]" />
          <div className="size-20 bg-[#6d28d9]" />
          <div className="size-20 bg-[#ea580c]" />
          <div className="size-20 bg-[#65a30d]" />
          <div className="size-20 bg-[#ca8a04]" />
          <div className="size-20 bg-[#c026d3]" />
          <div className="size-20 bg-[#0d9488]" />
          <div className="size-20 bg-[#d97706]" />
          <div className="size-20 bg-[#15803d]" />
          <div className="size-20 bg-[#2563eb]" />
          <div className="size-20 bg-[#e11d48]" />
          <div className="size-20 bg-[#0e7490]" />
          <div className="size-20 bg-[#a21caf]" />
          <div className="size-20 bg-[#047857]" />
        </div>
      </Section>
      <div className="flex gap-2">
        <div className="flex-1 flex flex-col gap-2">
          <SectionHeader>
            Header above section
          </SectionHeader>
          <Section>
            <SectionLabel>
              Section label, start upperscore, no notations
            </SectionLabel>
            <SectionDescription>
              Section details for long explanations and hints. Fill sentences with notations.
            </SectionDescription>
          </Section>

          <Section header={
            <Button>
              Button in section header
            </Button>
          }>
            <SectionLabel>
              Label
            </SectionLabel>
            Content
          </Section>

          <Section footer={
            <Button>
              Button in section footer
            </Button>
          }>
            <SectionLabel>
              Label
            </SectionLabel>
            Content
          </Section>

          <Section header={
            <Button>
              Button in section header
            </Button>
          } footer={
            <Button>
              Button in section footer
            </Button>
          }>
            <SectionLabel>
              Label
            </SectionLabel>
            Content
          </Section>
        </div>
        <div className="flex-1 flex flex-col gap-2">
          <Placeholder>
            Loading...
          </Placeholder>
          <Placeholder type="info">
            Information
          </Placeholder>
          <Section>
            <div className="flex gap-2">
              <Section nested className="flex-1">
                <SectionLabel>
                  Buttons enabled
                </SectionLabel>
                <Button variant="destructive">destructive</Button>
                <Button variant="ghost">ghost</Button>
                <Button variant="link">link</Button>
                <Button variant="outline">outline</Button>
                <Button variant="primary">primary</Button>
                <Button variant="secondary">secondary</Button>
                <Button variant="selector">selector</Button>
                <Button variant="selector_primary">selector_primary</Button>
              </Section>
              <Section nested className="flex-1">
                <SectionLabel>
                  Buttons disabled
                </SectionLabel>
                <Button disabled variant="destructive">destructive</Button>
                <Button disabled variant="ghost">ghost</Button>
                <Button disabled variant="link">link</Button>
                <Button disabled variant="outline">outline</Button>
                <Button disabled variant="primary">primary</Button>
                <Button disabled variant="secondary">secondary</Button>
                <Button disabled variant="selector">selector</Button>
                <Button disabled variant="selector_primary">selector_primary</Button>
              </Section>
            </div>
          </Section>
          <Failure>
            Failure
          </Failure>
        </div>
      </div>

    </div>
  );
}