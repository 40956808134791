import { getDefaultSettings } from '@/catalog';
import { Settings } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: "settings",
  initialState: getDefaultSettings(),
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<Settings>>) => {
      for(const key in action.payload) {
        const value = action.payload[key];
        if(value !== undefined) {
          state[key] = value;
        }
      }
    },
  }
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
