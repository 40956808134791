import { useAppDispatch, useAppSelector } from "@/state/store";
import { modifyInsertConfiguration } from "../../../state/model";
import ConfigurationEditor from "../ConfigurationEditor";

export default function InsertConfigurationProperties({ query }: { query: string }) {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(s => s.model.insert.configuration);
  return (
    <ConfigurationEditor query={query} configuration={configuration} modifyConfiguration={c => dispatch(modifyInsertConfiguration({
      configuration: c,
    }))}/>
  );
}