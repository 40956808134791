/* eslint-disable @typescript-eslint/no-explicit-any */
import { displayImage, getImageData } from "@/utils";
import { EnterFullScreenIcon, ExitFullScreenIcon, MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { ForwardedRef, forwardRef, HTMLAttributes, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

export interface PixelEditorRef {
  load: (image: ImageData) => void,
  save: () => ImageData | null,
}

export interface PixelEditorProps extends HTMLAttributes<HTMLDivElement> {
  sharpness: number,
  onInit?: () => void,
}

interface PixelEditorData {
  context: CanvasRenderingContext2D | null,
  originalWidth: number | null,
  originalHeight: number | null,
  undoStack: any[],
  redoStack: any[],
  lastX: number,
  lastY: number,
  lineX: number,
  lineY: number,
  points: { x: number, y: number, endX?: number, endY?: number, tool: string, mode: string }[],
  lastOnX: number,
  lastOnY: number,
  mode: Mode,
}

type Mode = "set" | "clear" | "none";
 
type Tool = "pencil" | "line" | "fill";

// https://medium.com/@oscar.lindberg/how-to-create-pixel-perfect-graphics-using-html5-canvas-3750eb5f1dc9
// https://codepen.io/tororoi/pen/PoGZOwQ
const PixelEditor = forwardRef<PixelEditorRef, PixelEditorProps>(({ sharpness, onInit, className, ...props }, ref) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const data = useRef<PixelEditorData>({
    context: null,
    originalHeight: null,
    originalWidth: null,
    undoStack: [],
    redoStack: [],
    lastOnX: 0,
    lastOnY: 0,
    lastX: 0,
    lastY: 0,
    lineX: 0,
    lineY: 0,
    points: [],
    mode: "none",
  });
  const [ tool, setTool] = useState<Tool>("pencil");
  useEffect(() => {
    if(canvasRef.current !== null) {
      data.current.context = canvasRef.current.getContext("2d");
      canvasRef.current.addEventListener("mousedown", handleMouseDown);
      canvasRef.current.addEventListener("mouseup", handleMouseUp);
      canvasRef.current.addEventListener("mouseout", handleMouseOut);
      canvasRef.current.addEventListener("mousemove", handleMouseMove);
    }
    if(onInit !== undefined) {
      onInit();
    }
  }, []);
  useEffect(() => {
    if(canvasRef.current !== null && data.current.context !== null && data.current.originalHeight !== null && data.current.originalWidth !== null) {
      canvasRef.current.width = data.current.originalWidth * sharpness;
      canvasRef.current.height = data.current.originalHeight * sharpness;
      data.current.context.scale(sharpness, sharpness);
    }
  }, [ sharpness ]);
  const load = useCallback((image: ImageData) => {
    if(canvasRef.current !== null && data.current.context !== null) {
      data.current.context!.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      canvasRef.current!.width = image.width;
      canvasRef.current!.height = image.height;
      data.current.context!.putImageData(image, 0, 0);
    }
  }, []);
  const save = useCallback(() => {
    if(canvasRef.current !== null && data.current.context !== null) {
      return data.current.context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    return null;
  }, []);
  const undo = useCallback(() => {
    if (data.current.undoStack.length > 0) {
      actionUndoRedo(data.current.redoStack, data.current.undoStack);
    }
  }, []);
  const redo = useCallback(() => {
    if (data.current.redoStack.length >= 1) {
      actionUndoRedo(data.current.undoStack, data.current.redoStack);
    }
  }, []);
  const actionDraw = useCallback((x: number, y: number, mode: Mode) => {
    if(data.current.context !== null) {
      data.current.context.fillStyle = mode === "set" ? "white" : "black";
      data.current.context.fillRect(x, y, 1, 1);
    }
  }, []);
  const actionFill = useCallback((x: number, y: number, mode: Mode) => {
    /*
    //get imageData
  let colorLayer = offScreenCTX.getImageData(
    0,
    0,
    offScreenCVS.width,
    offScreenCVS.height
  );

  let startPos = (startY * offScreenCVS.width + startX) * 4;

  //clicked color
  let startR = colorLayer.data[startPos];
  let startG = colorLayer.data[startPos + 1];
  let startB = colorLayer.data[startPos + 2];
  //exit if color is the same
  if (
    currentColor.r === startR &&
    currentColor.g === startG &&
    currentColor.b === startB
  ) {
    return;
  }
  //Start with click coords
  let pixelStack = [[startX, startY]];
  let newPos, x, y, pixelPos, reachLeft, reachRight;
  floodFill();
  function floodFill() {
    newPos = pixelStack.pop();
    x = newPos[0];
    y = newPos[1];

    //get current pixel position
    pixelPos = (y * offScreenCVS.width + x) * 4;
    // Go up as long as the color matches and are inside the canvas
    while (y >= 0 && matchStartColor(pixelPos)) {
      y--;
      pixelPos -= offScreenCVS.width * 4;
    }
    //Don't overextend
    pixelPos += offScreenCVS.width * 4;
    y++;
    reachLeft = false;
    reachRight = false;
    // Go down as long as the color matches and in inside the canvas
    while (y < offScreenCVS.height && matchStartColor(pixelPos)) {
      colorPixel(pixelPos);

      if (x > 0) {
        if (matchStartColor(pixelPos - 4)) {
          if (!reachLeft) {
            //Add pixel to stack
            pixelStack.push([x - 1, y]);
            reachLeft = true;
          }
        } else if (reachLeft) {
          reachLeft = false;
        }
      }

      if (x < offScreenCVS.width - 1) {
        if (matchStartColor(pixelPos + 4)) {
          if (!reachRight) {
            //Add pixel to stack
            pixelStack.push([x + 1, y]);
            reachRight = true;
          }
        } else if (reachRight) {
          reachRight = false;
        }
      }
      y++;
      pixelPos += offScreenCVS.width * 4;
    }

    // offScreenCTX.putImageData(colorLayer, 0, 0);
    // source = offScreenCVS.toDataURL();
    // renderImage();

    if (pixelStack.length) {
      floodFill();
      // window.setTimeout(floodFill, 100);
    }
  }

  //render floodFill result
  offScreenCTX.putImageData(colorLayer, 0, 0);

  //helpers
  function matchStartColor(pixelPos) {
    let r = colorLayer.data[pixelPos];
    let g = colorLayer.data[pixelPos + 1];
    let b = colorLayer.data[pixelPos + 2];
    return r === startR && g === startG && b === startB;
  }

  function colorPixel(pixelPos) {
    colorLayer.data[pixelPos] = currentColor.r;
    colorLayer.data[pixelPos + 1] = currentColor.g;
    colorLayer.data[pixelPos + 2] = currentColor.b;
    colorLayer.data[pixelPos + 3] = 255;
  }
    */
  }, []);
  const actionLine = useCallback((sx: number, sy: number, tx: number, ty: number, mode: Mode, scale = 1) => {
    data.current.context!.fillStyle = mode === "set" ? "white" : "black";
    function getTriangle(x1: number, y1: number, x2: number, y2: number, ang: number) {
      let x: number;
      let y: number;
      let long: number;
      if(Math.abs(x1-x2) > Math.abs(y1-y2)) {
          x = Math.sign(Math.cos(ang));
          y = Math.tan(ang) * Math.sign(Math.cos(ang));
          long = Math.abs(x1 - x2);
      } else { 
          x = Math.tan((Math.PI / 2) - ang) * Math.sign(Math.cos((Math.PI / 2) - ang));
          y = Math.sign(Math.cos((Math.PI / 2) - ang));
          long = Math.abs(y1 - y2);
      }
      return {
        x,
        y, 
        long,
      };
    }
    function getAngle(x: number ,y: number) {
      return Math.atan(y / (x == 0 ? 0.01 : x)) + (x < 0 ? Math.PI : 0); 
    }
    const angle = getAngle(tx-sx, ty-sy); // angle of line
    const tri = getTriangle(sx,sy,tx,ty, angle);
    for(let i = 0; i < tri.long; i++) {
        const thispoint = {
          x: Math.round(sx + tri.x * i), 
          y: Math.round(sy + tri.y * i)
        };
        // for each point along the line
        data.current.context!.fillRect(thispoint.x * scale, // round for perfect pixels
                    thispoint.y * scale, // thus no aliasing
                    scale, scale); // fill in one pixel, 1x1
    }
    // fill endpoint
    data.current.context!.fillRect(Math.round(tx) * scale, // round for perfect pixels
                    Math.round(ty) * scale, // thus no aliasing
                    scale, scale); // fill in one pixel, 1x1
  }, []);
  const redrawPoints = useCallback(() => {
    data.current.undoStack.forEach((action) => {
      //@ts-expect-error implicit any
      action.forEach((p) => {
        switch (p.tool) {
          case "fill":
            actionFill(p.x, p.y, p.mode);
            break;
          case "line":
            actionLine(p.startX, p.startY, p.endX, p.endY, p.color!);
            // eslint-disable-next-line no-fallthrough
          default:
            actionDraw(p.x, p.y, p.mode);
        }
      });
    });
  }, []);
  const actionUndoRedo = useCallback((pushStack: any[], popStack: any[]) => {
    pushStack.push(popStack.pop());
    data.current.context!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    redrawPoints();
  }, []);
  const handleMouseUp = useCallback((e: MouseEvent) => {
    data.current.mode = "none";
    const trueRatio = canvasRef.current!.offsetWidth / canvasRef.current!.width;
    const mouseX = Math.floor(e.offsetX / trueRatio);
    const mouseY = Math.floor(e.offsetY / trueRatio);
    if (tool === "line") {
      actionLine(data.current.lineX, data.current.lineY, mouseX, mouseY, data.current.mode);
      data.current.points.push({
        x: data.current.lineX,
        y: data.current.lineY,
        endX: mouseX,
        endY: mouseY,
        mode: data.current.mode,
        tool: tool,
      });
    }
    if (data.current.points.length) {
      data.current.undoStack.push(data.current.points);
    }
    data.current.points = [];
    data.current.redoStack = [];
  }, []);
  const handleMouseDown = useCallback((e: MouseEvent) => {
    if(e.button === 0) {
      data.current.mode = "set";
    } else if(e.button === 2) {
      data.current.mode = "clear";
    } else {
      data.current.mode = "none";
      return;
    }
    const trueRatio = canvasRef.current!.offsetWidth / canvasRef.current!.width;
    const mouseX = Math.floor(e.offsetX / trueRatio);
    const mouseY = Math.floor(e.offsetY / trueRatio);
    switch (tool) {
      case "fill":
        actionFill(mouseX, mouseY, data.current.mode);
        data.current.points.push({
          x: mouseX,
          y: mouseY,
          tool: tool,
          mode: data.current.mode,
        });
        break;
      case "line":
        data.current.lineX = mouseX;
        data.current.lineY = mouseY;
        break;
      default:
        actionDraw(mouseX, mouseY, data.current.mode);
        data.current.points.push({
          x: mouseX,
          y: mouseY,
          tool: tool,
          mode: data.current.mode,
        });
    }
  }, [ ]);
  const handleMouseOut = useCallback((e: MouseEvent) => {
    data.current.mode = "none";
    if (data.current.points.length) {
      data.current.undoStack.push(data.current.points);
    }
    data.current.points = [];
    data.current.redoStack = [];
    // TODO redraw viewcanvas
  }, []);
  const handleMouseMove = useCallback((e: MouseEvent) => {
    const trueRatio = canvasRef.current!.offsetWidth / canvasRef.current!.width;
    const mouseX = Math.floor(e.offsetX / trueRatio);
    const mouseY = Math.floor(e.offsetY / trueRatio);
    const ratio = canvasRef.current!.offsetWidth / canvasRef.current!.width;
    const onX = mouseX * ratio;
    const onY = mouseY * ratio;
    if (data.current.mode !== "none") {
      switch (tool) {
        case "fill":
          //do nothing
          break;
        case "line":
          //reset end point
          //draw line from origin point to current point onscreen
          //only draw when necessary
          if (onX !== data.current.lastOnX || onY !== data.current.lastOnY) {
            // TODO redraw viewcanvas
            //set offscreen endpoint
            data.current.lastOnX = mouseX;
            data.current.lastOnY = mouseY;
            actionLine(
              data.current.lineX,
              data.current.lineY,
              mouseX,
              mouseY,
              data.current.mode,
              ratio,
            );
            data.current.lastOnX = onX;
            data.current.lastOnY = onY;
          }
          break;
        default:
          actionDraw(mouseX, mouseY, data.current.mode);
          if (data.current.lastX !== mouseX || data.current.lastY !== mouseY) {
            data.current.points.push({
              x: mouseX,
              y: mouseY,
              mode: data.current.mode,
              tool: tool,
            });
          }
          data.current.lastX = mouseX;
          data.current.lastY = mouseY;
      }
    } else {
      //only draw when necessary
      if (onX !== data.current.lastOnX || onY !== data.current.lastOnY) {
        /*
        data.current.context!.clearRect(0, 0, data.current.originalWidth!, data.current.originalHeight!);
        data.current.context!.fillStyle = mode === "set" ? "black" : "white";
        data.current.context!.fillRect(onX, onY, ratio, ratio);
        data.current.context!.beginPath();
        data.current.context!.rect(onX, onY, ratio, ratio);
        data.current.context!.lineWidth = 0.5;
        data.current.context!.strokeStyle = "black";
        data.current.context!.stroke();
        data.current.context!.beginPath();
        data.current.context!.rect(onX + 0.5, onY + 0.5, ratio - 1, ratio - 1);
        data.current.context!.lineWidth = 0.5;
        data.current.context!.strokeStyle = "white";
        data.current.context!.stroke();
        data.current.lastOnX = onX;
        data.current.lastOnY = onY;*/
      }
    }
  }, []);
  useImperativeHandle(ref, () => ({
    load,
    save,
    undo,
    redo,
  }))
  return (
    <TransformWrapper minScale={0.1} panning={{
      allowLeftClickPan: false,
      allowRightClickPan: false,
      velocityDisabled: true,
    }} disablePadding={true}>
      {({ zoomIn, zoomOut, centerView, resetTransform, zoomToElement, ...rest }) => (
        <>
          <TransformComponent wrapperClass="!size-full max-w-full max-h-full rounded">
            <canvas ref={canvasRef} style={{
              imageRendering: "pixelated",
            }} onContextMenu={(e)=> e.preventDefault()}/>
          </TransformComponent>
          <div className="absolute left-2 top-2 flex flex-col items-center gap-2">
            <Button variant="outline" onClick={() => zoomIn()}>
              <PlusIcon />
            </Button>
            <Button variant="outline" onClick={() => zoomOut()}>
              <MinusIcon />
            </Button>
            <Button variant="outline" onClick={() => zoomToElement(canvasRef.current!)}>
              <EnterFullScreenIcon />
            </Button>
            <Button variant="outline" onClick={() => centerView()}>
              <ExitFullScreenIcon />
            </Button>
          </div>
        </>
    )}
    </TransformWrapper>
  );
});

PixelEditor.displayName = "PixelEditor";
export default PixelEditor;