import {
  createContext,
  useContext,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";

import { Box3, Group, Vector3 } from "three";
import { GroupProps, useThree } from "@react-three/fiber";

const DEFAULT_PADDING = 5;

const ShapeLayoutContext = createContext<{
  registerBox: (group: Group) => void,
  unregisterBox: (group: Group) => void,
  refreshPositions: () => void,
}>({
  registerBox: () => null,
  unregisterBox: () => null,
  refreshPositions: () => null,
});

export function useShapeLayoutContext() {
  return useContext(ShapeLayoutContext);
}

export function ShapeLayout({ children }: { children: JSX.Element[] }) {
  const boxesRef = useRef<Group[]>([]);
  const boundingBox = useMemo(() => new Box3(), []);
  const vec = useMemo(() => new Vector3(), []);
  const wrappingGroup = useRef<Group>(null);
  const { invalidate } = useThree();
  const registerBox = useCallback((group: Group) => {
    boxesRef.current.push(group);
  }, []);
  const unregisterBox = useCallback((group: Group) => {
    const i = boxesRef.current.findIndex((b) => b === group);
    if (i !== -1) {
      boxesRef.current.splice(i, 1);
    }
  }, []);
  const refreshPositions = useCallback(() => {
    let currentX = 0;
    boxesRef.current.forEach((group) => {
      boundingBox.setFromObject(group).getSize(vec);
      group.position.setX(group.position.x + currentX - boundingBox.min.x);
      currentX += DEFAULT_PADDING + vec.x;
    });
    boundingBox.setFromObject(wrappingGroup.current!);
    wrappingGroup.current!.position.setX(
      wrappingGroup.current!.position.x - (currentX - DEFAULT_PADDING) / 2
    );
    invalidate();
  }, []);
  const contextVals = useMemo(
    () => ({
      registerBox,
      unregisterBox,
      refreshPositions,
    }),
    [registerBox, unregisterBox, refreshPositions]
  );

  useLayoutEffect(() => {
    refreshPositions();
  }, [children, refreshPositions]);

  return (
    <group ref={wrappingGroup}>
      <ShapeLayoutContext.Provider value={contextVals}>
        {children}
      </ShapeLayoutContext.Provider>
    </group>
  );
}

export function Box({ ...props }: GroupProps) {
  const group = useRef<Group>(null);
  const { registerBox, unregisterBox } = useContext(ShapeLayoutContext);
  useLayoutEffect(() => {
    if (!group.current){
      return;
    }
    registerBox(group.current);
    return () => unregisterBox(group.current!);
  }, []);
  return <group ref={group} {...props}/>;
}
