import ConfigurationEditor from "../ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import {  modifyBlueprintConfiguration } from "@/state/model";

export function BlueprintConfigurationProperties({ query, blueprintId }: { query: string, blueprintId: number }) {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(s => s.model.blueprints[blueprintId].configuration);
  return (
    <ConfigurationEditor query={query} configuration={configuration} modifyConfiguration={c => dispatch(modifyBlueprintConfiguration({
      blueprint: blueprintId,
      configuration: c
    }))} />
  );
}