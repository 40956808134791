import NavigationMenu from "@/pages/navigation/NavigationMenu";
import { Outlet } from "react-router-dom";

export default function DesignerLayout() {
  return (
    <div className="relative h-screen w-[100%] overflow-hidden bg-slate-50">
      <div className="absolute inset-x-0 flex h-12 justify-center border-b bg-white">
        <div className="w-full px-2">
          <NavigationMenu showInsert={false} scope="designer"/>
        </div>
      </div>
      <main className="absolute inset-x-0 bottom-0 top-12 flex">
        <Outlet/>
      </main>
    </div>
  );
}