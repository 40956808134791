import { GearIcon, MixIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { select } from "@/state/model";

export default function InsertHeader() {
  const name = useAppSelector(s => s.model.insert.name);
  const version = useAppSelector(s => s.model.insert.version);
  const path = useAppSelector(s => s.model.insert.path);
  const selection = useAppSelector(s => s.model.selection);
  const dispatch = useAppDispatch();
  return (
    <div className="mt-1 flex flex-col gap-2 rounded-t-sm border-x border-t bg-white p-2">
      <div className="flex">
        <span className="mr-2 text-xl leading-none">
          {name}
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Badge variant="outline">v{version}</Badge>
          </TooltipTrigger>
          <TooltipContent>
            The current insert version.
          </TooltipContent>
        </Tooltip>
        <div className="flex-auto" />
        <Badge variant="secondary">{path}</Badge>
      </div>
      <div className="flex gap-1 rounded-lg bg-slate-100 p-1">
        <Button disabled={selection.aspect === "basics"} variant="outline" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "basics",
        }))} className="flex-auto bg-slate-50 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
          <MixIcon />
          Basics
        </Button>
        <Button disabled={selection.aspect === "configuration"} variant="outline" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "configuration",
        }))} className="flex-auto bg-slate-50 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
          <GearIcon />
          Configuration
        </Button>
        <Button disabled={selection.aspect === "globals"} variant="outline" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "globals",
        }))} className="flex-auto bg-slate-50 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
          <MixerHorizontalIcon />
          Globals
        </Button>
      </div>
    </div>
  );
}