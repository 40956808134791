import { Link } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { useListUpdatesQuery } from "@/state/api/updates";
import { useState } from "react";
import { ChatBubbleIcon, Cross1Icon } from "@radix-ui/react-icons";
import { ScrollArea } from "@ui/scroll-area";
import UpdatesPost from "./UpdatesPost";
import { Spinner } from "@ui/spinner";
import { Failure } from "@ui/failure";

export default function UpdatesIndex() {
  const session = useAppSelector(s => s.session);
  const [ page, setPage ] = useState(0);
  const { isFetching, isSuccess, data } = useListUpdatesQuery({
    page: page,
    count: 20,
  });
  return (
    <div className="flex flex-col border-l border-dashed">
      {session.user?.privileges.includes("post") && (
        <div className="flex justify-end gap-2">
          <Link to="/updates/publish?type=update">
            <Button variant="outline">
              New update
            </Button>
          </Link>
          <Link to="/updates/publish?type=documentation">
            <Button variant="outline">
              New documentation
            </Button>
          </Link>
        </div>
      )}
      {isFetching ? (
        <div className="flex gap-1">
          <Spinner/>
          Loading updates...
        </div>
      ) : isSuccess && data ? (
        <ScrollArea className="py-2" type="auto">
          <div className="flex flex-col gap-2">
            {data.updates.map(t => <UpdatesPost key={t.timestamp} update={t}/>)}
          </div>
        </ScrollArea>
      ) : (
        <Failure>
          Failed to load updates.
        </Failure>
      )}
    </div>
  );
}