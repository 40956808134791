import React from "react"
import { cn } from "./utils"

export interface SectionHeaderProps extends React.HTMLAttributes<HTMLParagraphElement> {
  space?: boolean
}

const SectionHeader = React.forwardRef<HTMLParagraphElement, SectionHeaderProps>(
  ({ children, space, className, ...props }, ref) => {
    return (
      <div className={cn("text-xl leading-none mt-2" + (space ? "" : " first:mt-0"), className)} {...props}>
        {children}
      </div>
    )
  }
)

SectionHeader.displayName = "SectionHeader";

export { SectionHeader }




