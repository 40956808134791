import { Outlet } from "react-router-dom";
import ConfigurationEditor from "./ConfigurationEditor";
import { useAppDispatch, useAppSelector } from "@/state/store";
import { updateSettings } from "@/state/settings";

export default function DesignerSettings() {
  const settings = useAppSelector(s => s.settings);
  const dispatch = useAppDispatch();
  return (
    <ConfigurationEditor configuration={settings} modifyConfiguration={c => dispatch(updateSettings(c))} query=""/>
  );
}