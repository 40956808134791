import { Text } from "@react-three/drei";
import CanvasPanel from "../canvas/CanvasPanel";
import { Vector } from "@/types";
import roboto from '../../../../public/fonts/RobotoMono-Regular.ttf?url'
import { printable } from "@/utils";

export default function InsertCanvasVerticalConnectorPanel({ position, distance, negative, flip, action } : {
  position: Vector,
  distance: number,
  negative: boolean,
  flip: boolean,
  action: (type: "fill" | "snap", axis: "X" | "Y" | "Z", distance: number, negative: boolean) => void,
 }) {
  const label = printable(distance);
  const width = 2 + Math.max(5, label.length) * 1.799;
  const sign = negative ? -1 : 1;
  return (
    <>
      <CanvasPanel args={[width, 4]} position={[position.x + (flip ? width / 2 : -width / 2), position.y + (distance > 4 + 2 ? 2 * sign : -2 * sign), position.z + 0.05]} color="#22c55e" hoverColor="#94a3b8" onPointerDown={e => {
        action(e.button === 2 ? "fill" : "snap", "Y", distance, negative);
      }}/>
      <Text position={[position.x + (flip ? 1 : -1), position.y + (distance > 4 + 2 ? 2 * sign : -2 * sign), position.z + 0.1]} color="black" anchorX={flip ? "left" : "right"} anchorY="middle" fontSize={3} font={roboto} renderOrder={2}>
        {label}
      </Text>
    </>
  );
}