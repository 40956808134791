import { User } from '@/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Session {
  user: User | undefined,
  type: SessionType,
  token: string | undefined,
}

export type SessionType = "cookie" | "login" | "new";

export const DefaultSession: Session = {
  user: undefined,
  type: "new",
  token: localStorage.getItem("sessionToken") ?? undefined,
}

export const sessionSlice = createSlice({
  name: "session",
  initialState: DefaultSession,
  reducers: {
    startSession: (state, action: PayloadAction<{
      user: User,
      type: SessionType,
      token: string,
    }>) => {
      state.user = action.payload.user;
      state.type = action.payload.type;
      state.token = action.payload.token;
      localStorage.setItem("sessionToken", action.payload.token);
    },
    endSession: (state) => {
      state.user = undefined;
      state.type = "new";
      state.token = undefined;
      localStorage.removeItem("sessionToken");
    },
  }
});

export const { startSession, endSession } = sessionSlice.actions;

export default sessionSlice.reducer; 