import React from "react";
import { cn } from "./utils";
import { Spinner } from "./spinner";

export interface BusyOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
}

const BusyOverlay = React.forwardRef<HTMLDivElement, BusyOverlayProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className="absolute inset-0 z-50 flex items-center justify-center bg-slate-50/90">
        <div className={cn("flex items-center gap-2 rounded border bg-white p-4 shadow-lg", className)}>
          <Spinner/>
          {children}
        </div>
      </div>
    )
  }
)

BusyOverlay.displayName = "BusyOverlay";

export { BusyOverlay }

