import { GroupProps } from "@react-three/fiber";
import { Suspense, useEffect } from "react";
import { Canvas as ThreeCanvas } from "@react-three/fiber";
import * as THREE from "three";

export default function Canvas({ children, ...props }: GroupProps) {
  useEffect(() => {
    // use z as up
    THREE.Object3D.DEFAULT_UP.set(0, 0, 1);

  }, [])
  const dpr = Math.min(window.devicePixelRatio, 2);
  return (
    <Suspense fallback={null}>
      <ThreeCanvas className="rounded-sm bg-white" dpr={dpr} frameloop="demand" camera={{ position: [0, -40, 80] }} onCreated={(state) => {
        state.gl.localClippingEnabled = true;
      }}>
        {children}
      </ThreeCanvas>
    </Suspense>
  );
}