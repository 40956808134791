import { ModelRecoryType } from "@/types";
import { GearIcon, MixIcon, MixerHorizontalIcon, MoveIcon, TokensIcon, TransformIcon } from "@radix-ui/react-icons";
import { IconProps } from "@radix-ui/react-icons/dist/types";

export default function InsertToolbarRecordIcon({type, ...props} : {type: ModelRecoryType} & IconProps) {
  switch(type) {
    case "basics": return <MixIcon {...props}/>
    case "configuration": return <GearIcon {...props}/>
    case "globals": return <MixerHorizontalIcon {...props}/>
    case "childs": return <TokensIcon {...props}/>
    case "layout": return <TransformIcon {...props}/>
    case "arrangement": return <MoveIcon {...props}/>
  }
}