import { GizmoHelper, GizmoViewcube, GizmoViewport, OrbitControls } from "@react-three/drei";
import React from "react";
import { useCanvasSettings } from "./CanvasContainer";

export const Controls = React.memo(
  function Controls({ panHorizontal }: { panHorizontal: boolean }) {
    const settings = useCanvasSettings();
    return (
      <>
        <OrbitControls makeDefault enableDamping screenSpacePanning={!panHorizontal} />
        {settings.showGizmo && (
          <GizmoHelper alignment="bottom-right" margin={[60, 60]} rotation={[-Math.PI / 2, 0, 0]} >
            <GizmoViewport axisColors={["#F87171", "#22c55e", "#60A5FA"]} axisHeadScale={1.2} labelColor="#202020" />
          </GizmoHelper>
        )}
      </>
    );
  }
);