import { InsertGlobals, Part, PocketElementBlueprint, TrayMeasure, GenerationOptions, TrayElement, ConfigurationConnectorType, ConnectorType, MeasurementOptions, Orientation, ConfigurationConnectorLength, ConfigurationConnectorMargin, ConfigurationConnectorWiggle } from "@/types";
import * as lib from "../library";
import * as rep from "replicad";

export const type = "connector";

export type Configuration = ConfigurationConnectorType & ConfigurationConnectorLength & ConfigurationConnectorMargin & ConfigurationConnectorWiggle;

export function getDefaultConfiguration(): Configuration {
  return {
    connectorType: ConnectorType.Dovetail,
    connectorLength: 6,
    connectorMargin: 10,
    connectorWiggle: 0.1,
  };
}

export function measureElement(c: Configuration, globals: InsertGlobals, options: MeasurementOptions) {
  const orientation = options.orientation ?? Orientation.Horizontal;
  const fullLength = c.connectorLength * 2 + globals.wall;
  const m: TrayMeasure = {
    xMin: orientation == Orientation.Horizontal ? fullLength : 0,
    yMin: orientation == Orientation.Horizontal ? 0 : fullLength,
    zMin: 0, // always use requested size
  };
  return m;
}

export function generatePart(blueprint: PocketElementBlueprint, element: TrayElement, globals: InsertGlobals, options: GenerationOptions): Part {
  const c = blueprint.configuration as Configuration;
  const perimeter = lib.drawRectangle(element.placement.xTotal ?? element.placement.x, element.placement.yTotal ?? element.placement.y);
  const orientation = options.orientation ?? Orientation.Horizontal;
  if(orientation === Orientation.Horizontal) {
    const depth = options.yNegExtent + options.yPosExtent + (element.placement.yTotal ?? element.placement.y);
    const height = options.zNegExtent + options.zPosExtent + (element.placement.zTotal ?? element.placement.z);
    const hookDepth = Math.max(depth - 2 * c.connectorMargin, 40);
    const hookSideDistance = (depth - hookDepth) / 2;
    const hookChamferLength = c.connectorLength / 100 * 60;
    // male
    const maleALow = rep.draw()
      .vLine(hookSideDistance)
      .line(c.connectorLength, hookChamferLength)
      .vLine(-hookSideDistance - hookChamferLength)
      .close()
      .sketchOnPlane("XY") as rep.Sketch;
    const deltaDepth = height / 10;
    const maleAHigh = rep.draw()
    .vLine(hookSideDistance - deltaDepth)
    .line(c.connectorLength, hookChamferLength)
    .vLine(deltaDepth -hookSideDistance - hookChamferLength)
    .close()
    .sketchOnPlane("XY", height) as rep.Sketch;
    const maleA = maleALow
      .loftWith(maleAHigh)
      .fillet(1, e => e.and([e => e.inPlane("YZ", c.connectorLength), e => e.containsPoint([c.connectorLength, hookSideDistance + hookChamferLength, 0]), e => e.containsPoint([c.connectorLength, hookSideDistance + hookChamferLength - deltaDepth, height])]));
    const maleB = maleA.clone().mirror("XZ", [0, depth / 2]);
    const male = maleA.fuse(maleB).translate(0, -options.yNegExtent, -options.zNegExtent);
    // female
    const femaleLow = rep.draw([0, hookSideDistance + c.connectorWiggle])
      .vLine(hookDepth - 2 * c.connectorWiggle)
      .line(c.connectorLength, -hookChamferLength)
      .vLine(-hookDepth + 2 * (c.connectorWiggle + hookChamferLength))
      .close()
      .sketchOnPlane("XY") as rep.Sketch;
    const femaleHigh = rep.draw([0, hookSideDistance + c.connectorWiggle - deltaDepth])
      .vLine(hookDepth - 2 * c.connectorWiggle + 2 * deltaDepth)
      .line(c.connectorLength, -hookChamferLength)
      .vLine(-hookDepth + 2 * (c.connectorWiggle + hookChamferLength - deltaDepth))
      .close()
    .sketchOnPlane("XY", height) as rep.Sketch;
    const female = femaleLow
      .loftWith(femaleHigh)
      .fillet(1, e => e.and([e => e.inPlane("YZ"), e => e.not(e => e.inDirection([0,1,0]))]))
      .translate(c.connectorLength + globals.wall, -options.yNegExtent, -options.zNegExtent);
    const cutAll = lib.drawRectangle(c.connectorLength * 2 + globals.wall, depth)
      /*.cut(lib.drawRectangle(20, depth, 3).translate(-20 + 3 + globals.wall, 0))
      .cut(lib.drawRectangle(20, depth, 3).translate(width - 3 - globals.wall, 0))*/;
    return {
      shape: male.fuse(female) as rep.Shape3D,
      cutout: cutAll.sketchOnPlane("XY").extrude(height).translate(0, -options.yNegExtent, -options.zNegExtent) as rep.Shape3D,
    };
  } else {
    return {};
  }
}