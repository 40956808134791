import * as React from "react"
import { cn } from "./utils"


export interface CardHederProps extends React.InputHTMLAttributes<HTMLDivElement> {
  index: number,
  color: "lead-green" | "green" | "red" | "gray",
}

const CardHeader = React.forwardRef<HTMLDivElement, CardHederProps>(
  ({ className, index, color, children, ...props }, ref) => {
    return (
      <div className={cn("flex h-10 items-center gap-2 overflow-clip rounded-t-sm border-x border-t p-2 " + (color === "green" ? "bg-green-100 border-primary" : "bg-slate-50"), className)}>
        <div className={"ml-[-48px] flex size-20 items-center justify-end rounded-full pe-4 " + (color === "green" || color === "lead-green" ? "bg-primary" : "bg-slate-500")}>
          <div className="text-2xl text-white">
            {index}
          </div>
        </div>
        {children}
      </div>
    )
  }
)
CardHeader.displayName = "CardHeader"

export { CardHeader }
