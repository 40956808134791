import { Link, Navigate, useParams } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { DbUpdateComment, useAddCommentMutation, useDeleteUpdateMutation, useGetUpdateQuery } from "@/state/api/updates";
import { getTimestampDescription, imageUrlFromId } from "@/utils";
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon, EyeOpenIcon, UpdateIcon } from "@radix-ui/react-icons";
import UpdatesMarkup from "./UpdatesMarkup";
import { Textarea, TextareaProps } from "@ui/textarea";
import { useRef, useState } from "react";
import { Spinner } from "@ui/spinner";
import { User } from "@/types";

function PostComment({user, updateId }: { user: User, updateId: string}) {
  const [comment, setComment] = useState("");
  const [addComment, status] = useAddCommentMutation();
  const textarea = useRef<HTMLTextAreaElement>(null);
  if(status.isLoading) {
    return (
      <div className="flex w-80 items-center gap-2">
        <Spinner/>
        Posting comment...
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-2">
      <Textarea placeholder={"Post as " + user.name} className="bg-white" ref={textarea} onChange={e => setComment(e.target.value)}/>
      {comment !== "" && (
        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={() => {
            textarea.current!.value = "";
            setComment("");
          }}>
            Cancel
          </Button>
          <Button variant="outline" onClick={async () => {
            const result = await addComment({
              comment: comment,
              updateId: updateId,
            });
            //@ts-expect-error error in type
            if(!result.error) {
              textarea.current!.value = "";
              setComment("");
            }
          }}>
            Comment
          </Button>
        </div>
      )}
    </div>
  );
}

function Comment({comment}: {comment: DbUpdateComment}) {
  return (
    <div className="flex flex-col rounded border bg-white p-2">
      <div className="flex gap-2 text-sm">
        <div className="font-semibold">
          {comment.ownerName}
        </div>
        <div className="text-secondary-foreground">
          {getTimestampDescription(comment.timestamp)}
        </div>
      </div>
      <div className="">
        {comment.comment}
      </div>
    </div>
  );
}

export default function UpdatesDetails() {
  const { id } = useParams();
  const session = useAppSelector(s => s.session);
  const { isFetching, isSuccess, data } = useGetUpdateQuery({
    id: id!,
  });
  const [ deleteUpdate, deleteUpdeteStatus] = useDeleteUpdateMutation();
  if(deleteUpdeteStatus.isSuccess) {
    return <Navigate to="/updates"/>;
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <Link to="/updates">
          <Button variant="outline" className="">
            <ChevronLeftIcon/>
            Back to Updates
          </Button>
        </Link>
        {session.user?.privileges.includes("post") && (
          <>
            <div className="flex-auto"></div>
            <div className="flex justify-end gap-2">
              <Link to={"/updates/edit/" + id}>
                <Button variant="outline">
                  Edit Update
                </Button>
              </Link>
              <Button variant="destructive" onClick={() => deleteUpdate({
                id: id!
              })}>
                Delete update
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="rounded border bg-white p-4">
        {isFetching ? (
          <div className="animate-spin"><UpdateIcon/></div>
        ) : isSuccess && data !== undefined ? (
          <div className="flex flex-col">
            <div className="flex items-center gap-1 text-sm text-secondary-foreground">
              <CalendarIcon/>
              {getTimestampDescription(data.timestamp)}
            </div>
            <div className="mb-2 text-2xl">
              {data.title}
            </div>
            <UpdatesMarkup markup={data.content} getImageSource={name => imageUrlFromId(id!, name)} className="w-full"/>
          </div>
        ) : (
          <div>
            Failed to load update.
          </div>
        )}
      </div>
      <div className="mt-2 flex flex-col gap-2">
        <div className="font-semibold">
          Comments
        </div>
        {session.user !== undefined && (
          <PostComment user={session.user} updateId={id!}/>
        )}
        {data !== undefined && data.comments.length > 0 ? (
          data?.comments.map(c => <Comment key={c.id} comment={c}/>)
        ) : (
          <div className="text-sm text-secondary-foreground">
            {session.user ? "No comments jet, be the first to comment!" : "No comments jet."}
          </div>
        )}
      </div>
    </div>
  );
}