import { ElementType } from "@/types";
import { AspectRatioIcon, GridIcon, LayoutIcon } from "@radix-ui/react-icons";
import { IconProps } from "@radix-ui/react-icons/dist/types";

export default function ContainerElementIcon({type, ...props} : {type: ElementType} & IconProps) {
  switch(type) {
    case "canvas": return <AspectRatioIcon {...props}/>
    case "grid": return <GridIcon {...props}/>
    default: return <LayoutIcon {...props}/>
  }
}