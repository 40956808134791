import { useAppSelector } from "@/state/store";
import { CrossCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";

export default function DesignerMessagesBar({ infoCount: info, warningCount: warning, errorCount: error }: { infoCount: number, warningCount: number, errorCount: number }) {
  if (info + warning + error == 0) {
    return <></>;
  }
  return (
    <div className="flex items-center gap-1">
      {info > 0 && <>
        <InfoCircledIcon/>
        <div className="mr-1">{info}</div>
      </>}
      {warning > 0 && <>
        <ExclamationTriangleIcon className="text-yellow-500" />
        <div className="mr-1">{warning}</div>
      </>}
      {error > 0 && <>
        <CrossCircledIcon className="text-red-500" />
        <div className="mr-1">{error}</div>
      </>}
    </div>
  );
}