import { GroupProps } from "@react-three/fiber";
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";

export interface CanvasSettings {
  centerCamera: boolean,
  showGizmo: boolean,
  showLines: boolean,
  wireframe: boolean,
  showPorts: boolean,
}

// @ts-expect-error must not be null, is set initially but null coalescance is avoided
const CanvasContext = createContext<CanvasSettings>(null);

// @ts-expect-error must not be null, is set initially but null coalescance is avoided
const CanvasSetContext = createContext<Dispatch<SetStateAction<CanvasSettings>>>(null);

export function useCanvasSettings() {
  return useContext(CanvasContext);
}

export function useSetCanvasSettings() {
  return useContext(CanvasSetContext);
}


export default function CanvasContainer({ children, ...props }: GroupProps) {
  const [settings, setSettings] = useState<CanvasSettings>({
    centerCamera: true,
    showGizmo: true,
    showLines: true,
    wireframe: false,
    showPorts: false,
  });
  return (
    <div className="relative size-full rounded-sm border bg-white shadow-sm">
      <CanvasContext.Provider value={settings}>
        <CanvasSetContext.Provider value={setSettings}>
          {children}
        </CanvasSetContext.Provider>
      </CanvasContext.Provider>
    </div>
  );
}