import React from "react";
import { Controls } from "./Controls";
import { GroupProps } from "@react-three/fiber";

export default React.memo(function Scene({ children, panHorizontal}: GroupProps & { panHorizontal: boolean}) {
  return (
    <>
      <Controls panHorizontal={panHorizontal} />
      {children}
    </>
  );
});