import { Modifier } from "@/types";
import * as rep from "replicad";

export function drawRectangle(x: number, y: number, rz?: number) {
  return rep.drawRoundedRectangle(x, y, rz)
    .translate(x / 2, y / 2);
}

/**
 * calculate fillet of equidistant edge moved towards center of the fillet by inset distance
 * @note primarily used to calculate wall fillets for opposite sides
 */
export function calcualteFillet(originalFillet: number, inset: number) {
  return Math.max(0, originalFillet * (1 - inset / originalFillet));
}

export function fuseAllShapes(shapes: rep.Shape3D[]) {
  let result = shapes[0];
  shapes.slice(1).forEach(shape => {
    result = result.fuse(shape);
  });
  return result;
}

export function fuseAllDrawings(drawings: rep.Drawing[]) {
  let result = drawings[0];
  drawings.slice(1).forEach(drawing => {
    result = result.fuse(drawing);
  });
  return result;
}

export function modifyDrawing(drawing: rep.Drawing, modifier: Modifier, length: number, cornerFinder?: (e: rep.CornerFinder) => rep.CornerFinder) {
  if(modifier === Modifier.Fillet) {
    return drawing.fillet(length, cornerFinder);
  } else {
    return drawing.chamfer(length, cornerFinder);
  }
}

export function modifyShape(shape: rep.Shape3D, modifier: Modifier, length: number, edgeFinder?: (e: rep.EdgeFinder) => rep.EdgeFinder) {
  if(modifier === Modifier.Fillet) {
    return shape.fillet(length, edgeFinder);
  } else {
    return shape.chamfer(length, edgeFinder);
  }
}

const drawingCache: { [key: string] : rep.Drawing } = {};

function hexGridKey(cx: number, cy: number, radius: number, wall: number, flat: boolean) {
  return `hg${flat? "f" : ""}:${radius};${wall};${cx}/${cy}`;
}

export function hexGrid(polygonRadius: number, polygonWall: number, width: number, depth: number, ox: number = 0, oy: number = 0, flat: boolean = true) {
  let grid = new rep.Drawing();
  let key: string;
  if (flat) {
    const dx = polygonRadius / 2 + Math.sin(Math.PI / 3) * polygonWall + polygonRadius;
    const dy = Math.sqrt(3) * polygonRadius + polygonWall;
    const cx = Math.ceil((width + Math.abs(ox) - polygonRadius) / dx / 2);
    const cy = Math.ceil(((depth + Math.abs(oy)) / dy - 1) / 2);
    // use cached drawing
    key = hexGridKey(cx, cy, polygonRadius, polygonWall, flat);
    const cachedDrawing = drawingCache[key];
    if(cachedDrawing !== undefined) {
      return cachedDrawing.clone().translate(ox / 2, oy / 2);
    }
    // draw new
    const poly = rep.drawPolysides(polygonRadius, 6).rotate(30);
    let shortColumn = new rep.Drawing();
    for (let y = -cy; y <= cy; y++) {
      shortColumn = shortColumn.fuse(poly
        .clone()
        .translate(0, dy * y)
      );
    }
    const longColumn = shortColumn.clone().fuse(poly
      .clone()
      .translate(0, dy * (cy + 1))
    );
    for (let x = -cx; x <= cx; x++) {
      grid = grid.fuse((Math.abs(x) % 2 ? longColumn : shortColumn)
        .clone()
        .translate(x * dx, (Math.abs(x) % 2) * -dy / 2)
      );
    }
  } else {
    const dx = Math.sqrt(3) * polygonRadius + polygonWall;
    const dy = Math.cos(Math.PI / 3) * polygonRadius + Math.cos(Math.PI / 6) * polygonWall + polygonRadius;
    const cx = Math.ceil((((width + Math.abs(ox)) / dx) - 1) / 2)
    const cy = Math.ceil((depth + Math.abs(oy) - polygonRadius) / dy / 2);
    const poly = rep.drawPolysides(polygonRadius, 6);
    // use cached drawing
    key = hexGridKey(cx, cy, polygonRadius, polygonWall, flat);
    const cachedDrawing = drawingCache[key];
    if(cachedDrawing !== undefined) {
      return cachedDrawing.clone().translate(ox / 2, oy / 2);
    }
    // draw new
    let shortRow = new rep.Drawing();
    for (let x = -cx; x <= cx; x++) {
      shortRow = shortRow.fuse(poly.clone()
        .translate(dx * x, 0));
    }
    const longRow = shortRow.clone().fuse(poly
      .clone()
      .translate(dx * (cx + 1), 0)
    );
    for (let y = -cy; y <= cy; y++) {
      grid = grid.fuse(((Math.abs(y) % 2) ? longRow : shortRow)
        .clone()
        .translate((Math.abs(y) % 2) * -dx / 2, dy * y)
      );
    }
  }
  // add to cache
  drawingCache[key] = grid.clone();
  return grid.translate(ox / 2, oy / 2);
}