import { Navigate } from "react-router-dom";
import { useGetInsertsQuery } from "@/state/api/inserts";
import { useAppSelector } from "@/state/store";
import WorkbenchInsert from "./WorkbenchInsert";

export default function WorkbenchAll() {
  const user = useAppSelector(s => s.session.user);
  if(user === undefined) {
    return <Navigate to="/?login=true"/>;
  }
  const { isFetching, data: inserts = [], error } = useGetInsertsQuery({
    ownerId: user.id,
  });
  if(isFetching) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="grid auto-cols-max grid-flow-col gap-2 p-2">
         {inserts.map(i => <WorkbenchInsert key={i.id} insert={i}/>)}
      </div>
    );
  }
}