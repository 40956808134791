import { Drawing, Shape3D, ShapeMesh } from 'replicad';

// list all pocket types here, search for NEW-POCKET when adding new pocket elements
import * as cardsFlat from "./designer/pockets/pocketCardsFlat";
import * as connector from "./designer/pockets/pocketConnector";
import * as spacer from "./designer/pockets/pocketSpacer";
import * as tokens from "./designer/pockets/pocketTokens";
import * as tokensShell from "./designer/pockets/pocketTokensShell";
import * as partVertical from "./designer/pockets/pocketPartVertical";

// list all pocket types here, search for NEW-POCKET when adding new pocket elements
export const pockets = {
  cardsFlat,
  connector,
  spacer,
  tokens,
  tokensShell,
  partVertical,
};

export const pocketTypes = Object.values(pockets).map(p => p.type);

export type PocketType = typeof pocketTypes[number];

// import all blueprint model generators here, search for NEW-TRAY when adding new blueprint
import * as layout from "./designer/trays/trayLayout";
import * as scripted from "./designer/trays/trayScripted";

// list all blueprint types here, search for NEW-BLUEPRINT when adding new blueprints
export const blueprints = {
  layout,
  scripted,
};

export const blueprintTypes = Object.values(blueprints).map(p => p.type);

export type BlueprintType = typeof blueprintTypes[number];

export interface FullSettings {
  settingShowTrayEditor: boolean,
  settingShowTrayHistory: boolean,
  settingShowTrayWorkers: boolean,
  settingCanvasSnapDistance: number,
}

export type Settings = FullSettings & {
  [name: string]: number | boolean | string,
};

export interface ConfigurationStackingWiggle { stackingWiggle: number }
export interface ConfigurationStackingBottom { stackingBottom: boolean }
export interface ConfigurationStackingHeight { stackingHeight: number }
export interface ConfigurationScript { script: string }

export interface ConfigurationX { x: number }
export interface ConfigurationY { y: number }
export interface ConfigurationZ { z: number }
export type ConfigurationXYZ = ConfigurationX & ConfigurationY & ConfigurationZ;

export interface ConfigurationTrayWiggle { trayWiggle: number }

export interface ConfigurationOrientation { orientation: Orientation }
export interface ConfigurationShell { shell: number }
export interface ConfigurationAnchor { anchor: Anchor }
export interface ConfigurationWall { wall: number }
export interface ConfigurationHorizontalWiggle { hWiggle: number }
export interface ConfigurationVerticalWiggle { vWiggle: number }

export interface ConfigurationHexCutEnable { hexCutEnable: boolean }
export interface ConfigurationHexCutTrimThreshold { hexCutTrimThreshold: number }
export interface ConfigurationHexCutWall { hexCutWall: number }
export interface ConfigurationHexCutRadius { hexCutRadius: number }
export interface ConfigurationHexCutOrientation { hexCutOrientation: Orientation }
export interface ConfigurationHexCutOffsetX { hexCutOffsetX: number }
export interface ConfigurationHexCutOffsetY { hexCutOffsetY: number }
export type ConfigurationHexCut = ConfigurationHexCutEnable & ConfigurationHexCutTrimThreshold & ConfigurationHexCutWall & ConfigurationHexCutRadius & ConfigurationHexCutOrientation & ConfigurationHexCutOffsetX & ConfigurationHexCutOffsetY;
export interface ConfigurationHexCutPrevent { hexCutPrevent: boolean }
export interface ConfigurationStackCount { stackCount: number }
export interface ConfigurationPart { part: ContouredPart }

export interface ConfigurationCornerModifierEnable { cornerModifierEnable: boolean }
export interface ConfigurationCornerModifierModifier { cornerModifier: Modifier }
export interface ConfigurationCornerModifierLength { cornerModifierLength: number }
export type ConfigurationCornerModifier = ConfigurationCornerModifierEnable & ConfigurationCornerModifierModifier & ConfigurationCornerModifierLength;

export interface ConfigurationTakeoutAssistEnableCornerModifier { takeoutAssistEnableCornerModifier: boolean }
export interface ConfigurationTakeoutAssistCornerModifier { takeoutAssistCornerModifier: Modifier }
export interface ConfigurationTakeoutAssistCornerLength { takeoutAssistCornerLength: number }
export interface ConfigurationTakeoutAssistCutoutModifier { takeoutAssistCutoutModifier: Modifier }
export interface ConfigurationTakeoutAssistCutoutLength { takeoutAssistCutoutLength: number }
export interface ConfigurationTakeoutAssistCutoutWidth { takeoutAssistCutoutWidth: number }
export interface ConfigurationTakeoutAssistCutoutWidthPercent { takeoutAssistCutoutWidthPercent: number }
export interface ConfigurationTakeoutAssistCutoutDepth { takeoutAssistCutoutDepth: number }
export interface ConfigurationTakeoutAssistCutoutDepthPercent { takeoutAssistCutoutDepthPercent: number }

export interface ConfigurationInsertPath { insertPath: string }
export interface ConfigurationName { name: string }
export interface ConfigurationPocketType { pocketType: PocketType }
export interface ConfigurationTopStacking { topStacking: TopStacking }

export interface ConfigurationConnectorType { connectorType: ConnectorType }
export interface ConfigurationConnectorLength { connectorLength: number }
export interface ConfigurationConnectorMargin { connectorMargin: number }
export interface ConfigurationConnectorWiggle { connectorWiggle: number }

type FullConfiguration = ConfigurationStackingBottom & ConfigurationStackingHeight & ConfigurationStackingWiggle &
                         ConfigurationScript &
                         ConfigurationX & ConfigurationY & ConfigurationZ &
                         ConfigurationOrientation &
                         ConfigurationShell &
                         ConfigurationAnchor &
                         ConfigurationWall & ConfigurationHorizontalWiggle & ConfigurationVerticalWiggle &
                         ConfigurationHexCutEnable & ConfigurationHexCutTrimThreshold & ConfigurationHexCutWall & ConfigurationHexCutRadius & ConfigurationHexCutOrientation & ConfigurationHexCutOffsetX & ConfigurationHexCutOffsetY & ConfigurationHexCutPrevent &
                         ConfigurationCornerModifierEnable & ConfigurationCornerModifierModifier & ConfigurationCornerModifierLength &
                         ConfigurationTakeoutAssistEnableCornerModifier & ConfigurationTakeoutAssistCornerModifier & ConfigurationTakeoutAssistCornerLength & ConfigurationTakeoutAssistCutoutModifier & ConfigurationTakeoutAssistCutoutLength & ConfigurationTakeoutAssistCutoutWidth & ConfigurationTakeoutAssistCutoutWidthPercent & ConfigurationTakeoutAssistCutoutDepth & ConfigurationTakeoutAssistCutoutDepthPercent &
                         ConfigurationInsertPath &
                         ConfigurationName &
                         ConfigurationPocketType &
                         ConfigurationTopStacking &
                         ConfigurationTrayWiggle & 
                         ConfigurationPart & 
                         ConfigurationStackCount &
                         ConfigurationConnectorType & ConfigurationConnectorLength & ConfigurationConnectorMargin & ConfigurationConnectorWiggle;

export interface ConfigurationIndexer {
  [key: string]: boolean | number | string | undefined;
}

export type Configuration = Partial<FullConfiguration> & ConfigurationIndexer;

export type DrawerKeys = "hexCutAlign" | "placement" | "blueprintName" | "trayType" | "trayColor" | "elementName" | "stack" | "grid" | "canvas" |
                         "insertName" | 
                         "insertGame" | 
                         "trayName" | "traySizeX" | "traySizeY" | "traySizeZ" | "trayOffsetX" | "trayOffsetY" | "trayOffsetZ" | "trayLayout";

export type LayoutKeys = "stack" | "grid" | "canvas";

export type SettingsKeys = keyof FullSettings;

export type Key = keyof FullConfiguration | DrawerKeys | LayoutKeys | SettingsKeys;


export interface Drawer {
  keys: string[],
  control: ((configuration: Configuration, modifyConfiguration: (configuration: Configuration) => void) => JSX.Element) | JSX.Element,
  useLabel: boolean,
}

export interface Vector {
  x: number,
  y: number,
  z: number,
}

export interface TrayLayoutOffsets {
  start: Vector,
  extent: Vector,
}

export interface ElementMeasure {
  xMin: number,
  yMin: number,
  zMin: number,
  xLimit?: number,
  yLimit?: number,
  zLimit?: number,
}

export type TrayMeasure = ElementMeasure;

export interface Bounds {
  /** width of element without padding */
  x: number,
  /** depth of element without padding */
  y: number,
  /** height of element without padding */
  z: number,
  /** offset along x axis */
  xOffset: number,
  /** offset along y axis */
  yOffset: number,
  /** offset along z axis */
  zOffset: number,
}

export type TrayPlacement = Bounds;

export interface ElementPlacement extends Bounds {
  /** total width of element including padding, only available when aligned */
  xTotal?: number,
  /** total depth of element including padding, only available when aligned */
  yTotal?: number,
  /** total height of element including padding, only available when aligned */
  zTotal?: number,
  /** used to contribute filler shapes to the tray shape, needed when padding is required between pockets */
  fillers?: Omit<ElementPlacement, "fillers">[],
}

export interface InsertGlobals {
  hWiggle: number,
  vWiggle: number,
  stackingHeight: number,
  stackingWiggle: number,
  wall: number,
  [name: string]: number | string | boolean,
}

export interface InsertChanges {
  name?: string,
  path?: string,
  globals: Partial<InsertGlobals>,
  trays?: number[],
  configuration: Partial<InsertConfiguration>,
}

export interface InsertHistoryItem {
  insert: number,
  version: number,
  timestamp: number,
  description?: string,
}

export type InsertConfiguration = ConfigurationXYZ & ConfigurationTrayWiggle & ConfigurationIndexer;

export enum ExpansionSupport {
  None = 0,
  Optional = 1,
  Required = 2,
}

export type GameExpansions = {
  expansionId: number,
  support: ExpansionSupport,
}[];

export interface MinifiedInsert {
  id: number,
  version: number,
  name: string,
  path: string,
  gameId: number,
  expansions: GameExpansions,
  globals: InsertGlobals,
  configuration: InsertConfiguration,
  trays: number[],
}


export interface Insert extends MinifiedInsert {
  history: InsertHistoryItem[],
  messages: Message[],
}

export interface GeometryEdge {
  start: Vector,
  end: Vector,
  length: number,
}

export interface GeometryPlaneFace {
  center: Vector,
  size: Vector,
}

export type GeometryFace = GeometryPlaneFace;

export interface TrayGeometry {
  name: string,
  edges: GeometryEdge[],
  faces: GeometryFace[],
  faceGeometry: ShapeMesh,
  edgeGeometry: {
    lines: number[];
    edgeGroups: {
      start: number;
      count: number;
      edgeId: number;
    }[];
  },
  volume: number,
}


export type TrayModel = TrayGeometry & {
  trayId: number,
  color: string,
};

export interface TrayShape {
  name: string,
  shape: Shape3D,
}

export type ModelRecoryType = "basics" | "configuration" | "globals" | "childs" | "layout" | "arrangement";

export type ModelRecord = Omit<Model, "history" | "selection"> & {
  change: string,
  type: ModelRecoryType,
}

export interface Model {
  insert: Insert,
  blueprints: { [id: number]: Blueprint },
  trays: { [id: number]: Tray },
  selection: ModelSelection,
  history: {
    index: number,
    records: ModelRecord[],
  }
}

export interface MinifiedModel {
  insert: MinifiedInsert,
  blueprints: { [id: number]: MinifiedBlueprint },
  trays: { [id: number]: MinifiedTray },
}

export type ElementType = ElementBlueprint["type"];

export interface TrayElement {
  measure: ElementMeasure,
  placement: ElementPlacement,
}

interface ElementBlueprintBase<T extends Configuration> {
  id: number,
  configuration: T,
  layout: ElementLayout,
}

export interface ContainerElementBlueprintBase<T extends Configuration> extends ElementBlueprintBase<T> {
  childs: number[],
}

export enum HorizontalAlignment {
  Left = 0,
  Center = 0.5,
  Right = 1,
}

export enum VerticalAlignment {
  Bottom = 0,
  Center = 0.5,
  Top = 1,
}

export enum Orientation {
  Horizontal = 0,
  Vertical = 1,
}

export enum Modifier {
  Chamfer = 0,
  Fillet = 1,
}

export interface ElementLayoutBase {
  xGrow: number,
  yGrow: number,
  zGrow: number,
  xAlign: HorizontalAlignment,
  yAlign: VerticalAlignment,
  [key: string]: number | string | boolean,
}

export enum TopStacking {
  Disabled = 0,
  Enabled = 1,
  EnabledIndividual = 2,
}
export enum ConnectorType {
  Dovetail = 0,
}

export type ContainerConfiguration = ConfigurationIndexer & ConfigurationTopStacking;

export interface GridElementLayout extends ElementLayoutBase {
  type: "grid",
  /** the grid row */
  row: number,
  /** the grid column */
  column: number,
}

export interface GridElementConfiguration extends ContainerConfiguration {
  rows: number,
  cols: number,
}

export interface GridElementBlueprint extends ContainerElementBlueprintBase<GridElementConfiguration> {
  type: "grid",
}

export interface StackElementLayout extends ElementLayoutBase {
  type: "stack",
}

export interface StackElementConfiguration extends ContainerConfiguration {
  orientation: Orientation,
}

export interface StackElementBlueprint extends ContainerElementBlueprintBase<StackElementConfiguration> {
  type: "stack",
}

export enum Anchor {
  Center = 0,
  XMin = 1,
  XMax = 2,
  YMin = 3,
  YMax = 4,
}

/** gx and gy must be 0 for canvas childs */
export interface CanvasElementLayout extends ElementLayoutBase {
  type: "canvas",
  /** anchor for offset, ax is used to align on x axis when xmin or ymin is used as anchor, ay is used when ymin or ymax is used as anchor */
  anchor: Anchor,
}

export interface CanvasElementConfiguration extends ContainerConfiguration {
  /** the minmal width */
  xMin: number,
  /** the minimal depth */
  yMin: number,
  /** the width limit */
  xLimit?: number,
  /** the depth limit */
  yLimit?: number,
}

export interface CanvasElementBlueprint extends ContainerElementBlueprintBase<CanvasElementConfiguration> {
  type: "canvas",
}

export interface PocketElementBlueprint extends ElementBlueprintBase<Configuration> {
  type: "pocket",
  name: string,
  pocketType: PocketType,
}

export interface Part {
  shape?: Shape3D,
  cutout?: Shape3D,
  preserveAll?: Drawing,
  cutAll?: Drawing,
  post?: (shape: Shape3D) => Shape3D,
}

export interface TrayPortSegment {
  xOffset: number,
  yOffset: number,
  zOffset?: number,
  x: number,
  y: number,
}

export interface TrayPort {
  id: number,
  type: string,
  xOffset: number,
  yOffset: number,
  zOffset: number,
  segments: Omit<TrayPortSegment, "zOffset">[],
}

export type DebugHandler = (name: string, value: Shape3D | Drawing | (() => Shape3D | Drawing)) => void;

export interface BlueprintLibraryEntry {
  libraryId: string,
  version: number,
  timestamp: string,
  isIsolated: boolean,
}

export interface MinifiedBlueprint {
  id: number,
  name: string,
  type: BlueprintType,
  version: number,
  color: string,
  globals: InsertGlobals,
  configuration: Configuration,
  elements: { [id: number]: MinifiedElementBlueprint },
  root: number,
}

export interface Blueprint extends MinifiedBlueprint {
  elements: { [id: number]: ElementBlueprint },
  library?: BlueprintLibraryEntry,
  modelHash: string,
}

export enum TrayType {
  Preview,
  Primary,
  Secondary,
}

export interface MinifiedTray {
  id: number,
  name?: string,
  blueprintId: number,
  size: Vector,
  offset: Vector,
  type: TrayType,
}

export interface Message {
  type: "error" | "warning" | "info",
  key: string,
  content: string,
}

export interface Tray extends MinifiedTray {
  elements: { [id: number]: TrayElement },
  measure: TrayMeasure,
  ports: TrayPort[],
  bounds: Bounds[],
  modelHash: string,
  messages: Message[],
}

export type ElementBlueprint = PocketElementBlueprint | GridElementBlueprint | StackElementBlueprint | CanvasElementBlueprint;

export type ContainerElementBlueprint = GridElementBlueprint | StackElementBlueprint | CanvasElementBlueprint;

export type ElementLayout = GridElementLayout | StackElementLayout | CanvasElementLayout;

export type MinifiedPocketElementBlueprint = PocketElementBlueprint;

export type MinifiedContainerElementBlueprint = ContainerElementBlueprint;

export type MinifiedElementBlueprint = MinifiedPocketElementBlueprint | MinifiedContainerElementBlueprint;


export interface ModelInsertSelection {
  scope: "insert",
  aspect: "basics" | "configuration" | "globals",
}

export interface ModelInsertTraySelection {
  scope: "insert",
  aspect: "tray",
  trayId: number,
}

export interface ModelBlueprintSelection {
  scope: "blueprint",
  aspect: "basics" | "configuration" | "globals",
  blueprintId: number,
}

export interface ModelBlueprintElementSelection {
  scope: "blueprint",
  aspect: "element",
  blueprintId: number,
  elementId: number,
}

export type ModelSelection = ModelInsertSelection | ModelInsertTraySelection | ModelBlueprintSelection | ModelBlueprintElementSelection;

export interface MagicAction {
  tooltip: string,
  icon?: "wand" | "reset",
  handler: () => number | null,
}

export interface MagicActions {
  [key: string]: MagicAction,
}

export interface TrayConnector {
  z: number,
  startOrthogonalMin: number,
  startOrthogonalMax: number,
  offset: number,
  localOffset: number,
  distance: number,
  endOrthogonalMin?: number,
  endOrthogonalMax?: number,
}

export interface MeasurementOptions {
  orientation?: Orientation,
}

export interface GenerationOptions extends MeasurementOptions {
  xNegExtent: number,
  xPosExtent: number,
  yNegExtent: number,
  yPosExtent: number,
  zNegExtent: number,
  zPosExtent: number,
}

export enum SpecificUpdateVote {
  Yes = 0x60,
  No = 0x61,
}

export interface Contour {
  points: number[],
  width: number,
  depth: number,
}

export interface ContouredPart extends Contour {
  height: number,
}

export type UpdateVote = SpecificUpdateVote | number;

export type SiteScope = "explore" | "workbench" | "updates" | "designer" | "games" | "cameras" | "boxes";

export interface User {
  id: number,
  name: string,
  email: string,
  privileges: string[],
}