import { useAppSelector } from "@/state/store";
import { PocketConfigurationProperties } from "./PocketConfigurationProperties";
import { ContainerConfigurationProperties } from "./ContainerConfigurationProperties";

export function ElementConfiguration({ query, trayId, blueprintId, elementId }: { query: string, trayId: number, blueprintId: number, elementId: number }) {
  const elementBlueprint = useAppSelector(s => s.model.blueprints[blueprintId].elements[elementId]);
  if (elementBlueprint.type === "pocket") {
    return <PocketConfigurationProperties query={query} trayId={trayId!} blueprintId={blueprintId} elementBlueprint={elementBlueprint}/>;
  } else {
    return <ContainerConfigurationProperties query={query} trayId={trayId!} blueprintId={blueprintId} elementBlueprint={elementBlueprint}/>;
  }
}