import { api } from '../store'

export interface DbInsert {
  id: number,
  ownerId: number,
  name: string,
  description: string,
  gameName: string,
  bggId: number,
  image: string,
}

const insertsApi = api.injectEndpoints({
  endpoints: builder => ({
    getInserts: builder.query<DbInsert[], {
      ownerId?: number,
      search?: string,
    }>({
      query: (arg) => ({
        url: "/inserts",
        params: arg,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useGetInsertsQuery } = insertsApi;