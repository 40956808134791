import { Vector } from "@/types";
import { Line, Sphere } from "@react-three/drei";
import VertexInfo from "./VertexInfo";

export default function BoxInfo({ position, size, showCoordinates, color }: { position: Vector, size: Vector, showCoordinates: boolean, color: string }) {
  return (
    <>
      {showCoordinates && (
        <>
          <VertexInfo position={{x: position.x + 0, y: position.y + 0, z: position.z + 0}} parentSize={size}/>
          <VertexInfo position={{x: position.x + size.x, y: position.y + 0, z: position.z + 0}} parentSize={size}/>
          <VertexInfo position={{x: position.x + 0, y: position.y + size.y, z: position.z + 0}} parentSize={size}/>
          <VertexInfo position={{x: position.x + size.x, y: position.y + size.y, z: position.z + 0}} parentSize={size}/>
          <VertexInfo position={{x: position.x + 0, y: position.y + 0, z: position.z + size.z}} parentSize={size}/>
          <VertexInfo position={{x: position.x + size.x, y: position.y + 0, z: position.z + size.z}} parentSize={size}/>
          <VertexInfo position={{x: position.x + 0, y: position.y + size.y, z: position.z + size.z}} parentSize={size}/>
          <VertexInfo position={{x: position.x + size.x, y: position.y + size.y, z: position.z + size.z}} parentSize={size}/>
        </>
      )}
      <Line linewidth={1.5} points={[[position.x, position.y, position.z], [position.x + size.x, position.y, position.z], [position.x + size.x, position.y + size.y, position.z], [position.x, position.y + size.y, position.z], [position.x, position.y, position.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
      <Line linewidth={1.5} points={[[position.x, position.y, position.z + size.z], [position.x + size.x, position.y, position.z + size.z], [position.x + size.x, position.y + size.y, position.z + size.z], [position.x, position.y + size.y, position.z + size.z], [position.x, position.y, position.z + size.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
      <Line linewidth={1.5} points={[[position.x, position.y, position.z], [position.x, position.y, position.z + size.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
      <Line linewidth={1.5} points={[[position.x + size.x, position.y, position.z], [position.x + size.x, position.y, position.z + size.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
      <Line linewidth={1.5} points={[[position.x, position.y + size.y, position.z], [position.x, position.y + size.y, position.z + size.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
      <Line linewidth={1.5} points={[[position.x + size.x, position.y + size.y, position.z], [position.x + size.x, position.y + size.y, position.z + size.z]]} depthTest={false} renderOrder={10} color={color} lineWidth={1}/>
    </>
  );
  
}