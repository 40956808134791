import React, { useRef, useState } from "react";
import { Label } from "@ui/label";
import { Dialog, DialogContent, DialogTrigger } from "@ui/dialog";
import { Button } from "@ui/button";
import { EnterFullScreenIcon, TrackPreviousIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { ScrollArea } from "@ui/scroll-area";

/*
intentially close to markup for potential migration later on

Inlines:
`code`
**bold**
![subtitle](relative image url)
[tooltip](external url)
Lines:
# chapter
## subchapter
 */

function MarkupImage({ source, subtitle }: { source: string, subtitle: string }) {
  const [isResized, setIsResized] = useState(true);
  const imgRef = useRef<HTMLImageElement>(null);
  const showReload = source.startsWith("data:image/gif") || source.endsWith(".gif");
  return <div className="relative my-2 flex flex-col items-center gap-1">
    <img id="image" src={source} className="max-h-[800px] rounded-lg shadow" onLoad={e => {
      const image = e.target as HTMLImageElement;
      setIsResized(image.naturalHeight > image.height || image.naturalWidth > image.width);
    }} ref={imgRef}/>
    {subtitle && (
      <Label htmlFor="image" className="p-1 text-center">{subtitle}</Label>
    )}
    <div className="absolute right-2 top-2 flex gap-1">
      {showReload && (
        <Tooltip>
          <TooltipTrigger>
            <Button variant="outline" className="border-none bg-white/0 px-2 py-1 backdrop-contrast-50 hover:bg-white/0" onClick={() => {
              if (imgRef.current != null) {
                imgRef.current.src = source;
              }
            }}>
              <TrackPreviousIcon className="size-6 text-white" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Restart the video
          </TooltipContent>
        </Tooltip>
      )}
      {isResized && (
        <Dialog>
          <DialogTrigger>
            <Tooltip>
              <TooltipTrigger>
                <Button variant="outline" className="border-none bg-white/0 px-2 py-1 backdrop-contrast-50 hover:bg-white/0">
                  <EnterFullScreenIcon className="size-6 text-white" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Open in full screen
              </TooltipContent>
            </Tooltip>
          </DialogTrigger>
          <DialogContent className="gap-1 pt-2">
            <ScrollArea>
              <div className="text-lg font-semibold">
                {subtitle}
              </div>
              <img src={source} />
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}
    </div>
  </div>;
}

export default function UpdatesMarkup({ markup, className, getImageSource }: { markup: string, className?: string, getImageSource: (name: string) => string }) {
  const formattedInlines: {
    index: number,
    length: number,
    content: React.ReactNode
  }[] = [];
  const notHandled = (index: number) => {
    return !formattedInlines.some(i => index >= i.index && index < i.index + i.length);
  }
  const multilineSpan = (className: string, content: string) => {
    const segments = content.split("\n");
    const inlines: React.ReactNode[] = [];
    for (let index = 0; index < segments.length; index++) {
      inlines.push(<span key={index} className={className}>{segments[index]}</span>);
      if (index < segments.length - 1) {
        inlines.push(<br />);
      }
    }
    return inlines;
  }
  for (const match of markup.matchAll(/`([^`]+)`/g)) {
    formattedInlines.push({
      index: match.index!,
      length: match[0].length,
      content: multilineSpan("font-mono border px-1 rounded-sm", match[1]),
    });
  }
  for (const match of markup.matchAll(/\*\*([^*]+)\*\*/g)) {
    if (notHandled(match.index!)) {
      formattedInlines.push({
        index: match.index!,
        length: match[0].length,
        content: multilineSpan("font-semibold text-wrap", match[1]),
      });
    }
  }
  for (const match of markup.matchAll(/\*([^*]+)\*/g)) {
    if (notHandled(match.index!)) {
      formattedInlines.push({
        index: match.index!,
        length: match[0].length,
        content: multilineSpan("italic", match[1]),
      });
    }
  }
  for (const match of markup.matchAll(/!\[([^\]]*)\]\(([^)]+)\)/g)) {
    if (notHandled(match.index!)) {
      let length = match[0].length;
      if (markup[match.index! + length] == "\n") {
        // skip tailing new lines
        length++;
      }
      formattedInlines.push({
        index: match.index!,
        length: length,
        content: <MarkupImage source={getImageSource(match[2])} subtitle={match[1]} />
      });
    }
  }
  for (const match of markup.matchAll(/\[([^\]]+)\]\(([^)]+)\)/g)) {
    if (notHandled(match.index!)) {
      formattedInlines.push({
        index: match.index!,
        length: match[0].length,
        content: <a href={match[2]}>{match[1]}</a>,
      });
    }
  }
  for (const match of markup.matchAll(/^# (.+)/gm)) {
    if (notHandled(match.index!)) {
      formattedInlines.push({
        index: match.index!,
        length: match[0].length,
        content: <span className="mt-1 text-lg/loose font-semibold underline decoration-primary underline-offset-2">{match[1]}</span>,
      });
    }
  }
  for (const match of markup.matchAll(/^## (.+)/gm)) {
    if (notHandled(match.index!)) {
      formattedInlines.push({
        index: match.index!,
        length: match[0].length,
        content: <h2>{match[1]}</h2>,
      });
    }
  }
  let index = 0;
  const inlines: React.ReactNode[] = [];
  for (const inline of formattedInlines.sort((a, b) => a.index - b.index)) {
    if (index < inline.index) {
      // prepend text
      inlines.push(<span className="whitespace-pre-wrap">{markup.substring(index, inline.index)}</span>);
    }
    inlines.push(inline.content);
    index = inline.index + inline.length;
  }
  if (index < markup.length) {
    // append text
    inlines.push(<span className="whitespace-pre-wrap">{markup.substring(index)}</span>);
  }
  return (
    <div className={className}>
      {...inlines}
    </div>
  );
}