import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/store";
import { Button } from "@ui/button";
import { DbUpdate } from "@/state/api/updates";
import { getTimestampDescription } from "@/utils";
import { CalendarIcon, ChevronRightIcon } from "@radix-ui/react-icons";

export default function UpdatesPost({ update }: {update: DbUpdate}) {
  const navigate = useNavigate();
  return (
    <div className="flex">
      <div className="flex-none w-64 text-slate-500 py-2">
        <div className="px-2">
          {getTimestampDescription(update.timestamp)}
        </div>
        <div className="relative h-[1px] w-full border-t border-dashed">
          <div className="absolute left-[-16px]  overflow-visible">
            <CalendarIcon/>
          </div>
        </div>
        <div className="px-2">
          {update.views} views
        </div>
      </div>
      <div className="flex flex-col flex-auto rounded border bg-white p-2 cursor-pointer gap-2 hover:border-primary" onClick={() => navigate("/updates/" + update.id) }>
        <div className="font-semibold">
          {update.title}
        </div>
        <div className="text-slate-500">
          {update.description}
        </div>
        <div className="flex items-center text-primary gap-2">
          Read article <ChevronRightIcon/>
        </div>
      </div>
    </div>
  );
}