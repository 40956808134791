import { Outlet } from "react-router";
import NavigationMenu from "@/pages/navigation/NavigationMenu";
import { useAppSelector } from "@/state/store";
import { ScrollArea } from "@ui/scroll-area";
import { SiteScope } from "@/types";
import { Separator } from "@ui/separator";
import { Toaster } from "@ui/sonner";

export default function CenteredLayout({ maxWidth, scope } : { maxWidth: number, scope: SiteScope }) {
  const session = useAppSelector(s => s.session);
  return (
    <div className="flex h-screen flex-col bg-slate-50">
      <div className="flex flex-none justify-center border-b bg-white shadow-md">
        <div className="h-12 w-full px-2" style={{
          maxWidth: maxWidth,
        }}>
          <NavigationMenu showInsert={true} scope={scope}/>
        </div>
      </div>
      <main className="flex flex-auto overflow-hidden">
        <Toaster position="bottom-left" theme="light"/>
        <ScrollArea className="size-full">
          <div className="mx-auto p-2" style={{
          maxWidth: maxWidth,
        }}>
            <Outlet/>
          </div>
        </ScrollArea>
      </main>
    </div>
  );
}