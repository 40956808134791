import { useAppSelector } from "@/state/store";
import { ArrowRightIcon, ChevronLeftIcon, DownloadIcon, EnterFullScreenIcon, LightningBoltIcon, MagicWandIcon, MagnifyingGlassIcon, MinusIcon, MixerHorizontalIcon, OpenInNewWindowIcon, Pencil2Icon, PlusIcon, TargetIcon, TrashIcon, UploadIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { TransformWrapper, TransformComponent, useControls, MiniMap } from "react-zoom-pan-pinch";
import { useCallback, useEffect, useRef, useState } from "react";
import { CV, Mat } from "mirada"
import { Label } from "@ui/label";
import { Input } from "@ui/input";
import * as exifr from "exifr";
import { Navigate, useNavigate } from "react-router-dom";
import { SaveIcon } from "react-line-awesome";
import { bytesToBase64, displayImage, getImageData } from "@/utils";
import sheetPreview from "./camera-calibration.jpg"
import sheetPdf from "./camera-calibration.pdf"
import { useAddCameraMutation } from "@/state/api/cameras";
import { PageHeader } from "@ui/pageHeader";
import { SectionHeader } from "@ui/sectionHeader";
import { SectionDescription } from "@ui/sectionDescription";
import { Section } from "@ui/section";
import { SectionLabel } from "@ui/sectionLabel";
import { HintButton } from "@ui/hintButton";
import { BulletPoint } from "@ui/bulletPoint";
import { Placeholder } from "@ui/placeholder";
import { opencvRequest } from "@/opencv";
import { useSelector } from "react-redux";

interface CameraImage {
  data: ImageData,
  error?: string,
}

function ImageThumbnail({ image, busy, deleteImage, setPreview, calibration }: { image: CameraImage, busy: boolean, deleteImage: () => void, setPreview: (image: ImageData) => void, calibration: string | undefined }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [selfBusy, setSelfBusy] = useState(false);
  useEffect(() => {
    displayImage(canvasRef.current!, image.data);
  }, [image]);
  return (
    <div className={"flex flex-col rounded border bg-white" + (image.error !== undefined ? " border-destructive" : "")}>
      <canvas ref={canvasRef} className="rounded-t border-b bg-gray-100 object-contain p-2" />
      <div className="flex flex-col gap-1 p-1">
        <div className="text-center font-mono text-sm">
          {image.data.width}x{image.data.height}px
        </div>
        {image.error !== undefined && (
          <div className="text-center font-mono text-sm text-destructive">
            {image.error}
          </div>
        )}
        <Button disabled={busy} variant="destructive" onClick={() => deleteImage()}>
          <TrashIcon />
          Delete
        </Button>
        {calibration !== undefined && (
          <>
          <Button disabled={busy || selfBusy} variant="outline" onClick={() => {
            setSelfBusy(true);
            opencvRequest({
              type: "undistort",
              image: image.data,
              calibration: calibration,
            }).then(result => {
              if (result instanceof Error) {
                //setError(result.message);
              } else {
                setPreview(result.image);
              }
              setSelfBusy(false);
            });
          }} className="flex-1">
            <OpenInNewWindowIcon />
            View results
          </Button>
          <Button disabled={busy || selfBusy} variant="outline" onClick={() => {
            setSelfBusy(true);
            opencvRequest({
              type: "detectCalibrationPattern",
              image: image.data,
            }).then(result => {
              if (result instanceof Error) {
                //setError(result.message);
              } else {
                setPreview(result.image);
              }
              setSelfBusy(false);
            });
          }} className="flex-1">
            <OpenInNewWindowIcon />
            View results
          </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default function CameraNew() {
  const session = useAppSelector(s => s.session);
  const navigate = useNavigate();
  const [images, setImages] = useState<CameraImage[]>([]);
  const [busy, setBusy] = useState(false);
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [calibration, setCalibration] = useState<string | undefined>(undefined);
  const [model, setModel] = useState<string | undefined>(undefined);
  const [resolution, setResolution] = useState<number | undefined>(undefined);
  const [lastQuality, setLastQuality] = useState<number | undefined>(undefined);
  const [currentQuality, setCurrentQuality] = useState<number | undefined>(undefined);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRefMinimap = useRef<HTMLCanvasElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const [validationImage, setValidationImage] = useState<ImageData | undefined>(undefined);
  const [addCamera, addCameraState] = useAddCameraMutation();
  /*
  if (session.user === undefined) {
    return <Navigate to="/?login=true&redirect=workbench" />;
  }*/
  useEffect(() => {
    if (validationImage !== undefined) {
      displayImage(canvasRef.current!, validationImage);
      displayImage(canvasRefMinimap.current!, validationImage);
    }
  }, [validationImage]);
  useEffect(() => {
    // preload opencv
    opencvRequest({
      type: "load",
    });
  }, []);
  if (addCameraState.data !== undefined && addCameraState.isSuccess) {
    return <Navigate to={`/cameras/${addCameraState.data.id}`} />;
  }
  const addImages = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImages: CameraImage[] = [];
    let requiredModel = model;
    let requiredResolution = resolution;
    for (let index = 0; index < e.target.files!.length; index++) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files![index]);
      reader.onloadend = async () => {
        try {
          const data = reader.result as string;
          const metadata = await exifr.parse(data);
          const currentModel = `${metadata.Make} ${metadata.Model} (${metadata.LensModel})`;
          const currentResolution = metadata.ExifImageWidth * metadata.ExifImageHeight;
          if (requiredModel === undefined) {
            requiredModel = currentModel;
          }
          if (requiredResolution === undefined) {
            requiredResolution = currentResolution;
          }
          let error: string | undefined = undefined;
          if (currentModel != requiredModel) {
            error = "camera model mismatch";
          } else if (currentResolution != requiredResolution) {
            error = "resolution mismatch";
          }
          newImages.push({
            data: await getImageData(reader.result as string),
            error: error,
          });
          if (newImages.length === e.target.files!.length) {
            setImages(images.concat(newImages));
            setResolution(requiredResolution);
            setModel(requiredModel);
          }
        } catch (e) {
          if (e instanceof Error) {
            setError("Failed to add image: " + e.message);
          } else {
            setError("Failed to add image: " + String(e));
          }
        }
      };
    }

  }, [model, resolution, images]);
  return (
    <div className="flex flex-col gap-2">
      <PageHeader>
        <SectionHeader>
          Calibrate and publish new camera
        </SectionHeader>
        <SectionDescription>
          Camera calibration is used to eliminate the radial and tagential distortion introduced by the camera. This will improve part scan accuracy.
        </SectionDescription>
      </PageHeader>
      <Section footer={
        <>
          <input type="file" id="file" multiple accept=".jpg,.jpeg" ref={inputFile} style={{ display: "none" }} onChange={addImages} />
          <Button disabled={busy} variant="outline" onClick={() => {
            inputFile.current!.click();
          }}>
            <UploadIcon />
            Upload images
          </Button>
          <div className="flex-auto" />
          <HintButton hint={images.length < 6 ? "Add at leaset 6 images" : undefined} disabled={busy} variant="primary" onClick={async () => {
            opencvRequest({
              type: "calibrate",
              images: images.map(i => i.data),
            }).then(result => {
              if (result instanceof Error) {
                setError(result.message);
              } else {
                setLastQuality(currentQuality);
                setCurrentQuality(result.quality);
                setCalibration(result.calibration);
              }
              setBusy(false);
            });
          }}>
            <MagicWandIcon />
            Start calibration
          </HintButton>
        </>
      }>
        <SectionLabel htmlFor="model">
          Model
        </SectionLabel>
        <SectionDescription>
          The model is automatically extracted from the first uploaded image metadata.
        </SectionDescription>
        <Input id="model" value={model ?? ""} readOnly className="bg-slate-50" />
        <SectionLabel htmlFor="setup" className="mt-2">
          Setup
        </SectionLabel>
        <SectionDescription>
          Describe the setup that should be used for making scans (camera settings etc.).
        </SectionDescription>
        <Input id="setup" defaultValue={description} onChange={e => setDescription(e.target.value)} />
        <div className="mt-2 flex gap-4">
          <Section nested>
            <SectionLabel>
              Calibration images
            </SectionLabel>
            <p>
              Modern cameras introduce significant radial and tangential distortion. This will reduce the dimensional accurracy and in this case lead to inaccurate scans of board game parts.
              The good news is that these errors are constant and can be eliminated. The calibration will determine the camera distortion which can then be used to remap any future scans.
              <div className="mt-2 flex flex-col gap-2">
                <BulletPoint hint="Make sure to print at 100% on DIN-A4 without any additional borders.">
                  Download and print the calibration sheet
                </BulletPoint>
                <BulletPoint hint=" Ensure that the orientation is always identical (camera calibration label is always on the bottom) and that the entire chess board pattern is always visible. The calibration sheed must not be wrinkled or warped.">
                  Take at least 6 pictures of the calibration sheet from different angles
                </BulletPoint>
              </div>
            </p>
          </Section>
          <div className="flex flex-col">
            <img src={sheetPreview} className="w-40 rounded-t-lg border border-b-0" />
            <a href={sheetPdf} target="_blank" rel="noreferrer">
              <Button variant="outline" className="rounded-t-none">
                <DownloadIcon />
                Download sheet
              </Button>
            </a>
          </div>
        </div>
        {error && (
          <div className="test-destructive">
            {error}
          </div>
        )}
      </Section>
      {images.length > 0 && (
        <div className="grid grid-cols-6 gap-1">
          {images.map(i => <ImageThumbnail key={images.indexOf(i)} busy={busy} image={i} deleteImage={() => {
            setImages(images.filter(o => o != i))
            setCalibration(undefined);
          }} setPreview={image => setValidationImage(image)} calibration={calibration} />)}
        </div>
      )}
      <Section>
        <SectionLabel>
          Calibration results
        </SectionLabel>
        <SectionDescription>
          The calibration algorithm aims to find the exact inner corners of the chessboard pattern and their order. Please validate the accuracy of the pattern analysis and make sure that the remapped images look reasonable.
        </SectionDescription>
        {calibration !== undefined ? (
          <>
            <TransformWrapper centerZoomedOut={false} centerOnInit={true} minScale={0.1}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <div className="flex flex-col gap-2 ">
                  <div className="flex gap-2">
                    <Button variant="outline" onClick={() => zoomIn()}>
                      <PlusIcon />
                    </Button>
                    <Button variant="outline" onClick={() => zoomOut()}>
                      <MinusIcon />
                    </Button>
                    <div className="flex-auto" />
                    <Button variant="outline" onClick={() => resetTransform()}>
                      <EnterFullScreenIcon />
                    </Button>
                  </div>
                  <div className="relative">
                    <TransformComponent wrapperClass="max-w-full max-h-[800px] rounded border">
                      <canvas ref={canvasRef}></canvas>
                    </TransformComponent>
                    <div className="absolute right-2 top-2">
                      <MiniMap className="rounded border">
                        <canvas ref={canvasRefMinimap}></canvas>
                      </MiniMap>
                    </div>
                  </div>
                </div>
              )}
            </TransformWrapper>
            <Label className="mt-2">
              Camera calibration quality
            </Label>
            <p>
              The overall quality of the camera calibration settings is calculated as the average reprojection error for all provided images. The reprojection error is the total sum of all squared distances between the projected observerd pattern points and their real world positions. Smaller values are better, values under 2 are good.
            </p>
            <div className="flex gap-2">
              <div className="flex flex-col rounded border p-2">
                <div className="text-sm">
                  Last calibration quality
                </div>
                <div className="text-center font-mono">
                  {lastQuality === undefined ? "not available" : lastQuality.toFixed(4)}
                </div>
              </div>
              <div className="flex flex-col rounded border border-primary p-2">
                <div className="text-sm">
                  Calibration quality
                </div>
                <div className="text-center font-mono">
                  {currentQuality === undefined ? "not available" : currentQuality.toFixed(4)}
                </div>
              </div>
              <div className="flex flex-col rounded border p-2">
                <div className="text-sm">
                  Change in calibration quality
                </div>
                <div className="text-center font-mono">
                  {currentQuality === undefined || lastQuality == undefined ? "not available" : (currentQuality - lastQuality).toFixed(4)}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <Button variant="primary" disabled={calibration === undefined} onClick={() => {
                addCamera({
                  model: model!,
                  description: description,
                  data: calibration,
                });
              }}>
                <SaveIcon />
                Publish camera
              </Button>
            </div>
          </>
        ) : (
          <Placeholder type="info">
            Start the calibration to show its results
          </Placeholder>
        )}
      </Section>
    </div>
  );
}