/* eslint-disable react/no-unknown-property */
import { Blueprint, TrayModel, Vector } from "@/types";
import { useEffect, useState } from "react";
import { generateTrayGeometry } from "@/replicadWorkerPool";
import Skeleton from "../canvas/Skeleton";
import { Vector3 } from "three";
import { ReplicadShape } from "../canvas/ReplicadShape";
import { Box } from "@react-three/drei";
import { getBlueprint, getBlueprintForTray, selectFromBlueprint, selectFromTray, useAppSelector } from "@/state/store";
import BoxInfo from "../canvas/BoxInfo";

export default function InsertCanvasTray({ trayId: trayId, setSelectedTrayId, positionOverride }: { 
  trayId: number,
  positionOverride?: Vector3,
  setSelectedTrayId?: (trayId: number) => void,
 }) {
  const blueprintModelHash = useAppSelector(s => s.model.blueprints[s.model.trays[trayId].blueprintId].modelHash);
  const trayModelHash = useAppSelector(s => s.model.trays[trayId].modelHash);
  const offset = useAppSelector(s => s.model.trays[trayId].offset);
  const size = useAppSelector(s => s.model.trays[trayId].size);
  const bounds = useAppSelector(s =>s.model.trays[trayId].bounds);
  const [model, setModel] = useState<TrayModel>();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  useEffect(() => {
    setIsGenerating(true);
    generateTrayGeometry(trayId, false).then(result => {
      if(result.length > 0) {
        setModel({
         ...result[0],
         color: getBlueprintForTray(trayId).color,
         trayId: trayId,
        });
      }
      setIsGenerating(false);
    }, error => {
      setIsGenerating(false);
    });
  }, [ trayId, blueprintModelHash, trayModelHash ]);
  return (
    <group position={positionOverride !== undefined ? positionOverride : new Vector3(offset.x, offset.y, offset.z)}>
      {model === undefined || isGenerating ? (
          <Skeleton size={size}/>
      ) : (
        <>
          <ReplicadShape clipConstant={5} clipDirection={null} wireframe={false} model={model} edgesHighlight={[]} facesHighlight={[]} onEdgeClick={(e, index, edge) => { }} onFaceClick={(e, index, face) => { }} />
          {setSelectedTrayId !== undefined && bounds.map(b => (
            <Box key={bounds.indexOf(b)} userData={{
              "tray": model.trayId,
            }} position={[b.xOffset + b.x / 2, b.yOffset + b.y / 2, b.zOffset + b.z / 2]} scale={[b.x, b.y, b.z]} onClick={e => {
              if (e.intersections.find(i => i.eventObject.userData["tray"] !== undefined)?.eventObject.userData["tray"] === model.trayId) {
                setSelectedTrayId(model.trayId);
              }
            }}>
              <meshBasicMaterial transparent={true} opacity={0} depthTest={false}/>
            </Box>
          ))}
          {/* TODO improve and reenable selection highlight
          {setSelectedTrayId === undefined && bounds.map(b => (
            <BoxInfo color="#ffffff" showCoordinates={false} key={bounds.indexOf(b)} position={{
              x: b.xOffset, 
              y: b.yOffset, 
              z: b.zOffset,
            }} size={{
              x: b.x, 
              y: b.y, 
              z: b.z
            }}/>
          ))}
            */}
        </>
      )}
    </group>
  );
}