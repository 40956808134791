import { useEffect } from "react";

export function useModifiers() {
  const modifiers = {
    ctrl: false,
    shift: false,
    alt: false,
  };
  const handler = (e: KeyboardEvent) => {
    modifiers.alt = e.altKey;
    modifiers.shift = e.shiftKey;
    modifiers.ctrl = e.ctrlKey;
  };
  useEffect(() => {
    window.addEventListener("keydown", handler);
    window.addEventListener("keyup", handler);
    return () => {
      window.removeEventListener("keydown", handler);
      window.removeEventListener("keyup", handler);
    };
  }, []);
  return modifiers;
}