import { DbBox } from "@/state/api/boxes";
import { HeartFilledIcon, Link1Icon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { useNavigate } from "react-router-dom";

export default function BoxesCard( { box, link = false } : { box: DbBox, link?: boolean }) {
  const navigate = useNavigate();
  if(link) {
    return (
      <div className="flex items-stretch">
        <div className="flex items-center gap-2 rounded-l border bg-white px-2 py-1">
          <div className="">
            {box.name}
          </div>
          <div className="text-secondary-foreground">
            {box.data}
          </div>
        </div>
        <div className="flex items-center gap-1 border-y bg-slate-50 px-2 py-1">
          <HeartFilledIcon className="text-primary"/>
          23
        </div>
        <Button className="rounded-l-none" size="sm" onClick={() => navigate("/boxes/" + box.id)}>
          <Link1Icon/>
        </Button>
      </div>
    );
  } else {
    return (
      <div className="flex">
        <div className="flex items-center gap-2 rounded-l border bg-white px-2 py-1">
          <div className="">
            {box.name}
          </div>
          <div className="text-secondary-foreground">
            {box.data}
          </div>
        </div>
        <div className="flex items-center gap-1 rounded-r border-y border-r bg-slate-50 px-2 py-1">
          <HeartFilledIcon className="text-primary"/>
          23
        </div>
      </div>
    );
  }
}