import { Fragment } from "react";
import {ReplicadShape } from "./ReplicadShape";
import { Box, ShapeLayout } from "./ShapeLayout";
import { TrayModel, Vector } from "@/types";
import { EdgeSelection, FaceSelection, Selection } from "@/pages/designer/blueprint/BlueprintCanvas";

export function ReplicadShapes({
  models,
  selections,
  disableLayout = true,
  onSelect,
  wireframe,
  clipDirection,
  clipConstant,
  color,
}: {
  models: TrayModel[],
  selections: Selection[],
  onSelect?: (s: Selection) => void,
  disableLayout: boolean,
  wireframe?: boolean,
  clipDirection: Vector | null,
  clipConstant: number,
  color?: string,
}) {
  const P = disableLayout ? Fragment : ShapeLayout;
  return (
    <P>
      {models.map(model => {
        return (
          <Box key={model.name}>
            <ReplicadShape
              wireframe={wireframe}
              clipConstant={clipConstant}
              clipDirection={clipDirection}
              model={model}
              color={color}
              edgesHighlight={selections.filter(s => s.type == "edge" && s.shapeName == model.name).map(s => (s as EdgeSelection).index)}
              facesHighlight={selections.filter(s => s.type == "face" && s.shapeName == model.name).map(s => (s as FaceSelection).index)}
              onFaceClick={(e, index, face) => onSelect?.({
                type: "face",
                shapeName: model.name,
                index: index,
                face: face,
              })}
              onEdgeClick={(e, index, edge) => onSelect?.({
                type: "edge",
                shapeName: model.name,
                index: index,
                edge: edge,
              })}
            />
          </Box>
        );
      })}
    </P>
  );
}