import { api } from '../store'

export interface Game {
  id: number,
  name: string,
  year: number,
}

export interface GameDetails {
  id: number,
  name: string,
  year: number,
  image: string,
  minPlayers: number,
  maxPlayers: number,
  minPlaytime: number,
  maxPlaytime: number,
  age: number,
  designers: string[],
  publishers: string[],
  categories: string[],
  mechanics: string[],
  expansions: {
    id: number,
    name: string,
  }[],
  baseGame?: {
    id: number,
    name: string,
  }
}

const boxesApi = api.injectEndpoints({
  endpoints: builder => ({
    searchBoardgame: builder.query<Game[], {
      query: string,
    }>({
      query: (arg) => ({
        url: `/games/search?query=${encodeURIComponent(arg.query)}`,
      }),
    }),
    getBoardgame: builder.query<GameDetails, {
      id: number,
    }>({
      query: (arg) => ({
        url: `/games/${arg.id}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useLazySearchBoardgameQuery, useGetBoardgameQuery, useLazyGetBoardgameQuery } = boxesApi;