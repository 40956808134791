import * as React from "react"

import { cn } from "./utils"
import { Button } from "./button"
import { Input } from "./input";
import { darken, lighten } from "polished";
import { MoonIcon, SunIcon } from "react-line-awesome";

export interface ColorPickerProps {
  color: string,
  onColorChange: (color: string) => void,
  swatches: string[],
  className?: string,
}

const ColorPicker = React.forwardRef<HTMLDivElement, ColorPickerProps>(
  ({ className, color, onColorChange, swatches }, ref) => {
    const items = React.useMemo(() => swatches.map(s => (
      <Button key={s} className="h-5 p-0" style={{
        backgroundColor: s
      }} onClick={() => onColorChange(s)} />
    )), []);
    const [currentColor, setCurrentColor] = React.useState(color);
    React.useEffect(() => setCurrentColor(color), [color]);
    return (
      <div ref={ref} className={cn("grid grid-cols-10 gap-1", className)}>
        {items}
        <Button variant="outline" className="col-span-3 h-7 p-0" onClick={() => {
              onColorChange(darken(0.1, currentColor));
            }}>
              <MoonIcon size="lg"/>
              darken
            </Button>
            <Button variant="outline" className="col-span-3 h-7 p-0" onClick={() => {
              onColorChange(lighten(0.1, currentColor));
            }}>
              <SunIcon size="lg"/>
              lighten
            </Button>
        <Input value={currentColor} onChange={e => {
          let c = e.target.value as string;
          if (!c.startsWith("#")) {
            c = "#" + c;
          }
          if (c.length == 7) {
            onColorChange(c);
          } else {
            setCurrentColor(c);
          }
        }} className="col-span-4 h-7" />
      </div>
    )
  }
);

ColorPicker.displayName = "ColorPicker"

export { ColorPicker }
