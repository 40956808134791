import { addBlueprint, addTray, deleteTray, select } from "@/state/model";
import { getInsert, getBlueprint, getTray, selectCurrentTrayId, useAppDispatch, useAppSelector, getBlueprintForTray } from "@/state/store";
import { Button } from "@ui/button";
import { CardStackIcon, CardStackPlusIcon, CopyIcon, DragHandleHorizontalIcon, GearIcon, ThickArrowRightIcon, TrashIcon } from "@radix-ui/react-icons";
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from "@dnd-kit/sortable";
import { defaultTrayName } from "@/catalog";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { TrayType } from "@/types";
import { getPalletteColor, getTrayName } from "@/utils";
import { Badge } from "@ui/badge";

export default function InsertTrayListItem({ item }: {
  item: {
    trayId: number,
    blueprintId: number,
    type: "standalone" | "primary" | "secondary",
    group?: number,
  },
}) {
  const dispatch = useAppDispatch();
  const blueprintName = useAppSelector(s => s.model.blueprints[item.blueprintId].name);
  const trayName = useAppSelector(s => s.model.trays[item.trayId].name);
  const name = getTrayName(blueprintName, trayName);
  const selectedTrayId = useAppSelector(selectCurrentTrayId);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.trayId,
  });
  const isSelected = selectedTrayId == item.trayId;
  return (
    <div ref={setNodeRef} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"bg-slate-50 mb-[-1px] rounded-l-lg border peer" + (isSelected ? " bg-white hover:bg-white" : " hover:bg-slate-100") + (isDragging ? " z-50" : "")}>
      <div className="flex cursor-pointer flex-row items-center p-0.5">
        <button {...listeners} {...attributes}>
          <DragHandleHorizontalIcon className="ml-1" />
        </button>
        <Button className="flex-auto select-none justify-start px-1" variant="ghost" onClick={() => dispatch(select({
          scope: "insert",
          aspect: "tray",
          trayId: item.trayId,
        }))}>
          <div className={isSelected ? "text-primary" : ""}>
            {name}
          </div>
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={() => dispatch(addTray({
              blueprintId: getBlueprintForTray(item.trayId).id,
              index: getInsert().trays.indexOf(item.trayId) + 1,
              selectIn: "insert",
            }))} variant="ghost" className="px-2">
              <CardStackPlusIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Add another instance of this tray.
          </TooltipContent>
        </Tooltip>
        {item.type === "primary" && (
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button onClick={() => {
                  const blueprint = getBlueprint(item.blueprintId);
                  dispatch(addBlueprint({
                    index: getInsert().trays.indexOf(item.trayId) + 1,
                    name: blueprint.name + " - Copy",
                    blueprint: blueprint,
                    selectIn: "insert",
                  }));
                }} variant="ghost" className="px-2">
                  <CopyIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Duplicate the tray.
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button onClick={() => dispatch(select({
                  scope: "blueprint",
                  aspect: name.startsWith(defaultTrayName) ? "basics" : "configuration",
                  blueprintId: item.blueprintId,
                }))} variant="ghost" className="px-2">
                  <GearIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Edit the tray.
              </TooltipContent>
            </Tooltip >
          </>
        )}
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={() => dispatch(deleteTray({
              tray: item.trayId,
            }))} variant="ghost" className="px-2">
              <TrashIcon />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Delete the tray.
          </TooltipContent>
        </Tooltip>
      </div >
    </div >
  );
}