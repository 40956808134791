import { configurationKeyEnumerations } from "@/catalog";
import { Drawer } from "@/types";
import { Checkbox } from "@ui/checkbox";
import { InputNumber } from "@ui/inputNumber";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";

export default function getCommonDrawers(): Drawer[] {
  return [/*{
    keys: ["cornerModifier", "cornerModifierEnable", "cornerModifierLength"],
    useLabel: true,
    control: (configuration, modifyConfiguration) => {
      const enumerations = configurationKeyEnumerations("cornerModifier")!;
      const modifier = configuration.cornerModifier!;
      return (
        <div className="flex items-center gap-1">
          <Checkbox id="cornerModifierEnable" checked={configuration.cornerModifierEnable} onCheckedChange={e => modifyConfiguration({
            cornerModifierEnable: Boolean(e),
          })} />
          <Select name="cornerModifier" value={enumerations[modifier]} onValueChange={e => modifyConfiguration({
            cornerModifier: enumerations.indexOf(e),
          })}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {enumerations.map(e => (
                <SelectItem value={e} key={e}>
                  {e}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <InputNumber unit="mm" id="cornerModifierLength" value={configuration.cornerModifierLength} setValue={e => modifyConfiguration({
            cornerModifierLength: e,
          })} />
        </div>
      );
    }
  }*/]
}