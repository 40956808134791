import { Badge } from "@ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { CubeIcon } from "react-line-awesome";
import { getCurrentBlueprintId, selectFromCurrentBlueprint, useAppDispatch, useAppSelector } from "@/state/store";
import { shallowEqual } from "react-redux";
import { BoxIcon, BoxModelIcon, GearIcon, MixIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { select } from "@/state/model";

export default function BlueprintHeader({ blueprintId }: { blueprintId: number }) {
  const name = useAppSelector(s => s.model.blueprints[blueprintId].name);
  const version = useAppSelector(s => s.model.blueprints[blueprintId].version);
  const type = useAppSelector(s => s.model.blueprints[blueprintId].type);
  const selection = useAppSelector(s => s.model.selection);
  const dispatch = useAppDispatch();
  return (
    <div className="mt-1 flex flex-col gap-2 rounded-t-sm border-x border-t bg-white p-2">
      <div className="flex">
        <span className="mr-2 text-xl leading-none">
          {name}
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Badge variant="outline">v{version}</Badge>
          </TooltipTrigger>
          <TooltipContent>
            The current tray version.
          </TooltipContent>
        </Tooltip>
        <div className="flex-auto"/>
        <Badge variant="secondary">{type}</Badge>
      </div>
      <div className="flex gap-1 rounded-lg bg-slate-100 p-1">
          <Button disabled={selection.aspect === "basics"} variant="outline" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "basics",
            blueprintId: blueprintId,
          }))} className="flex-auto bg-slate-100 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
            <MixIcon />
            Basics
          </Button>
          <Button disabled={selection.aspect === "configuration"} variant="outline" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "configuration",
            blueprintId: blueprintId,
          }))} className="flex-auto bg-slate-100 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
            <GearIcon/>
              Configuration
          </Button>
          <Button disabled={selection.aspect === "globals"} variant="outline" onClick={() => dispatch(select({
            scope: "blueprint",
            aspect: "globals",
            blueprintId: blueprintId,
          }))} className="flex-auto bg-slate-100 px-1 disabled:border disabled:bg-white disabled:text-primary disabled:opacity-100 disabled:shadow">
            <MixerHorizontalIcon/>
              Globals
          </Button>
        </div>
    </div>
  );
}