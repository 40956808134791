import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./components/App";
import "./index.css"

// This is here to compensate for a bug in vite
import "replicad-opencascadejs/src/replicad_single.wasm?url";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <App/>
);

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept();
}
