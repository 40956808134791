import React from "react";
import { cn } from "./utils";
import { ArrowRightIcon } from "@radix-ui/react-icons";

export interface BulletPointProps extends React.HTMLAttributes<HTMLDivElement> {
  hint?: React.ReactNode,
  hintClassName?: string
}

const BulletPoint = React.forwardRef<HTMLDivElement, BulletPointProps>(
  ({ children, className, hint, hintClassName = "text-secondary-foreground", ...props }, ref) => {
    return (
      <div className={cn("flex flex-col", className)}>
        <div className="flex items-center gap-2">
          <ArrowRightIcon className="w-4 flex-none" />
          <div className="flex-auto">
            {children}
          </div>
        </div>
        {hint !== undefined && (
          <div className={cn("ml-6", hintClassName)}>
            {hint}
          </div>
        )}
      </div>
    );
  }
)

BulletPoint.displayName = "BulletPoint";

export { BulletPoint }

