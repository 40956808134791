import { Button } from "@ui/button";
import { Input } from "@ui/input";
import { InputSearch } from "@ui/inputSearch";
import { PocketElementBlueprint } from "@/types";
import { CheckIcon } from "@radix-ui/react-icons";
import { useState } from "react";

export default function PocketLibrary(element: {element: PocketElementBlueprint}) {
  const [name, setName] = useState("");
  return (
    <div className="flex flex-col gap-1">
      <h4 className="font-medium leading-none">Save to preset library</h4>
      <p className="text-sm text-muted-foreground">
        Save this pocket configuration to an account wide preset library.
      </p>
      <div className="flex gap-1">
        <Input value={name} onChange={e => setName(e.target.value)} className="h-8 rounded-r-none"/>
        <Button variant="primary" className="size-8 flex-none rounded-l-none p-0">
          <CheckIcon className="size-5"/>
        </Button>
      </div>
      <h4 className="mt-3 font-medium leading-none">Restore from preset</h4>
      <p className="text-sm text-muted-foreground">
        Overwrite the current configuration with a preset from the preset library.
      </p>
      <InputSearch/>
      No entries found.
    </div>
  );
}