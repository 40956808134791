import { modifyInsertGlobals } from "../../../state/model";
import { useAppDispatch, useAppSelector } from "@/state/store";
import ConfigurationEditor from "../ConfigurationEditor";

export default function InsertGlobalsProperties({ query }: { query: string }) {
  const dispatch = useAppDispatch();
  const globals = useAppSelector(s => s.model.insert.globals);
  return <ConfigurationEditor query={query} configuration={globals} modifyConfiguration={c => dispatch(modifyInsertGlobals({
    globals: c
  }))}/>
}