import ConfigurationEditor from "../../ConfigurationEditor";
import { getBlueprint, selectCurrentBlueprintId, useAppDispatch, useAppSelector } from "@/state/store";
import { alignHexagonCutoutToPocket, modifyElement, modifyElementConfiguration, modifyElementLayout } from "@/state/model";
import { Configuration, Drawer, TrayElement, MagicActions, PocketElementBlueprint, pocketTypes, PocketType } from "@/types";
import { InputSubmit } from "@ui/inputSubmit";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@ui/select";
import { configurationKeyName, getPocketLayoutSupport as getPocketPlacementSupport } from "@/catalog";
import { calcualteFillet } from "@/designer/library";
import { useCallback, useMemo } from "react";
import { Button } from "@ui/button";
import ElementPlacementDrawer from "./ElementPlacementDrawer";

export function PocketConfigurationProperties({ query, trayId, blueprintId, elementBlueprint }: { query: string, trayId: number, blueprintId: number, elementBlueprint: PocketElementBlueprint }) {
  const dispatch = useAppDispatch();
  const element = useAppSelector(s => s.model.trays[trayId].elements[elementBlueprint.id]);
  const modifyConfiguration = useCallback((configuration: Configuration) => {
    dispatch(modifyElementConfiguration({
      blueprint: blueprintId,
      element: elementBlueprint.id,
      configuration: configuration,
    }));
  }, [ blueprintId, elementBlueprint.id ]);
  const magicActions = useMemo<MagicActions>(() => ({
    cornerModifierLength: {
      tooltip: "Calculate from tray corner modifier length to yield a constant wall width.",
      handler: () => {
        const blueprint = getBlueprint(blueprintId);
        return calcualteFillet(blueprint.configuration.cornerModifierLength!, blueprint.globals.wall);
      }
    },
    takeoutAssistLength: {
      tooltip: "Calculate from tray corner modifier length to yield a constant wall width.",
      handler: () => {
        const blueprint = getBlueprint(blueprintId);
        return calcualteFillet(elementBlueprint.configuration.cornerModifierLength!, blueprint.globals.wall);
      }
    }
  }), [ blueprintId, elementBlueprint.configuration ]);
  const drawers = useMemo<Drawer[]>(() => {
    const result: Drawer[] = [{
      keys: [ "name" ],
      control: <InputSubmit initialValue={elementBlueprint.name} applyValue={n => dispatch(modifyElement({
        blueprint: blueprintId,
        element: elementBlueprint.id,
        name: n,
      }))} />,
      useLabel: true,
    }, {
      keys: [ "pocketType" ],
      control: <Select value={elementBlueprint.pocketType} onValueChange={e => {
        dispatch(modifyElement({
          blueprint: blueprintId,
          element: elementBlueprint.id,
          pocketType: e as PocketType
        }));
      }}>
        <SelectTrigger>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {pocketTypes.map(t => (
            <SelectItem value={t} key={t}>
              {t}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>,
      useLabel: true,
    }, {
      keys: [ "hexCutAlign" ],
      control: <Button variant="outline" className="h-8" onClick={() => dispatch(alignHexagonCutoutToPocket({
        blueprint: blueprintId,
        element: elementBlueprint.id,
      }))}>
        {configurationKeyName("hexCutAlign")}
      </Button>,
      useLabel: false,
    }];   
    if(getPocketPlacementSupport(elementBlueprint.pocketType)) {
      result.push({
        keys: [ "placement" ],
        control: <ElementPlacementDrawer elementId={elementBlueprint.id} layout={elementBlueprint.layout} measure={element.measure} placement={element.placement}/>,
        useLabel: true,
      });
    }
    return result;
  }, [blueprintId, elementBlueprint, element]);
  return (
    <ConfigurationEditor query={query} configuration={elementBlueprint.configuration} modifyConfiguration={modifyConfiguration} magicActions={magicActions} drawers={drawers}/>
  );
}