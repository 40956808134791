import * as React from "react"
import { cn } from "./utils"
import { Button } from "./button"
import { Input } from "./input";
import { Cross1Icon, Cross2Icon, MagnifyingGlassIcon, UpdateIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export interface InputSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  isBusy?: boolean,
  inputClassName?: string,
}

const InputSearch = React.forwardRef<HTMLInputElement, InputSearchProps>(
  ({ className, inputClassName, onClear, isBusy = false, value, ...props }, ref) => {
    return (
      <div className={cn("relative", className)}>
        <Input className={cn("flex-auto pl-7", inputClassName)} ref={ref} value={value} {...props} onKeyDown={k => {
          if (k.code === "Escape" && onClear !== undefined) {
            onClear();
          }
        }}/>
        <div className="pointer-events-none absolute inset-y-0 start-0 z-20 flex items-center pl-2 opacity-50">
          <MagnifyingGlassIcon />
        </div>
        <div className="absolute inset-y-1 end-1 z-20 flex items-center">
          {isBusy && (
            <UpdateIcon className="size-3 animate-spin"/>
          )}
          {onClear !== undefined && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button disabled={!value} variant="ghost" className="h-full rounded-sm px-2" onClick={() => onClear()}>
                  <Cross1Icon />
                </Button>
              </TooltipTrigger>
              <TooltipContent autoFocus={false}>
                Press <b>Esc</b> or use this button to clear the search query.
              </TooltipContent>
            </Tooltip>
          )}
          </div>
      </div>
    )
  }
)
InputSearch.displayName = "InputSearch"

export { InputSearch }
