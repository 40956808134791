import React from "react"
import { cn } from "./utils"

export interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string,
  onError?: () => void,
}

const ImageBox = React.forwardRef<HTMLDivElement, ImageBoxProps>(
  ({ src, onError, className, ...props }, ref) => {
    return (
      <div className={cn("flex justify-center rounded border bg-slate-50 p-2", className)} {...props}>
        <img src={src} onError={onError} className="rounded object-contain"/>
      </div>
    )
  }
)

ImageBox.displayName = "ImageBox";

export { ImageBox }




