import { store } from "@/state/store";
import Designer from "../pages/designer/Designer";
import { Provider } from "react-redux";
import { TooltipProvider } from "./ui/tooltip";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Error404 from "../pages/community/Error404";
import Home from "../pages/community/Home";
import WorkbenchLayout from "@/pages/workbench/WorkbenchLayout";
import WorkbenchRecent from "@/pages/workbench/WorkbenchRecent";
import DesignerLayout from "@/pages/designer/DesignerLayout";
import WorkbenchAll from "@/pages/workbench/WorkbenchAll";
import WorkbenchTrays from "@/pages/workbench/WorkbenchTrays";
import { StrictMode } from "react";
import UpdatesIndex from "@/pages/updates/UpdatesIndex";
import CenteredLayout from "@/pages/CenteredLayout";
import { UpdatesPublish, UpdatesEdit } from "@/pages/updates/UpdatesPublish";
import UpdatesDetails from "@/pages/updates/UpdatesDetails";
import PartDesigner from "@/pages/parts/PartDesigner";
import CameraNew from "@/pages/cameras/CameraNew";
import CameraDetails from "@/pages/cameras/CameraDetails";
import GameDetails from "@/pages/games/GameDetails";
import BoxesNew from "@/pages/boxes/BoxesNew";
import BoxesDetails from "@/pages/boxes/BoxesDetails";

export default function ReplicadApp() {
  return (
    <StrictMode>
      <GoogleOAuthProvider clientId="653501001383-gui3o1ksajqg2m366l850dg886to2mcr.apps.googleusercontent.com">
        <TooltipProvider>
          <Provider store={store}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<CenteredLayout scope="explore" maxWidth={1400}/>}>
                  <Route index element={<Home/>}/>
                  <Route path="insert" element={<Designer/>} />
                  <Route path="profile" element={<Designer/>} />
                  <Route path="profile" element={<Designer/>} />
                  <Route path="*" element={<Error404/>} />
                </Route>
                <Route path="/workbench" element={<WorkbenchLayout/>}>
                  <Route index element={<WorkbenchRecent/>}/>
                  <Route path="recent" element={<WorkbenchRecent/>} />
                  <Route path="all" element={<WorkbenchAll/>} />
                  <Route path="*" element={<Error404/>} />
                  <Route path="trays" element={<WorkbenchTrays/>} />
                </Route>
                <Route path="/designer" element={<DesignerLayout/>}>
                  <Route index element={<Designer/>}/>
                </Route>
                <Route path="/updates" element={<CenteredLayout scope="updates" maxWidth={1000}/>}>
                  <Route index element={<UpdatesIndex/>}/>
                  <Route path="publish" element={<UpdatesPublish/>}/>
                  <Route path="edit/:id" element={<UpdatesEdit/>}/>
                  <Route path=":id" element={<UpdatesDetails/>}/>
                </Route>
                <Route path="/parts" element={<DesignerLayout/>}>
                  <Route path="new" element={<PartDesigner/>}/>
                </Route>
                <Route path="/games" element={<CenteredLayout scope="games" maxWidth={1000}/>}>
                  <Route path=":id" element={<GameDetails/>}/>
                </Route>
                <Route path="/cameras" element={<CenteredLayout scope="cameras" maxWidth={1000}/>}>
                  <Route path="new" element={<CameraNew/>}/>
                  <Route path=":id" element={<CameraDetails/>}/>
                </Route>
                <Route path="/boxes" element={<CenteredLayout scope="boxes" maxWidth={1000}/>}>
                  <Route path="new" element={<BoxesNew/>}/>
                  <Route path=":id" element={<BoxesDetails/>}/>
                </Route>
              </Routes>
            </BrowserRouter>
          </Provider>
        </TooltipProvider>
      </GoogleOAuthProvider>
    </StrictMode>
  );
}