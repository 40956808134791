import { useGetBoardgameQuery, useLazyGetBoardgameQuery } from "@/state/api/boardgames";
import { useGetBoxQuery } from "@/state/api/boxes";
import { useGetCameraQuery } from "@/state/api/cameras";
import { imageUrlFromId, parseNumber } from "@/utils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Button } from "@ui/button";
import { ImageBox } from "@ui/imageBox";
import { PageHeader } from "@ui/pageHeader";
import { Placeholder } from "@ui/placeholder";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionHeader } from "@ui/sectionHeader";
import { SectionLabel } from "@ui/sectionLabel";
import { Skeleton } from "@ui/skeleton";
import { Textarea } from "@ui/textarea";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function Measurements({data} : {data: string}) {
  const values = JSON.parse(data);
  const value = `Width: ${values.x}mm\nDepth: ${values.y}mm\nHeight: ${values.z}mm`;
  return (
    <Textarea rows={3} id="setup" value={value} readOnly className="bg-slate-50 font-mono"/>
  );
}

export default function BoxesDetails() {
  const { id } = useParams();
  const getBox = useGetBoxQuery({
    id: id!,
  });
  const [getBoardgame, getBoardgameStatus] = useLazyGetBoardgameQuery();
  useEffect(() => {
    if(getBox.isSuccess && getBox.data !== undefined) {
      getBoardgame({
        id: getBox.data.gameId,
      });
    }
  }, [ getBox ]);
  const [showImage, setShowImage] = useState(true);
  if (getBox.isLoading) {
    return (
      <Placeholder background="#f8fafc" accent="#e2e8f0">
        Loading box details...
      </Placeholder>
    );
  } else if (getBox.isSuccess && getBox.data !== undefined) {
    return (
      <div className="flex flex-col gap-2">
        <PageHeader>
          <SectionHeader>
          Box details
          </SectionHeader>
          <SectionDescription>
            Cameras are created using a calibration wizard and store information about lense distortion. This information is used to improve the accuracy of part scans.
          </SectionDescription>
        </PageHeader>
        <Section>
          <SectionLabel>
            Game
          </SectionLabel>
          <SectionDescription>
            The game that this box is associated with.
          </SectionDescription>
          {getBoardgameStatus.isSuccess ? (
           <div className="flex gap-2 rounded border bg-white p-2">
            <img src={getBoardgameStatus.data.image} className="w-32 rounded shadow-lg" />
            <div className="flex flex-auto flex-col items-start gap-2 ">
              <table className="z-10 table text-sm">
                <tbody>
                  <tr>
                    <td className="pr-2 font-semibold">Name:</td>
                    <td>{getBoardgameStatus.data.name}</td>
                  </tr>
                  <tr>
                    <td className="pr-2 font-semibold">Year:</td>
                    <td>{getBoardgameStatus.data.year}</td>
                  </tr>
                  <tr>
                    <td className="pr-2 font-semibold">Id:</td>
                    <td>{getBoardgameStatus.data.id}</td>
                  </tr>
                </tbody>
              </table>
              <div className="flex-auto" />
              <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${getBoardgameStatus.data.id}`, '_blank')?.focus()} size="sm" className="justify-start self-stretch">
                <ExternalLinkIcon />
                Open on boardgamegeek.com
              </Button>

            </div>
          </div>
          ) : (
            <div>
              <Skeleton className=""/>
            </div>
          )}
          {showImage && (
            <>
              <SectionLabel>
                Image
              </SectionLabel>
              <SectionDescription>
                A user provided image of the box.
              </SectionDescription>
              <ImageBox src={imageUrlFromId(id!, "preview.jpeg")} onError={() => setShowImage(false)}/>
            </>
          )}
          <SectionLabel>
            Measurements
          </SectionLabel>
          <Measurements data={getBox.data.data}/>
        </Section>
        <Section>
          <SectionLabel >
            Inserts that use this box
          </SectionLabel>
          ... comming soon
        </Section>
      </div>
    );
  } else {
    <Placeholder>
      Error
    </Placeholder>
  }
}