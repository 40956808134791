import { InputGameSearch } from "@/components/InputGameSearch";
import { useLazyGetBoardgameQuery } from "@/state/api/boardgames";
import { useAddBoxMutation, useLazyListBoxesQuery } from "@/state/api/boxes";
import { parseNumber } from "@/utils";
import { ArrowLeftIcon, ChevronLeftIcon, ClipboardIcon, ExternalLinkIcon, MixIcon, UploadIcon } from "@radix-ui/react-icons";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { Input } from "@ui/input";
import { InputNumber } from "@ui/inputNumber";
import { Label } from "@ui/label";
import { PageHeader } from "@ui/pageHeader";
import { RadioGroup, RadioGroupItem } from "@ui/radio-group";
import { Section } from "@ui/section";
import { SectionDescription } from "@ui/sectionDescription";
import { SectionLabel } from "@ui/sectionLabel";
import { Separator } from "@ui/separator";
import { Textarea } from "@ui/textarea";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import BoxesCard from "./BoxesCard";
import { SectionHeader } from "@ui/sectionHeader";
import { BusyOverlay } from "@ui/busyOverlay";
import { Spinner } from "@ui/spinner";

export default function BoxesNew() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [getBoardgame, getBoardgameStatus] = useLazyGetBoardgameQuery();
  const [listBoxes, listBoxesStatus] = useLazyListBoxesQuery();
  const [addBox, addBoxStatus] = useAddBoxMutation();
  const [box, setBox] = useState("core");
  const [variant, setVariant] = useState("normal");
  const [otherVariant, setOtherVariant] = useState("");
  const [fullName, setFullName] = useState("");
  const [width, setWidth] = useState(300);
  const [depth, setDepth] = useState(300);
  const [height, setHeight] = useState(80);
  const [image, setImage] = useState<string>();
  const inputFile = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const gameId = parseNumber(searchParams.get("gameId"));
    if (gameId !== null) {
      getBoardgame({
        id: gameId,
      });
    }
  }, []);
  useEffect(() => {
    if (getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined) {
      let name = getBoardgameStatus.data.name;
      if (box !== "core") {
        const segments = box.split("‖");
        name = segments[1];
      }
      if (variant === "normal") {
        /// do nothing
      } else if (variant === "other") {
        if (otherVariant.trim().length > 0) {
          name += " (" + otherVariant + ")";
        } else {
          name += " (other)";
        }
      } else {
        name += " (" + variant + ")";
      }
      setFullName(name)
    }
  }, [getBoardgameStatus, box, variant, otherVariant]);
  useEffect(() => {
    if (getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined) {
      listBoxes({
        gameId: getBoardgameStatus.data.id,
      });
    }
  }, [getBoardgameStatus]);
  return (
    <div className="relative flex flex-col gap-2">
      <PageHeader>
        <SectionHeader>
          Create a new box
        </SectionHeader>
        <SectionDescription>
          A box is used to manage the physical dimensions and other properties of a board game enclosure with the intent to reuse this information for the insert creation process.
        </SectionDescription>
      </PageHeader>
      <div className="flex">
        <Section className="flex-1 rounded-r-none">
          <SectionLabel htmlFor="game">
            Select board game
          </SectionLabel>
          <SectionDescription>
            Select the board game that is associated with this box. Note that this site uses metadata from <Badge>boardgamegeek.com</Badge> as a reference.
          </SectionDescription>
          <InputGameSearch id="model" onSelectResult={id => getBoardgame({
            id: id,
          })} />
        </Section>
        <Section className="flex-1 rounded-l-none border-l-0 bg-slate-100">
          {getBoardgameStatus.isFetching ? (
            <div className="flex h-full items-center justify-center">
              <div className="flex items-center gap-2 text-secondary-foreground">
                <Spinner/>
                Loading metadata...
              </div>
            </div>
          ) : getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined ? (
            <div className="flex gap-2 rounded border bg-white p-2">
              <img src={getBoardgameStatus.data.image} className="w-32 rounded shadow-lg" />
              <div className="flex flex-auto flex-col items-start gap-2 ">
                <table className="z-10 table text-sm">
                  <tbody>
                    <tr>
                      <td className="pr-2 font-semibold">Name:</td>
                      <td>{getBoardgameStatus.data.name}</td>
                    </tr>
                    <tr>
                      <td className="pr-2 font-semibold">Year:</td>
                      <td>{getBoardgameStatus.data.year}</td>
                    </tr>
                    <tr>
                      <td className="pr-2 font-semibold">Id:</td>
                      <td>{getBoardgameStatus.data.id}</td>
                    </tr>
                  </tbody>
                </table>
                {getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined && getBoardgameStatus.data.baseGame !== undefined && (
                  <div className="flex w-full flex-col gap-2 rounded bg-yellow-100 p-2">
                    <div className="text-sm">
                      This is an expansion for <span className="font-semibold">{getBoardgameStatus.data.baseGame.name}</span>.
                    </div>
                    <Button onClick={() => getBoardgame({
                      id: getBoardgameStatus.data.baseGame!.id,
                    })}>
                      Select core game
                    </Button>
                  </div>
                )}
                <div className="flex-auto" />
                <Button onClick={() => window?.open(`https://boardgamegeek.com/boardgame/${getBoardgameStatus.data.id}`, '_blank')?.focus()} size="sm" className="justify-start self-stretch">
                  <ExternalLinkIcon />
                  Open on boardgamegeek.com
                </Button>

              </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="flex items-center gap-2 text-secondary-foreground">
                <ArrowLeftIcon />
                Search and select a game
              </div>
            </div>
          )}
        </Section>
      </div>
      {listBoxesStatus.isSuccess && listBoxesStatus.data !== undefined && listBoxesStatus.data.length > 0 && (
        <Section className="border-yellow-300 bg-yellow-50">
          <SectionLabel>
            Existing boxes for this game
          </SectionLabel>
          <SectionDescription>
            To prevent duplicates, make sure that no other box exists that already suits your needs.
          </SectionDescription>
          <div className="flex flex-wrap gap-1">
            {listBoxesStatus.data.map(b => <BoxesCard key={b.id} box={b} link />)}
          </div>
        </Section>
      )}
      <Section>
        <SectionLabel>
          Select box
        </SectionLabel>
        <SectionDescription>
          Expansions are often sold in their own box. When capturing data for the box of an expansion, select it here.
        </SectionDescription>
        <RadioGroup value={box} onValueChange={v => setBox(v)}>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="core" id="core" />
            <Label htmlFor="core">Core game</Label>
          </div>
          {getBoardgameStatus.isSuccess && getBoardgameStatus.data !== undefined && (
            <>
              {getBoardgameStatus.data.expansions.map(e =>
                <div key={e.id} className="flex items-center space-x-2">
                  <RadioGroupItem value={e.id + "‖" + e.name} id={"expansion-" + e.id} />
                  <Label htmlFor={"expansion-" + e.id}>{e.name}</Label>
                </div>
              )}
            </>
          )}
        </RadioGroup>
        <SectionLabel>
          Select variant
        </SectionLabel>
        <SectionDescription>
          Many games come in different variants which have different boxes.
        </SectionDescription>
        <RadioGroup value={variant} onValueChange={v => setVariant(v)}>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="normal" id="normal" />
            <Label htmlFor="normal">Normal</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="Deluxe" id="deluxe" />
            <Label htmlFor="deluxe">Deluxe</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="Big Box" id="bigbox" />
            <Label htmlFor="bigbox">Big Box</Label>
          </div>
          <div className="flex items-start space-x-2">
            <RadioGroupItem value="other" id="other" />
            <Label htmlFor="other">Other</Label>
          </div>
          {variant === "other" && (
            <Input value={otherVariant} onChange={e => setOtherVariant(e.target.value)} />
          )}
        </RadioGroup>
        <SectionLabel>
          Full box name
        </SectionLabel>
        <Input readOnly value={fullName} className="bg-slate-50" />
      </Section>
      <div className="flex items-start gap-2">
        <Section className="flex-none">
          <SectionLabel>
            Measurements
          </SectionLabel>
          <SectionDescription>
            Measure the <u>inside</u> dimensions of the box.
          </SectionDescription>
          <table className="table table-auto">
            <tbody>
              <tr>
                <td className="pr-2">Width (X)</td>
                <td><InputNumber value={width} setValue={value => setWidth(value)} unit="mm" /></td>
              </tr>
              <tr>
                <td className="pr-2">Depth (Y)</td>
                <td><InputNumber value={depth} setValue={value => setDepth(value)} unit="mm" /></td>
              </tr>
              <tr>
                <td className="pr-2">Height (Z)</td>
                <td><InputNumber value={height} setValue={value => setHeight(value)} unit="mm" /></td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section>
          <SectionLabel>
            Add photo <span className="text-secondary-foreground">(optional)</span>
          </SectionLabel>
          <SectionDescription>
            A photo of the exact box is a big confidence booster for the correct box selection during the insert creation process.
          </SectionDescription>
          <div className="flex justify-start gap-2">
            <Button variant="outline" onClick={async () => {
              const clipboardContents = await navigator.clipboard.read();
              for (const item of clipboardContents) {
                if (item.types.includes("image/png")) {
                  const blob = await item.getType("image/png");
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    setImage(reader.result as string);
                  }
                  break;
                }
              }
            }}>
              <ClipboardIcon />
              Add from clipboard
            </Button>
            <input type="file" id="file" accept=".jpg,.jpeg,.png,.gif" ref={inputFile} style={{ display: "none" }} onChange={e => {
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files![0]);
              reader.onloadend = () => {
                setImage(reader.result as string);
              }
            }} />
            <Button variant="outline" onClick={async () => {
              inputFile.current!.click();
            }}>
              <UploadIcon />
              Upload
            </Button>
            <Button variant="destructive" onClick={() => setImage(undefined)}>
              Clear
            </Button>
          </div>
          {image !== undefined && (
            <div className="flex items-center justify-center rounded border bg-slate-50 p-2">
              <img src={image} className="rounded" />
            </div>
          )}
        </Section>
      </div>
      <div className="flex justify-start">
        <Button disabled={!getBoardgameStatus.isSuccess || getBoardgameStatus.data === undefined} variant="primary" onClick={async () => {
          if (getBoardgameStatus.data !== undefined) {
            await addBox({
              gameId: getBoardgameStatus.data.id,
              name: fullName,
              data: JSON.stringify({
                x: width,
                y: depth,
                z: height,
              }),
              image: image,
            });
            if (addBoxStatus.isSuccess && addBoxStatus.data !== undefined) {
              navigate("/boxes/" + addBoxStatus.data.id);
            } else if (addBoxStatus.error !== undefined) {
              toast.error("Failed to publish box: " + addBoxStatus.error);
            }
          }
        }}>
          <MixIcon />
          Publish
        </Button>
      </div>
      {addBoxStatus.isLoading && (
        <BusyOverlay>
          Publishing box...
        </BusyOverlay>
      )}
    </div>
  );
}