import { Button } from "@ui/button";
import { Input } from "@ui/input";
import { Label } from "@ui/label";
import { getBlueprint, getTray, useAppSelector } from "@/state/store";
import { exportBlueprint } from "@/utils";
import { ArrowLeftIcon, ArrowRightIcon, CameraIcon, CheckIcon, CopyIcon, Cross2Icon, DimensionsIcon, EyeOpenIcon, MaskOffIcon, TrashIcon } from "@radix-ui/react-icons";
import { Suspense, useEffect, useState } from "react";
import { TrayModel, TrayType } from "@/types";
import { Canvas as ThreeCanvas } from "@react-three/fiber";
import * as THREE from "three";
import { ReplicadShapes } from "../canvas/ReplicadShapes";
import { OrbitControls } from "@react-three/drei";
import PhotoStage from "../canvas/PhotoStage";
import ScreenshotCapture from "../canvas/ScreenshotCapture";
import { DialogDescription, DialogHeader, DialogTitle } from "@ui/dialog";
import { Badge } from "@ui/badge";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ScrollArea } from "@ui/scroll-area";
import Editor from 'react-simple-wysiwyg';
import "@ui/wysisyg.css"
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui/tooltip";
import { Popover, PopoverAnchor, PopoverArrow, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@ui/tabs";
import { useAddTrayMutation } from "@/state/api/trays";
import { Spinner } from "@ui/spinner";

interface TrayImage {
  id: number,
  source: string,
}

function BlueprintShareImage({ image, isPrimary, deleteImage }: { image: TrayImage, isPrimary: boolean, deleteImage: () => void }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: image.id,
  });
  return (
    <div ref={setNodeRef} style={{
      transform: CSS.Translate.toString(transform),
      transition,
    }} className={"relative size-[82px] border rounded bg-white" + (isPrimary && !isDragging ? " border-primary" : "") + (isDragging ? " z-20" : "")}>
      <img src={image.source} className="size-20 rounded bg-white" />
      {isPrimary && !isDragging && (
        <div className="absolute inset-x-0 bottom-0 flex justify-center rounded-b bg-white/80 text-sm text-primary">
          Preview
        </div>
      )}
      <button {...listeners} {...attributes} className="absolute inset-0" />
      <div className="absolute right-0 top-0 flex">
        <Popover>
          <PopoverTrigger>
            <Button variant="ghost" className="size-6 rounded-md p-0">
              <EyeOpenIcon />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="size-[600px]">
            <img src={image.source}/>
            <PopoverArrow/>
          </PopoverContent>
        </Popover>
        <Button variant="ghost" className="size-6 rounded-md p-0" onClick={() => deleteImage()}>
          <TrashIcon />
        </Button>
      </div>
    </div>
  );
}

function BlueprintShareImages({ images, setImages }: { images: TrayImage[], setImages: (images: TrayImage[]) => void }) {
  return (
    <DndContext autoScroll={false} onDragEnd={e => {
      if (e.over === null) {
        return;
      }
      const activeIndex = images.findIndex(i => i.id == e.active.id);
      const overIndex = images.findIndex(i => i.id == e.over!.id);
      const current = [...images];
      const removed = current.splice(activeIndex, 1);
      current.splice(overIndex, 0, ...removed);
      setImages(current);
    }} modifiers={[]}>
      <SortableContext items={images.map(i => i.id)} id="trays" strategy={rectSortingStrategy}>
        <Label>Images</Label>
        <div className="flex flex-col gap-1 rounded bg-slate-100 p-1">
          {images.length > 0 ? (
            <>
              <div className="flex flex-wrap gap-1">
                {images.map(image => <BlueprintShareImage key={image.id} image={image} isPrimary={image.id === images[0].id} deleteImage={() => setImages(images.filter(i => image.id !== i.id))} />)}
              </div>
              <div className="text-center text-sm text-slate-500">
                The first image is used as the preview.
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center gap-1 p-1 text-sm">
              Add images using the <CameraIcon /> button on the left.
            </div>
          )}

        </div>
      </SortableContext>
    </DndContext>
  );
}

export default function BlueprintShare({ blueprintId, models }: { blueprintId: number, models: TrayModel[] }) {
  const [addSharedTray, addSharedTrayState] = useAddTrayMutation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<TrayImage[]>([]);
  const [hasCopied, setHasCopied] = useState(false);
  const [step, setStep] = useState("images");
  const library = useAppSelector(s => s.model.blueprints[blueprintId].library);
  const colors = [
    "#0d9488",
    "#0891b2",
    "#2563eb",
    "#7c3aed",
    "#c026d3",
    "#e11d48",
    "#ea580c",
    "#d97706",
    "#ca8a04",
    "#65a30d",
  ];
  useEffect(() => {
    // use z as up
    THREE.Object3D.DEFAULT_UP.set(0, 0, 1);
  }, [])
  if (addSharedTrayState.isLoading) {
    return (
      <div className="flex w-80 items-center gap-2">
        <Spinner/>
        Saving to tray library...
      </div>
    );
  } else if (addSharedTrayState.isError) {
    return (
      <>
        <DialogHeader>
          <DialogTitle>Failed to save tray</DialogTitle>
        </DialogHeader>
        <div className="flex w-80 items-center gap-2">
          The tray could not be saved to the tray library, please try again later.
        </div>
      </>
    );
  } else if (addSharedTrayState.isSuccess) {
    const url = (import.meta.env.MODE == "development" ? "http://localhost:4444" : "https://boardgameinserts.xyz") + "/designer?tray=" + addSharedTrayState.data.id;
    return (
      <>
        <DialogHeader>
          <DialogTitle>Tray was saved to library</DialogTitle>
          <DialogDescription className="w-80">The tray is now saved as version <Badge variant="outline">0</Badge> in the tray library and can be used across multiple inserts. Use the share link to directly share it with other users.</DialogDescription>
        </DialogHeader>
        <div className="flex w-80 flex-col gap-1">
          <Label htmlFor="url">Share link</Label>
          <div className="flex gap-1">
            <Input id="url" readOnly value={url} />
            <Button onClick={async () => {
              await navigator.clipboard.writeText(url);
              setHasCopied(true);
              window.setTimeout(() => setHasCopied(false), 5000);
            }} className="h-8">
              {hasCopied ? <CheckIcon className="size-5" /> : <CopyIcon className="size-5" />}
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    const dpr = Math.min(window.devicePixelRatio, 2);
    let takeScreenshot: () => string;
    return (
      <>
        <DialogHeader>
          <DialogTitle>{library === undefined ? "Add to library" : "Update in library"}</DialogTitle>
          <DialogDescription className="text-wrap">
            The tray library is an account wide collection of trays which allows reuse across multiple inserts.
            Trays from the library can be shared with other users and are versioned.
          </DialogDescription>
        </DialogHeader>
        <Tabs value={step}>
          <TabsList>
            <TabsTrigger value="images">
              <div className="flex items-center gap-2">
                <Badge>1</Badge>
                Add images
              </div>
            </TabsTrigger>
            <TabsTrigger value="description">
              <div className="flex items-center gap-2">
                <Badge>2</Badge>
                Add description
              </div>
            </TabsTrigger>
            <TabsTrigger value="upload">
            <div className="flex items-center gap-2">
                <Badge>3</Badge>
                Upload
              </div>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="images">
            <div className="flex gap-1">
              <div className="relative size-[600px] rounded-sm border bg-white shadow-sm">
                <Suspense fallback={null}>
                  <ThreeCanvas className="rounded-sm bg-white"
                    dpr={dpr}
                    frameloop="demand"
                    camera={{ position: [0, -40, 80] }}>
                    {/*<OrbitControls makeDefault enableDamping={false} enablePan={false} minAzimuthAngle={mode === "cover" ? -Math.PI / 2.5 : -Math.PI / 16} maxAzimuthAngle={mode === "cover" ? -Math.PI / 5 : Math.PI / 16} minPolarAngle={mode === "cover" ? Math.PI / 5 : 0} maxPolarAngle={mode === "cover" ? Math.PI / 3 : Math.PI / 10} enableZoom={false} />*/}
                    <OrbitControls makeDefault enableDamping={false} enablePan={false} minAzimuthAngle={-Math.PI / 2.5} maxAzimuthAngle={-Math.PI / 5} minPolarAngle={0} maxPolarAngle={Math.PI / 3} enableZoom={false} />
                    <PhotoStage>
                      <ScreenshotCapture registerScreenshotSource={h => takeScreenshot = h}>
                        <ReplicadShapes clipConstant={0} clipDirection={null} models={models} selections={[]} disableLayout={true} color="#0ea5e9" />
                      </ScreenshotCapture>
                    </PhotoStage>
                  </ThreeCanvas>
                </Suspense>
                <div className="absolute left-1 top-1 z-50 flex select-none items-start gap-1">
                  <div className="flex w-28 flex-col gap-1">
                    <Button variant="outline" className="z-30 justify-start disabled:border-primary disabled:opacity-100">
                      <DimensionsIcon />
                      Demo
                    </Button>
                    <Button variant="primary" className="z-30" onClick={() => {
                      setImages([...images, {
                        id: images.length == 0 ? 1 : (Math.max(...images.map(i => i.id)) + 1),
                        source: takeScreenshot(),
                      }]);
                    }}>
                      <CameraIcon />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex flex-auto flex-col gap-1">
                <div className="flex flex-col gap-1 rounded-sm border bg-white p-2 shadow-sm">
                  <BlueprintShareImages images={images} setImages={setImages} />
                </div>
                <div className="flex-auto"/>
                {images.length == 0 && (
                  <div className="pl-16 text-right text-sm text-slate-500">
                    At least one image must be added as a preview is required for each tray of the library.
                  </div>
                )}
                <Button disabled={images.length == 0} onClick={() => setStep("description")} className="items-center gap-2 self-end">
                  Next
                  <ArrowRightIcon/>
                </Button>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="description">
            <div className="flex flex-col gap-1">
              <Label htmlFor="name">Name</Label>
              <Input id="name" value={name} onChange={e => setName(e.target.value)}/>
              <div className="mb-2 text-sm text-slate-500">
                The tray name can not be changed in future revisions.
              </div>
              <Label htmlFor="description">Description</Label>
              <Editor value={description} onChange={e => setDescription(e.target.value)}/>
              <div className="mt-2 flex">
                <Button onClick={() => setStep("images")} className="items-center gap-2">
                  <ArrowLeftIcon/>
                  Back
                </Button>
                <div className="flex-auto"/>
                {name.trim().length === 0 && (
                  <div className="mr-3 flex items-center text-sm text-slate-500">
                    A name is required for each tray of the library.
                  </div>
                )}
                <Button disabled={name.trim().length === 0} onClick={() => setStep("upload")} className="items-center gap-2">
                  Next
                  <ArrowRightIcon/>
                </Button>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="upload">
            <div className="flex flex-col gap-2">
              <Label>Images</Label>
              <div className="flex flex-wrap rounded border p-2">
                {images.map(i => <img key={i.id} src={i.source} className="size-20"/>)}
              </div>
              <Label>Name</Label>
              {name}
              {description.trim().length > 0 && (
                <>
                  <Label>Description</Label>
                  <div dangerouslySetInnerHTML={{
                    __html: description,
                  }} className="rounded border p-2"/>
                </>
              )}
              <div className="mt-3 flex">
                <Button onClick={() => setStep("description")} className="items-center gap-2">
                  <ArrowLeftIcon/>
                  Back
                </Button>
                <div className="flex-auto"/>
                <Button disabled={name.trim().length == 0 || images.length === 0} onClick={() => {
                  const content = exportBlueprint(getBlueprint(blueprintId));
                  addSharedTray({
                    name: name,
                    description: description,
                    content: content,
                    images: images.map(i => i.source),
                  });
                }}>
                  Publish
                </Button>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </>
    );
  }
}