
import DesignerHistoryToolbar from "../DesignerHistoryToolbar";

export default function BlueprintToolbar() {
  return (
    <div className="flex gap-1">
        {/*}
        <Menubar className="w-full">
          <MenubarMenu>
            <MenubarTrigger>
              File
            </MenubarTrigger>
            <MenubarContent>
              <MenubarItem>
                <ResetIcon/>
                Undo
              </MenubarItem>
              <MenubarItem>
                <ReloadIcon/>
                Redo
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
          <MenubarMenu>
            <MenubarTrigger>
              Settings
            </MenubarTrigger>
            <MenubarContent>
              <DesignerSettings/>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
        */}
        <DesignerHistoryToolbar/>
    </div>
  );
}