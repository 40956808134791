import workerUrl from "./opencvWorker.ts?worker&url";
import { CV, Mat, Size } from "mirada";
import { base64ToBytes, bytesToBase64 } from "./utils";
import { Contour } from "./types";
import { size } from "polished";

export interface WorkerRequestCalibrate {
  type: "calibrate",
  images: ImageData[],
}

export interface WorkerRequestDetectCalibrationPattern {
  type: "detectCalibrationPattern",
  image: ImageData,
}

export interface WorkerRequestUndistort {
  type: "undistort",
  image: ImageData
  calibration: string,
}

export interface WorkerRequestUnwarp {
  type: "unwarp",
  image: ImageData,
}

export interface WorkerRequestPreprocess {
  type: "preprocess",
  image: ImageData,
  board: Board,
  crop: Rect,
  angle: number,
}

export interface WorkerRequestThreshold {
  type: "threshold",
  image: ImageData,
  threshold: number,
  closing: number,
  smoothing: number,
}

export interface WorkerRequestContour {
  type: "contour",
  image: ImageData,
  approximation: number,
  showBoundingBox: boolean,
  board: Board,
}

export interface WorkerRequestLoad {
  type: "load",
}

export type WorkerRequest =  WorkerRequestCalibrate |  WorkerRequestUndistort | WorkerRequestUnwarp | WorkerRequestPreprocess | WorkerRequestThreshold | WorkerRequestContour | WorkerRequestLoad | WorkerRequestDetectCalibrationPattern;

export interface WorkerResponseError {
  type: "error",
  message: string,
}

export interface WorkerResponseCalibrate {
  calibration: string,
  quality: number,
}

export interface WorkerResponseUnwarp {
  image: ImageData,
  board: Board,
  scaledBoard: Board,
}

export interface WorkerResponseUndistort {
  image: ImageData,
}

export interface WorkerResponsePreprocess {
  image: ImageData,
  cropImage: ImageData,
  histogram: number[],
  recommendedThreshold: number,
}

export interface WorkerResponseThreshold {
  image: ImageData,
}

export interface WorkerResponseContour {
  image: ImageData,
  contour: Contour,
  detectedAngle: number,
}

export interface WorkerResponseDetectCalibrationPattern {
  image: ImageData,
  success: boolean,
}

export interface WorkerResponseLoad {
}

export type WorkerResponse =  WorkerResponseError | WorkerResponseCalibrate | WorkerResponseUnwarp | WorkerResponseUndistort | WorkerResponsePreprocess | WorkerResponseThreshold | WorkerResponseContour | WorkerResponseLoad | WorkerResponseDetectCalibrationPattern;

enum MarkerType {
  DinA4Bordered = 20,
  DinA4Borderless = 21,
  DinA3Bordered = 22,
  DinA3Borderless = 23,
}

export interface Marker {
  magicNumber: number,
  type: MarkerType,
  mode: number,
  index: number,
  outerX: number,
  outerY: number,
  innerX: number,
  innerY: number,
}

export interface Board {
  name: string,
  areaWidth: number,
  areaHeight: number,
  extendWidth: number,
  extendHeight: number,
  tileSize: number,
  scaleX: number,
  scaleY: number,
}

export interface Rect {
  left: number,
  width: number,
  top: number,
  height: number,
}


function getBoardData(boardType: MarkerType): Board {
  switch(boardType) {
    case MarkerType.DinA4Bordered: return {
      areaWidth: 145,
      areaHeight: 233.8,
      extendWidth: 32,
      extendHeight: 32,
      tileSize: 19.6,
      scaleX: 1,
      scaleY: 1,
      name: "DIN A4 bordered",
    };
    default: return {
      areaWidth: 1,
      areaHeight: 1,
      extendWidth: 1,
      extendHeight: 1,
      tileSize: 1,
      scaleX: 1,
      scaleY: 1,
      name: "unknown",
    };
  }
}

function scaleBoard(board: Board, areaWidth: number, areaHeight: number): Board {
  return {
    extendWidth: areaWidth * board.extendWidth / board.areaWidth,
    extendHeight: areaHeight * board.extendHeight / board.areaHeight,
    areaWidth: areaWidth,
    areaHeight: areaHeight,
    tileSize: ((areaWidth * board.tileSize / board.areaWidth) + (areaHeight * board.tileSize / board.areaHeight)) / 2,
    name: board.name,
    scaleX: board.areaWidth / areaWidth,
    scaleY: board.areaHeight / areaHeight,
  };
}

function getDistance(a: Marker, b: Marker) {
  return Math.sqrt(Math.pow(a.outerX - b.outerX, 2) + Math.pow(a.outerY - b.outerY, 2));
}

function imageDataFromMat(mat: Mat, cv?: CV, grayscale = false) {
  if(grayscale && cv) {
    const colorMat = new cv.Mat();
    cv.cvtColor(mat, colorMat, cv.COLOR_GRAY2RGBA);
    const data = new ImageData(new Uint8ClampedArray(colorMat.data), colorMat.cols, colorMat.rows);
    colorMat.delete();
    return data;
  } else {
    return new ImageData(new Uint8ClampedArray(mat.data), mat.cols, mat.rows);
  }
}

let worker: Worker | null = null;

export function opencvRequest(request: WorkerRequestUndistort) : Promise<Error | WorkerResponseUndistort>;
export function opencvRequest(request: WorkerRequestUnwarp) : Promise<Error | WorkerResponseUnwarp>;
export function opencvRequest(request: WorkerRequestPreprocess) : Promise<Error | WorkerResponsePreprocess>;
export function opencvRequest(request: WorkerRequestThreshold) : Promise<Error | WorkerResponseThreshold>;
export function opencvRequest(request: WorkerRequestContour) : Promise<Error | WorkerResponseContour>;
export function opencvRequest(request: WorkerRequestCalibrate) : Promise<Error | WorkerResponseCalibrate>;
export function opencvRequest(request: WorkerRequestDetectCalibrationPattern) : Promise<Error | WorkerResponseDetectCalibrationPattern>;
export function opencvRequest(request: WorkerRequestLoad) : Promise<Error | WorkerResponseLoad>;
export function opencvRequest(request: WorkerRequest) {
  if(worker === null) {
    worker = new Worker(workerUrl, {
      type: "module",
    });
  }
  return new Promise<Error | WorkerResponse>(resolve => {
    worker!.addEventListener("message", (e: MessageEvent<Error | WorkerResponse>) => {
      resolve(e.data);
    }, {
      once: true,
    });
    worker!.postMessage(request);
  })
}

export function dispatchRequest(cv: CV, request: WorkerRequest): WorkerResponse {
  switch (request.type) {
    case "load": return {};
    case "calibrate": return calibrate(cv, request);
    case "undistort": return undistort(cv, request);
    case "preprocess": return preprocess(cv, request);
    case "threshold": return threshold(cv, request);
    case "unwarp": return unwarp(cv, request);
    case "contour": return contour(cv, request);
    case "detectCalibrationPattern": return detectCalibrationPattern(cv, request);
  }
}

export function calibrate(cv: CV, request: WorkerRequestCalibrate): WorkerResponseCalibrate {
  if(request.images.length < 3) {
    throw "camera calibration requires at least 3 images";
  }
  const allImageCorners = new cv.MatVector();
  let requiredSize: Size | null = null;
  for (const imageData of request.images) {
    const image = cv.matFromImageData(imageData);
    if(requiredSize === null) {
      requiredSize = image.size();
    } else {
      const currentSize = image.size();
      if(requiredSize.height !== currentSize.height || requiredSize.width !== currentSize.width) {
        throw "The images have inconsitent size. All images must have exactly the same dimensions and rotation."
      }
    }
    const corners = new cv.Mat();
    const patternFound = cv.findChessboardCorners(image, new cv.Size(6, 9), corners, cv.CALIB_CB_ADAPTIVE_THRESH | cv.CALIB_CB_NORMALIZE_IMAGE);
    if (patternFound) {
      const grayImage = new cv.Mat();
      cv.cvtColor(image, grayImage, cv.COLOR_RGB2GRAY);
      cv.cornerSubPix(grayImage, corners, new cv.Size(11, 11), new cv.Size(-1, -1), new cv.TermCriteria(cv.TermCriteria_EPS, 30, 0.1));
      grayImage.delete();
      cv.drawChessboardCorners(image, new cv.Size(6, 9), corners, patternFound);
      allImageCorners.push_back(corners);
    }
    image.delete();
  }
  const cameraMatrix = cv.Mat.eye(3, 3, cv.CV_64F);
  const distCoeffs = cv.Mat.zeros(8, 1, cv.CV_64F);
  const corners: number[] = [];
  for (let i = 0; i < 9; ++i) {
    for (let j = 0; j < 6; ++j) {
      corners.push(j * 0.02435, i * 0.02435, 0);
    }
  }
  const cornersMat = cv.matFromArray(6 * 9, 3, cv.CV_32F, corners);
  const objectCorners = new cv.MatVector();
  //@ts-expect-error MatVector.size() return skalar value
  for (let r = 0; r < allImageCorners.size(); r++) {
    objectCorners.push_back(cornersMat);
  }
  const rvecs = new cv.MatVector();
  const tvecs = new cv.MatVector();
  const stdDeviationsIntrinsics = new cv.Mat();
  const stdDeviationsExtrinsics = new cv.Mat();
  const perViewErrors = new cv.Mat();
  //@ts-expect-error calibrateCameraExtended is not a function
  cv.calibrateCameraExtended(objectCorners, allImageCorners, requiredSize, cameraMatrix, distCoeffs, rvecs, tvecs, stdDeviationsIntrinsics, stdDeviationsExtrinsics, perViewErrors);
  const rectificationTransformation = new cv.Mat();
  const newCameraMatrix = cv.getOptimalNewCameraMatrix(cameraMatrix, distCoeffs, requiredSize!, 1);
  const map1 = new cv.Mat();
  const map2 = new cv.Mat();
  cv.initUndistortRectifyMap(cameraMatrix, distCoeffs, rectificationTransformation, newCameraMatrix, requiredSize!, cv.CV_16SC2, map1, map2);
  /*for (const image of newImages) {
    if (image.analyzed) {
      image.corrected = new cv.Mat();
      cv.remap(image.analyzed, image.corrected, map1, map2, cv.INTER_LINEAR);
    }
  }*/
  // update quality
  let quality = 0;
  for (let index = 0; index < perViewErrors.data64F.length; index++) {
    quality += perViewErrors.data64F[index];
  }
  quality /= perViewErrors.data64F.length;
  // prepare export
  const buffer = new ArrayBuffer(112);
  const dataView = new DataView(buffer);
  dataView.setFloat64(0, cameraMatrix.data64F[0]);
  dataView.setFloat64(8, cameraMatrix.data64F[1]);
  dataView.setFloat64(16, cameraMatrix.data64F[2]);
  dataView.setFloat64(24, cameraMatrix.data64F[3]);
  dataView.setFloat64(32, cameraMatrix.data64F[4]);
  dataView.setFloat64(40, cameraMatrix.data64F[5]);
  dataView.setFloat64(48, cameraMatrix.data64F[6]);
  dataView.setFloat64(56, cameraMatrix.data64F[7]);
  dataView.setFloat64(64, cameraMatrix.data64F[8]);
  dataView.setFloat64(72, distCoeffs.data64F[0]);
  dataView.setFloat64(80, distCoeffs.data64F[1]);
  dataView.setFloat64(88, distCoeffs.data64F[2]);
  dataView.setFloat64(96, distCoeffs.data64F[3]);
  dataView.setFloat64(104, distCoeffs.data64F[4]);
  const calibration = bytesToBase64(new Uint8Array(buffer));
  return {
    calibration,
    quality,
  };
}

export function detectCalibrationPattern(cv: CV, request: WorkerRequestDetectCalibrationPattern): WorkerResponseDetectCalibrationPattern {
  const image = cv.matFromImageData(request.image);
  const corners = new cv.Mat();
  const patternFound = cv.findChessboardCorners(image, new cv.Size(6, 9), corners, cv.CALIB_CB_ADAPTIVE_THRESH | cv.CALIB_CB_NORMALIZE_IMAGE);
  if (patternFound) {
    const grayImage = new cv.Mat();
    cv.cvtColor(image, grayImage, cv.COLOR_RGB2GRAY);
    cv.cornerSubPix(grayImage, corners, new cv.Size(11, 11), new cv.Size(-1, -1), new cv.TermCriteria(cv.TermCriteria_EPS, 30, 0.1));
    grayImage.delete();
    cv.drawChessboardCorners(image, new cv.Size(6, 9), corners, patternFound);
  }
  corners.delete();
  const imageData = imageDataFromMat(image);
  image.delete();
  return {
    image: imageData,
    success: patternFound,
  };
}

export function undistort(cv: CV, request: WorkerRequestUndistort): WorkerResponseUndistort {
  // read calibration mats
  const importBytes = base64ToBytes(request.calibration);
  const dataView2 = new DataView(importBytes.buffer);
  const cameraMatrix = new cv.Mat(3, 3, cv.CV_64F);
  cameraMatrix.data64F[0] = dataView2.getFloat64(0);
  cameraMatrix.data64F[1] = dataView2.getFloat64(8);
  cameraMatrix.data64F[2] = dataView2.getFloat64(16);
  cameraMatrix.data64F[3] = dataView2.getFloat64(24);
  cameraMatrix.data64F[4] = dataView2.getFloat64(32);
  cameraMatrix.data64F[5] = dataView2.getFloat64(40);
  cameraMatrix.data64F[6] = dataView2.getFloat64(48);
  cameraMatrix.data64F[7] = dataView2.getFloat64(56);
  cameraMatrix.data64F[8] = dataView2.getFloat64(64);
  const distCoeffs = new cv.Mat(5, 1, cv.CV_64F);
  distCoeffs.data64F[0] = dataView2.getFloat64(72);
  distCoeffs.data64F[1] = dataView2.getFloat64(80);
  distCoeffs.data64F[2] = dataView2.getFloat64(88);
  distCoeffs.data64F[3] = dataView2.getFloat64(96);
  distCoeffs.data64F[4] = dataView2.getFloat64(104);
  const image = cv.matFromImageData(request.image);
  // configure transformation
  const transformation = new cv.Mat();
  const newCameraMatrix = cv.getOptimalNewCameraMatrix(cameraMatrix, distCoeffs, image.size(), 1);
  const map1 = new cv.Mat();
  const map2 = new cv.Mat();
  cv.initUndistortRectifyMap(cameraMatrix!, distCoeffs!, transformation, newCameraMatrix, image.size(), cv.CV_16SC2, map1, map2);
  cameraMatrix.delete();
  newCameraMatrix.delete();
  distCoeffs.delete();
  // perform transformation
  const result = new cv.Mat();
  cv.remap(image, result, map1, map2, cv.INTER_LINEAR);
  const imageData = new ImageData(new Uint8ClampedArray(result.data), result.cols, result.rows);
  result.delete();
  transformation.delete();
  map1.delete();
  map2.delete();
  image.delete();
  return {
    image: imageData,
  };
}

export function unwarp(cv: CV, request: WorkerRequestUnwarp): WorkerResponseUnwarp {
  //@ts-expect-error unknown
  const dictionary = cv.getPredefinedDictionary(cv.DICT_4X4_50);
  const boardIds = new cv.Mat();
  //@ts-expect-error unknown
  const charucoBoard = new cv.aruco_CharucoBoard(new cv.Size(3, 3), 0.0236, 0.0236 / 2, dictionary, boardIds);
  boardIds.delete();
  //@ts-expect-error unknown
  const charucoParameters = new cv.aruco_CharucoParameters();
  /*charucoParameters.cameraMatrix = cameraMatrix;
  charucoParameters.distCoeffs = distCoeffs;
  charucoParameters.minMarkers = 4;
  charucoParameters.tryRefineMarkers = true;*/
  //@ts-expect-error unknown
  const detectorParameters = new cv.aruco_DetectorParameters();
  //@ts-expect-error unknown
  const refineParameters = new cv.aruco_RefineParameters(10, 3, true);
  //@ts-expect-error unknown
  const detector = new cv.aruco_CharucoDetector(charucoBoard, charucoParameters, detectorParameters, refineParameters);
  const corners = new cv.MatVector();
  const ids = new cv.Mat();
  const image = cv.matFromImageData(request.image);
  const sourceGray = new cv.Mat();
  cv.cvtColor(image, sourceGray, cv.COLOR_RGBA2GRAY);
  detector.detectDiamonds(sourceGray, corners, ids);
  //@ts-expect-error MatVector.size() return skalar value
  if(ids.data32S.length !== 16 || corners.size() !== 4) {
    throw "failed to detect scan sheet markers";
  }
  //cv.drawDetectedDiamonds(sourceGray, corners, detectedIds, new cv.Scalar(0, 0, 255));
  sourceGray.delete();
  // read markers
  const markers: Marker[] = [];
  for(let index = 0; index < 4; index++) {
    markers.push({
      magicNumber: ids.data32S[index * 4 + 0],
      type: ids.data32S[index * 4 + 1],
      mode: ids.data32S[index * 4 + 2],
      index: ids.data32S[index * 4 + 3],
      innerX: 0,
      innerY: 0,
      outerX: 0,
      outerY: 0,
    });
  }

  ids.delete();
  // validate markers
  if(markers.some(m => m.magicNumber !== 42)) {
    throw "unknown scan sheet";
  }
  let board: Board;
  if(markers.every(m => m.type === MarkerType.DinA4Bordered)) {
    board = getBoardData(MarkerType.DinA4Bordered);
  } else {
    throw "unknown scan sheet type";
  }
  const topLeft = markers.find(m => m.index === 0)!;
  const topRight = markers.find(m => m.index === 1)!;
  const bottomRight = markers.find(m => m.index === 2)!;
  const bottomLeft = markers.find(m => m.index === 3)!;
  const order = [ markers.indexOf(topLeft), markers.indexOf(topRight), markers.indexOf(bottomRight), markers.indexOf(bottomLeft) ];
  if(order.some(i => i === -1)) {
    throw "unknown scan sheet marker";
  }
  const sourceCorners: number[] = [];
  for(const index of order) {
    const corner = corners.get(index);
    markers[index].outerX = corner.data32F[markers[index].index * 2 + 0];
    markers[index].outerY = corner.data32F[markers[index].index * 2 + 1];
    markers[index].innerX = corner.data32F[((markers[index].index + 2) % 4) * 2 + 0];
    markers[index].innerY = corner.data32F[((markers[index].index + 2) % 4) * 2 + 1];
    sourceCorners.push(markers[index].outerX, markers[index].outerY);
  }
  corners.delete();
  // get size
  const areaWidth = Math.max(getDistance(topRight, topLeft), getDistance(bottomRight, bottomLeft));
  const areaHeight = Math.max(getDistance(topRight, bottomRight), getDistance(topLeft, bottomLeft));
  const scaledBoard = scaleBoard(board, areaWidth, areaHeight);
  // get transform from 4 points
  const sourceCornersMat = cv.matFromArray(4, 1, cv.CV_32FC2, sourceCorners);
  const targetCornersMat = cv.matFromArray(4, 1, cv.CV_32FC2, [ scaledBoard.extendWidth, scaledBoard.extendHeight, scaledBoard.areaWidth + scaledBoard.extendWidth, scaledBoard.extendHeight, scaledBoard.areaWidth + scaledBoard.extendWidth, scaledBoard.areaHeight + scaledBoard.extendHeight, scaledBoard.extendWidth, scaledBoard.areaHeight + scaledBoard.extendHeight ]);
  const transform = cv.getPerspectiveTransform(sourceCornersMat, targetCornersMat);
  sourceCornersMat.delete();
  targetCornersMat.delete();
  // remove perspective
  const unwarped = new cv.Mat();
  cv.warpPerspective(image, unwarped, transform, new cv.Size(scaledBoard.areaWidth + 2 * scaledBoard.extendWidth, scaledBoard.areaHeight + 2 * scaledBoard.extendHeight));
  transform.delete();
  image.delete();
  const imageData = imageDataFromMat(unwarped);
  unwarped.delete();
  return {
    image: imageData,
    board: board,
    scaledBoard: scaledBoard,
  };
}

export function preprocess(cv: CV, request: WorkerRequestPreprocess): WorkerResponsePreprocess {
  const image = cv.matFromImageData(request.image);
  cv.cvtColor(image, image, cv.COLOR_RGBA2GRAY);
  const margin = 5;
  const sourceSize = image.size();
  const polygons = new cv.MatVector();
  const width = request.board.extendWidth + 2 * request.board.tileSize + margin;
  const height = request.board.extendHeight + 2 * request.board.tileSize + margin;
  polygons.push_back(cv.matFromArray(4, 1, cv.CV_32SC2, [
    0, 0, 
    width, 0, 
    width, height, 
    0, height,
  ]));
  polygons.push_back(cv.matFromArray(4, 1, cv.CV_32SC2, [
    sourceSize.width - width, 0, 
    sourceSize.width, 0,
    sourceSize.width, height, 
    sourceSize.width - width, height, 
  ]));
  polygons.push_back(cv.matFromArray(4, 1, cv.CV_32SC2, [
    sourceSize.width - width, sourceSize.height - height,
    sourceSize.width, sourceSize.height - height,
    sourceSize.width, sourceSize.height,
    sourceSize.width - width, sourceSize.height
  ]));
  polygons.push_back(cv.matFromArray(4, 1, cv.CV_32SC2, [
    0, sourceSize.height - height,
    width, sourceSize.height - height,
    width, sourceSize.height,
    0, sourceSize.height
  ]));
  polygons.push_back(cv.matFromArray(4, 1, cv.CV_32SC2, [
    width, sourceSize.height - request.board.extendHeight, // REMOVE after reprint
    sourceSize.width - width, sourceSize.height - request.board.extendHeight,
    sourceSize.width - width, sourceSize.height,
    width, sourceSize.height
  ]));
  cv.fillPoly(image, polygons, new cv.Scalar(255));
  polygons.delete();
  if(request.angle !== 0) {
    // https://stackoverflow.com/questions/22041699/rotate-an-image-without-cropping-in-opencv-in-c/24352524#24352524
    const rotationMatrix = cv.getRotationMatrix2D({ x: (image.cols - 1) / 2, y: (image.rows - 1) / 2 }, request.angle, 1);
    const rotatedBounds = new cv.RotatedRect({ x: 0, y: 0 }, image.size(), request.angle);
    //@ts-expect-error incorrect typings
    const boundingBox = cv.RotatedRect.boundingRect(rotatedBounds);
    rotationMatrix.data64F[2] = rotationMatrix.data64F[2] + boundingBox.width / 2 - image.cols / 2;
    rotationMatrix.data64F[5] = rotationMatrix.data64F[5] + boundingBox.height / 2 - image.rows / 2;
    cv.warpAffine(image, image, rotationMatrix, boundingBox, cv.INTER_AREA, cv.BORDER_CONSTANT, new cv.Scalar(255, 255, 255, 255));
  }
  const cropImage = imageDataFromMat(image, cv, true);
  const croppedRect = new cv.Rect(request.crop.left, request.crop.top, request.crop.width, request.crop.height);
  const cropped = image.roi(croppedRect);
  image.delete();
  const imageData = imageDataFromMat(cropped, cv, true);
  const images = new cv.MatVector();
  images.push_back(cropped);
  const histogram = new cv.Mat();
  const mask = new cv.Mat();
  cv.calcHist(images, [0], mask, histogram, [256], [0, 255], false);
  images.delete();
  mask.delete();            
  const values = [...histogram.data32F];
  histogram.delete();            
  return {
    image: imageData,
    cropImage: cropImage,
    histogram: values,
    recommendedThreshold: 0,
  };
}

export function threshold(cv: CV, request: WorkerRequestThreshold): WorkerResponseThreshold {
  const image = cv.matFromImageData(request.image);
  cv.cvtColor(image, image, cv.COLOR_RGBA2GRAY);
  cv.threshold(image, image, request.threshold, 255, cv.THRESH_BINARY_INV);
  if(request.closing > 0) {
    // https://docs.opencv.org/4.x/d4/d76/tutorial_js_morphological_ops.html
    const kernel = cv.Mat.ones(3, 3, cv.CV_8U);
    const anchor = new cv.Point(-1, -1);
    cv.dilate(image, image, kernel, anchor, request.closing, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    cv.erode(image, image, kernel, anchor, request.closing, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    kernel.delete();
  }
  if(request.smoothing > 0) {
    const kernel = cv.Mat.ones(5, 5, cv.CV_8U);
    const anchor = new cv.Point(-1, -1);
    cv.erode(image, image, kernel, anchor, request.smoothing, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    cv.dilate(image, image, kernel, anchor, request.smoothing, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
    kernel.delete();
  }
  const imageData = imageDataFromMat(image, cv, true);
  image.delete();
  return {
    image: imageData,
  };
}

export function contour(cv: CV, request: WorkerRequestContour): WorkerResponseContour {
  const image = cv.matFromImageData(request.image);
  cv.cvtColor(image, image, cv.COLOR_RGBA2GRAY);
  cv.threshold(image, image, 128, 255, cv.THRESH_BINARY);
  const hierarchy = new cv.Mat();
  const contours = new cv.MatVector();
  // You can try more different parameters
  cv.findContours(image, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
  //@ts-expect-error wrong typings
  const count = contours.size() as number;
  let contour: Mat;
  if(count === 0) {
    throw "failed to find a contour";
  } else if(count > 1) {
    let maxPerimeter = 0;
    for(let index = 0; index < count; index++) {
      // https://docs.opencv.org/4.x/dc/dcf/tutorial_js_contour_features.html
      const temp = contours.get(0);
      const perimeter = cv.arcLength(temp, true);
      if(perimeter > maxPerimeter) {
        contour = temp;
        maxPerimeter = perimeter;
      }
    }
  } else {
   contour = contours.get(0);
  }
  if(request.approximation > 0) {
    const perimeter = cv.arcLength(contour!, true);
    cv.approxPolyDP(contour!, contour!, request.approximation * perimeter / 1000, true);
    contours.set(0, contour!);
  }
  const result = cv.Mat.zeros(image.rows, image.cols, cv.CV_8UC3);
  image.delete();
  cv.drawContours(result, contours, 0, new cv.Scalar(255, 255, 255), 1, cv.LINE_8, hierarchy, 100);
  const boundingBox = cv.minAreaRect(contour!);
  const angle = boundingBox.angle;
  //@ts-expect-error incorrect typings
  const boundingBoxVertices = cv.RotatedRect.points(boundingBox);
  const rectangleColor = new cv.Scalar(0x22, 0xc5, 0x5e);
  contours.delete();
  if(request.showBoundingBox) {
    for (let i = 0; i < 4; i++) {
      cv.line(result, boundingBoxVertices[i], boundingBoxVertices[(i + 1) % 4], rectangleColor, 1, cv.LINE_AA, 0);
    }
  }
  cv.cvtColor(result, result, cv.COLOR_RGB2RGBA);
  const imageData = imageDataFromMat(result);
  const contourData = [ ...contour!.data32S ];
  for(let index = 0; index < contourData.length; index += 2) {
    contourData[index] *= request.board.scaleX;
  }
  for(let index = 1; index < contourData.length; index += 2) {
    contourData[index] *= request.board.scaleY;
  }
  result.delete();
  contour!.delete();
  let minX = contourData[0];
  let maxX = minX;
  let minY = contourData[1];
  let maxY = minY;
  for(let index = 0; index < contourData.length; index += 2) {
    if(contourData[index] < minX) {
      minX = contourData[index];
    } else if(contourData[index] > maxX) {
      maxX = contourData[index];
    }
  }
  for(let index = 1; index < contourData.length; index += 2) {
    if(contourData[index] < minY) {
      minY = contourData[index];
    } else if(contourData[index] > maxY) {
      maxY = contourData[index];
    }
  }
  const width = maxX - minX;
  const height = maxY - minY;
  for(let index = 0; index < contourData.length; index += 2) {
    contourData[index] -= minX;
  }
  for(let index = 1; index < contourData.length; index += 2) {
    contourData[index] = maxY - contourData[index];
  }
  return {
    image: imageData,
    contour: {
      points: contourData,
      width: width,
      depth: height,
    },
    detectedAngle: Math.min(angle, Math.abs(90 - angle)),
  };
}