import React from "react";
import { cn } from "@ui/utils";

export interface TextHighlightProps extends React.InputHTMLAttributes<HTMLDivElement>  {
  className?: string, 
  text: string, 
  textClassName?: string, 
  highlight: string, 
  highlightClassName?: string
  respectCase?: boolean,
}

export default function TextHighlight({ className, text, textClassName, highlight, highlightClassName, respectCase = false, ...props }: TextHighlightProps) {
  if(highlight.length === 0) {
    return (
      <p className={cn(className, textClassName)} {...props}>
        {text}
      </p>
    );
  }
  const inlines: React.ReactNode[] = [];
  let index = 0;
  while(index < text.length) {
    const startIndex = respectCase ? text.indexOf(highlight, index) : text.toLowerCase().indexOf(highlight.toLowerCase(), index);
    if(startIndex == -1) {
      // push remaining text
      inlines.push(<span className={cn("whitespace-pre-wrap", textClassName)}>{text.substring(index)}</span>);
      break;
    } else {
      // prepend text
      if(startIndex > index) {
        inlines.push(<span className={cn("whitespace-pre-wrap", textClassName)}>{text.substring(index, startIndex)}</span>);
      }
      // add styled segment
      const endIndex = startIndex + highlight.length;
      inlines.push(<span className={cn("whitespace-pre-wrap", highlightClassName)}>{text.substring(startIndex, endIndex)}</span>);
      index = endIndex;
    }
  }
  return (
    <p className={cn(className)} {...props}>
      {...inlines}
    </p>
  );
}