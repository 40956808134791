import { api } from '../store'

export interface AddBoxResponse {
  id: number,
}

export interface DbBox {
  id: string,
  gameId: number,
  name: string,
  data: string,
  timestamp: string,
}

export interface DbBoxDetails {
  gameId: number,
  name: string,
  data: string,
  timestamp: string,
  inserts: string[],
  hearts: number,
}

const boxesApi = api.injectEndpoints({
  endpoints: builder => ({
    addBox: builder.mutation<AddBoxResponse, {
      gameId: number,
      name: string,
      data: string,
      image?: string,
    }>({
      query: (arg) => ({
        url: "/boxes/",
        method: "POST",
        body: arg,
      }),
      invalidatesTags: [ "boxes" ],
    }),
    listBoxes: builder.query<DbBox[], {
      name?: string,
      ownerId?: number,
      gameId?: number,
    }>({
      query: (arg) => ({
        url: `/boxes`,
        params: arg,
        
      }),
      providesTags: [ "boxes" ],
    }),
    getBox: builder.query<DbBoxDetails, {
      id: string,
    }>({
      query: (arg) => ({
        url: `/boxes/${arg.id}`,
      }),
      providesTags: [ "boxes" ],
    }),
  }),
  overrideExisting: false,
})

export const { useAddBoxMutation, useLazyListBoxesQuery, useListBoxesQuery, useGetBoxQuery } = boxesApi;